const dayjs = require('dayjs');

const plugins = [
  require('dayjs/plugin/badMutable'),
  require('dayjs/plugin/localeData'),
  require('dayjs/plugin/localizedFormat'),
  require('dayjs/plugin/pluralGetSet'),
  require('dayjs/plugin/isBetween'),
  require('dayjs/plugin/weekday')
];

plugins.forEach(p => dayjs.extend(p));

dayjs.isMoment = dayjs.isDayjs;
dayjs.invalid = () => dayjs(NaN);

module.exports = dayjs;
