// IMPORTS
import LodgeApiClient from '../../api/clients/LodgeApiClient';
import { initializeState } from '../../utilities/storageUtils';

import { actionCreators as currentLodgeActionCreators, getResortLocation } from './CurrentLodge';
import { actionCreators as plansActionCreators } from './Plans';

// ACTION TYPES
const configLoading = 'LOADING_CONFIG';
const configLoadingError = 'ERROR_LOADING_CONFIG';
const configLoadingClear = 'CLEAR_LOADING_CONFIG';
const configLoadingSuccess = 'SUCCESS_LOADING_CONFIG';

const planConfigLoading = 'LOADING_PLAN_CONFIG';
const planConfigLoadingError = 'ERROR_LOADING_PLAN';
const planConfigLoadingClear = 'CLEAR_LOADING_PLAN';
const planConfigLoadingSuccess = 'SUCCESS_LOADING_PLAN';

const userSettingsLoading = 'LOADING_USER_SETTINGS';
const userSettingsLoadingError = 'ERROR_USER_SETTINGS';
const userSettingsLoadingClear = 'CLEAR_USER_SETTINGS';
const userSettingsLoadingSuccess = 'SUCCESS_USER_SETTINGS';

const creditPackageLoading = 'LOADING_CREDIT_PACKAGE';
const creditPackageLoadingError = 'ERROR_LOADING_CREDIT_PACKAGE';
const creditPackageLoadingClear = 'CLEAR_LOADING_CREDIT_PACKAGE';
const creditPackageLoadingSuccess = 'SUCCESS_LOADING_CREDIT_PACKAGE';

const cmpConfigurationLoading = 'LOADING_CMP_CONFIGURATION';
const cmpConfigurationLoadingError = 'ERROR_LOADING_CMP_CONFIGURATION';
const cmpConfigurationLoadingClear = 'CLEAR_LOADING_CMP_CONFIGURATION';
const cmpConfigurationLoadingSuccess = 'SUCCESS_LOADING_CMP_CONFIGURATION';

/* ABCD TEST FOR PARKING FEE */
const paymentConfigurationLoading = 'LOADING_PAYMENT_CONFIGURATION';
const paymentConfigurationLoadingError = 'ERROR_LOADING_PAYMENT_CONFIGURATION';
const paymentConfigurationLoadingClear = 'CLEAR_LOADING_PAYMENT_CONFIGURATION';
const paymentConfigurationLoadingSuccess = 'SUCCESS_LOADING_PAYMENT_CONFIGURATION';
/* ABCD TEST FOR PARKING FEE */

const leadGenConfigurationLoading = 'LOADING_LEADGEN_CONFIGURATION';
const leadGenConfigurationLoadingError = 'ERROR_LOADING_LEADGEN_CONFIGURATION';
const leadGenConfigurationLoadingClear = 'CLEAR_LOADING_LEADGEN_CONFIGURATION';
const leadGenConfigurationLoadingSuccess = 'SUCCESS_LOADING_LEADGEN_CONFIGURATION';

const resetLodgeConfig = 'RESET_LODGE_CONFIG';

const cartConfigurationLoading = 'LOADING_CART_CONFIGURATION';
const cartConfigurationLoadingError = 'ERROR_LOADING_CART_CONFIGURATION';
const cartConfigurationLoadingClear = 'CLEAR_LOADING_CART_CONFIGURATION';
const cartConfigurationLoadingSuccess = 'SUCCESS_LOADING_CART_CONFIGURATION';

const bookingWidgetConfigurationLoading = 'LOADING_BOOKING_WIDGET_CONFIGURATION';
const bookingWidgetConfigurationLoadingError = 'ERROR_LOADING_BOOKING_WIDGET_CONFIGURATION';
const bookingWidgetConfigurationLoadingClear = 'CLEAR_LOADING_BOOKING_WIDGET_CONFIGURATION';
const bookingWidgetConfigurationLoadingSuccess = 'SUCCESS_LOADING_BOOKING_WIDGET_CONFIGURATION';

// REDUX FUNCTIONS

const verifyIfIsEnabled = option => {
  const optionValue = typeof option === 'boolean' ? option : false;
  return optionValue;
};

const verifyPointsMultiplier = option => {
  const optionValue = typeof option === 'number' ? option / 100 : 0.1;
  return optionValue;
};

/**
 * As a test I added the offers the same as loyalty, they need to be separated
 * or we need to change the url to allocate all the lodge config under one URL
 */
export const actionCreators = {
  getLoyaltyConfigByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);
    const lastResortLocation =
      localStorage.getItem('state') && getResortLocation(JSON.parse(localStorage.getItem('state')).currentLodge);

    dispatch({ type: configLoadingClear });
    dispatch({ type: configLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'loyalty');

      resortLocation !== lastResortLocation && dispatch(plansActionCreators.cleanIsCaliforniaResident());

      dispatch({
        type: configLoadingSuccess,
        isLoyaltyEnabled: verifyIfIsEnabled(configurationData.data.isLoyaltyEnabled),
        isInPrimerState: verifyIfIsEnabled(configurationData.data.isInPrimerState),
        isOffersEnabled: verifyIfIsEnabled(configurationData.data.isOffersEnabled),
        loyaltyPointsMultiplier: verifyPointsMultiplier(configurationData.data.loyaltyPointsMultiplier),
        isLoyaltyReferralEnabled: verifyIfIsEnabled(configurationData.data.isLoyaltyReferralEnabled),
        showCaliforniaResortFeeVariation: configurationData.data.showCaliforniaResortFeeVariation,
        isLoyaltyPointsExpirationOnProfileEnabled: verifyIfIsEnabled(
          configurationData.data.isLoyaltyPointsExpirationOnProfileEnabled
        ),
        loyaltyPointsToEarn: configurationData.data.loyaltyPointsToEarn,
        loyaltyEarnThresholdNights: configurationData.data.loyaltyEarnThresholdNights ?? 2,
        showPackagesInAddGuestsModal: configurationData.data.showPackagesInAddGuestsModal
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: configLoadingError, configFetchError: error });
    }
    dispatch({ type: configLoading, configFetchIsLoading: false });
  },
  getPlanConfigByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({ type: planConfigLoadingClear });
    dispatch({ type: planConfigLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'plan');

      dispatch({
        type: planConfigLoadingSuccess,
        config: {
          additionalPersonAmount: configurationData.data.additionalPersonAmount,
          affirmPubKey: configurationData.data.affirmPubKey,
          filters: configurationData.data.filters,
          isFetchRestrictionsEnabled: configurationData.data.isFetchRestrictionsEnabled,
          isHighestRatesEnabled: configurationData.data.isHighestRatesEnabled,
          isRateCalEnabled: configurationData.data.isRateCalEnabled,
          updateSearchFullPageRefresh: configurationData.data.updateSearchFullPageRefresh,
          includeResortFeeInSuitePrice: configurationData.data.includeResortFeeInSuitePrice,
          pricePerWaterPass: configurationData.data.pricePerWaterPass,
          isRoomUnbundledRateWPAvailable: configurationData.data.isRoomUnbundledRateWPAvailable,
          suitePriceIncludedTaxesAndFees: configurationData.data.suitePriceIncludedTaxesAndFees ?? []
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: planConfigLoadingError, configFetchError: error });
    }
    dispatch({ type: planConfigLoading, configFetchIsLoading: false });
  },
  getUserSettingsByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({ type: userSettingsLoadingClear });
    dispatch({ type: userSettingsLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'user-settings');

      dispatch({
        type: userSettingsLoadingSuccess,
        newPatchEndpoint: configurationData.data['new-patch-endpoint']
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: userSettingsLoadingError, configFetchError: error });
    }
    dispatch({ type: userSettingsLoading, configFetchIsLoading: false });
  },
  getCreditPackageConfigByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({ type: creditPackageLoadingClear });
    dispatch({ type: creditPackageLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'credit-packages');

      dispatch({
        type: creditPackageLoadingSuccess,
        isDiningPackagesEnhancedUIEnabled: verifyIfIsEnabled(configurationData.data.isDiningPackagesEnhancedUIEnabled)
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: creditPackageLoadingError, configFetchError: error });
    }
    dispatch({ type: creditPackageLoading, configFetchIsLoading: false });
  },
  getCmpConfigByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({ type: cmpConfigurationLoadingClear });
    dispatch({ type: cmpConfigurationLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'cmp');

      dispatch({
        type: cmpConfigurationLoadingSuccess,
        isDynamicTileEnabled: verifyIfIsEnabled(configurationData.data.isDynamicTileEnabled),
        hideOnlineCheckinText: verifyIfIsEnabled(configurationData.data.hideOnlineCheckinText),
        parkingFeeCallout: verifyIfIsEnabled(configurationData.data?.parkingFeeCallout),
        parkingFeeCalloutTitle: configurationData.data?.parkingFeeCalloutTitle ?? '',
        parkingFeeCalloutDescription: configurationData.data?.parkingFeeCalloutDescription ?? '',
        uffTileEnabled: verifyIfIsEnabled(configurationData.data.uffTileEnabled),
        isCancelReservationEnabled: verifyIfIsEnabled(configurationData.data.isCancelReservationEnabled)
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: cmpConfigurationLoadingError, configFetchError: error });
    }
    dispatch({ type: cmpConfigurationLoading, configFetchIsLoading: false });
  },
  /* ABCD TEST FOR PARKING FEE */
  getPaymentConfigByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({ type: paymentConfigurationLoadingClear });
    dispatch({ type: paymentConfigurationLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'payment');

      dispatch({
        type: paymentConfigurationLoadingSuccess,
        perNightParkingFee1: configurationData.data.perNightParkingFee1,
        perNightParkingFee2: configurationData.data.perNightParkingFee2,
        perNightParkingFee3: configurationData.data.perNightParkingFee3,
        parkingFeeStartDate: configurationData.data.parkingFeeStartDate,
        parkingFeeEndDate: configurationData.data.parkingFeeEndDate,
        clientCardTokenization: configurationData.data.clientCardTokenization,
        creditCardAuthUrl: configurationData.data.creditCardAuthUrl,
        creditEnabled: configurationData.data.creditEnabled,
        affirmEnabled: configurationData.data.affirmEnabled,
        applePayEnabled: configurationData.data.applePayEnabled,
        googlePayEnabled: configurationData.data.googlePayEnabled,
        payPalEnabled: configurationData.data.payPalEnabled,
        amexEnabled: verifyIfIsEnabled(configurationData.data?.isAmexEnabled),
        bookingSummaryParkingFee: configurationData.data?.bookingSummaryParkingFee,
        taxAndFeeLabels: configurationData.data?.taxAndFeeLabels
      });
    } catch (error) {
      dispatch({ type: paymentConfigurationLoadingError, configFetchError: error });
    }
    dispatch({ type: paymentConfigurationLoading, configFetchIsLoading: false });
  },
  /* ABCD TEST FOR PARKING FEE */
  getLeadGenConfigByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({ type: leadGenConfigurationLoadingClear });
    dispatch({ type: leadGenConfigurationLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'lead-gen');

      dispatch({
        type: leadGenConfigurationLoadingSuccess,
        shouldLeadGenPop: configurationData.data.shouldLeadGenPop
      });
    } catch (error) {
      dispatch({ type: leadGenConfigurationLoadingError, configFetchError: error });
    }
    dispatch({ type: leadGenConfigurationLoading, configFetchIsLoading: false });
  },
  resetLodgeConfig: () => async (dispatch, getState) => {
    const {
      lodgeLocations: { locationList }
    } = getState();

    dispatch(currentLodgeActionCreators.updateCurrentLodge(locationList));
    dispatch({ type: resetLodgeConfig });
  },
  getCartConfigByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({ type: cartConfigurationLoadingClear });
    dispatch({ type: cartConfigurationLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'cart');

      dispatch({
        type: cartConfigurationLoadingSuccess,
        config: {
          isCartActive: configurationData.data.isCartActive
        }
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: cartConfigurationLoadingError, configFetchError: error });
    }
    dispatch({ type: cartConfigurationLoading, configFetchIsLoading: false });
  },
  getBookingWidgetConfigByResort: () => async (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({ type: bookingWidgetConfigurationLoadingClear });
    dispatch({ type: bookingWidgetConfigurationLoading, configFetchIsLoading: true });

    try {
      const configurationClient = new LodgeApiClient();
      const configurationData = await configurationClient.getConfiguration(resortLocation, 'booking-widget');

      dispatch({
        type: bookingWidgetConfigurationLoadingSuccess,
        beginReservationsDate: configurationData.data.beginReservationsDate,
        maxOccupancy: configurationData.data.maxOccupancy
      });
    } catch (error) {
      dispatch({ type: bookingWidgetConfigurationLoadingError, configFetchError: error });
    }
    dispatch({ type: bookingWidgetConfigurationLoading, configFetchIsLoading: false });
  }
  /* ABCD TEST FOR PARKING FEE */
};

// INITIAL STATE
const initialState = {
  configFetchIsLoading: false,
  configFetchError: '',
  additionalPersonAmount: 0,
  affirmPubKey: '',
  filters: null,
  isFetchRestrictionsEnabled: false,
  isHighestRatesEnabled: false,
  isRateCalEnabled: false,
  updateSearchFullPageRefresh: false,
  isLoyaltyEnabled: false,
  loyaltyPointsToEarn: '',
  isInPrimerState: false,
  isOffersEnabled: false,
  loyaltyPointsMultiplier: 0.1,
  loyaltyEarnThresholdNights: 2,
  isLoyaltyReferralEnabled: false,
  isDiningPackagesEnhancedUIEnabled: true,
  isDynamicTileEnabled: false,
  hideOnlineCheckinText: false,
  perNightParkingFee1: '',
  perNightParkingFee2: '',
  perNightParkingFee3: '',
  parkingFeeStartDate: '',
  parkingFeeEndDate: '',
  clientCardTokenization: false,
  creditCardAuthUrl: '',
  creditEnabled: false,
  affirmEnabled: false,
  applePayEnabled: false,
  googlePayEnabled: false,
  payPalEnabled: false,
  amexEnabled: false,
  shouldLeadGenPop: '',
  parkingFeeCallout: false,
  parkingFeeCalloutTitle: '',
  parkingFeeCalloutDescription: '',
  bookingSummaryParkingFee: '',
  uffTileEnabled: false,
  isLoyaltyPointsExpirationOnProfileEnabled: false,
  isCartActive: false,
  showCaliforniaResortFeeVariation: false,
  pricePerWaterPass: 0,
  isCancelReservationEnabled: false,
  isRoomUnbundledRateWPAvailable: false,
  beginReservationsDate: null,
  maxOccupancy: 0,
  newPatchEndpoint: false,
  showPackagesInAddGuestsModal: false,
  suitePriceIncludedTaxesAndFees: [],
  taxAndFeeLabels: ''
};

// ACTION CREATORS
const reducer = (state, action) => {
  state = initializeState(state, initialState);
  switch (action.type) {
    case configLoading:
    case creditPackageLoading:
    case cmpConfigurationLoading:
    case cartConfigurationLoading:
    case bookingWidgetConfigurationLoading:
      return {
        ...state,
        configFetchIsLoading: action.configFetchIsLoading
      };
    case configLoadingError:
    case creditPackageLoadingError:
    case cmpConfigurationLoadingError:
    case cartConfigurationLoadingError:
    case bookingWidgetConfigurationLoadingError:
      return {
        ...state,
        configFetchError: action.configFetchError
      };
    case configLoadingClear:
    case creditPackageLoadingClear:
    case cmpConfigurationLoadingClear:
    case cartConfigurationLoadingClear:
    case bookingWidgetConfigurationLoadingClear:
      return {
        ...state,
        configFetchError: ''
      };
    case configLoadingSuccess:
      return {
        ...state,
        isLoyaltyEnabled: action.isLoyaltyEnabled,
        isInPrimerState: action.isInPrimerState,
        isOffersEnabled: action.isOffersEnabled,
        loyaltyPointsMultiplier: action.loyaltyPointsMultiplier,
        isLoyaltyReferralEnabled: action.isLoyaltyReferralEnabled,
        showCaliforniaResortFeeVariation: action.showCaliforniaResortFeeVariation,
        isLoyaltyPointsExpirationOnProfileEnabled: action.isLoyaltyPointsExpirationOnProfileEnabled,
        loyaltyPointsToEarn: action.loyaltyPointsToEarn,
        loyaltyEarnThresholdNights: action.loyaltyEarnThresholdNights,
        showPackagesInAddGuestsModal: action.showPackagesInAddGuestsModal
      };
    case planConfigLoadingSuccess:
      return {
        ...state,
        additionalPersonAmount: action.config.additionalPersonAmount,
        affirmPubKey: action.config.affirmPubKey,
        filters: action.config.filters,
        isFetchRestrictionsEnabled: action.config.isFetchRestrictionsEnabled,
        isHighestRatesEnabled: action.config.isHighestRatesEnabled,
        isRateCalEnabled: action.config.isRateCalEnabled,
        updateSearchFullPageRefresh: action.config.updateSearchFullPageRefresh,
        includeResortFeeInSuitePrice: action.includeResortFeeInSuitePrice,
        pricePerWaterPass: action.config.pricePerWaterPass,
        isRoomUnbundledRateWPAvailable: action.config.isRoomUnbundledRateWPAvailable,
        suitePriceIncludedTaxesAndFees: action.config.suitePriceIncludedTaxesAndFees
      };
    case creditPackageLoadingSuccess:
      return {
        ...state,
        isDiningPackagesEnhancedUIEnabled: action.isDiningPackagesEnhancedUIEnabled
      };
    case cmpConfigurationLoadingSuccess:
      return {
        ...state,
        isDynamicTileEnabled: action.isDynamicTileEnabled,
        hideOnlineCheckinText: action.hideOnlineCheckinText,
        parkingFeeCallout: action.parkingFeeCallout,
        parkingFeeCalloutTitle: action.parkingFeeCalloutTitle,
        parkingFeeCalloutDescription: action.parkingFeeCalloutDescription,
        uffTileEnabled: action.uffTileEnabled,
        isCancelReservationEnabled: action.isCancelReservationEnabled
      };
    /* ABCD TEST FOR PARKING FEE */
    case paymentConfigurationLoadingSuccess:
      return {
        ...state,
        perNightParkingFee1: action.perNightParkingFee1,
        perNightParkingFee2: action.perNightParkingFee2,
        perNightParkingFee3: action.perNightParkingFee3,
        parkingFeeStartDate: action.parkingFeeStartDate,
        parkingFeeEndDate: action.parkingFeeEndDate,
        clientCardTokenization: action.clientCardTokenization,
        creditCardAuthUrl: action.creditCardAuthUrl,
        creditEnabled: action.creditEnabled,
        affirmEnabled: action.affirmEnabled,
        applePayEnabled: action.applePayEnabled,
        googlePayEnabled: action.googlePayEnabled,
        payPalEnabled: action.payPalEnabled,
        amexEnabled: action.amexEnabled,
        taxAndFeeLabels: action.taxAndFeeLabels,
        bookingSummaryParkingFee: action.bookingSummaryParkingFee
      };
    case leadGenConfigurationLoadingSuccess:
      return {
        ...state,
        shouldLeadGenPop: action.shouldLeadGenPop
      };
    case userSettingsLoadingSuccess:
      return {
        ...state,
        newPatchEndpoint: action.newPatchEndpoint
      };
    case resetLodgeConfig:
      return {
        ...initialState
      };
    case cartConfigurationLoadingSuccess:
      return {
        ...state,
        isCartActive: action.config.isCartActive
      };
    case bookingWidgetConfigurationLoadingSuccess:
      return {
        ...state,
        beginReservationsDate: action.beginReservationsDate,
        maxOccupancy: action.maxOccupancy
      };
    /* ABCD TEST FOR PARKING FEE */
    default:
      return state;
  }
};

export default reducer;

export const getLoyaltyEnabledLocation = state => {
  return state.isLoyaltyEnabled;
};

export const getOffersEnabledLocation = state => {
  return state.isOffersEnabled;
};

export const getPointsMultiplier = state => {
  return state.loyaltyPointsMultiplier;
};

export const getReferralEnabledLocation = state => {
  return state.isLoyaltyReferralEnabled;
};

export const getDiningPackagesEnhancedUIActive = state => {
  return state.isDiningPackagesEnhancedUIEnabled;
};

export const getDynamicTileActive = state => {
  return state.isDynamicTileEnabled;
};

export const getHideOnlineCheckinText = state => {
  return state.hideOnlineCheckinText;
};

export const getaffirmPubKey = state => {
  return state.affirmPubKey;
};

export const getshowCaliforniaResortFeeVariation = state => {
  return state.showCaliforniaResortFeeVariation;
};

export const getPricePerWaterPass = state => {
  return state.pricePerWaterPass;
};

export const getFilters = state => {
  return JSON.parse(state.filters);
};

export const getAdditionalPersonAmount = state => {
  return state.additionalPersonAmount;
};

export const getIsHighestRatesEnabled = state => {
  return state.isHighestRatesEnabled;
};

export const getIsRateCalEnabled = state => {
  return state.isRateCalEnabled;
};

export const getUpdateSearchFullPageRefresh = state => {
  return state.updateSearchFullPageRefresh;
};

export const getFetchRestrictionsEnabled = state => {
  return state.isFetchRestrictionsEnabled;
};

export const getPerNightParkingFee1 = state => {
  return state.perNightParkingFee1;
};

export const getPerNightParkingFee2 = state => {
  return state.perNightParkingFee2;
};

export const getPerNightParkingFee3 = state => {
  return state.perNightParkingFee3;
};

export const getParkingFeeStartDate = state => {
  return state.parkingFeeStartDate;
};

export const getParkingFeeEndDate = state => {
  return state.parkingFeeEndDate;
};

export const getClientCardTokenization = state => {
  return state.clientCardTokenization;
};

export const getCreditCardAuthUrl = state => {
  return state.creditCardAuthUrl;
};

export const getPaymentMethodsAllowed = state => {
  return {
    creditEnabled: state.creditEnabled,
    affirmEnabled: state.affirmEnabled,
    applePayEnabled: state.applePayEnabled,
    googlePayEnabled: state.googlePayEnabled,
    payPalEnabled: state.payPalEnabled,
    amexEnabled: state.amexEnabled
  };
};

export const getShouldLeadGenPop = state => {
  return state.shouldLeadGenPop;
};

export const getParkingCallout = state => {
  return state.parkingFeeCallout;
};

export const getParkingCalloutTitle = state => {
  return state.parkingFeeCalloutTitle;
};

export const getParkingCalloutDescription = state => {
  return state.parkingFeeCalloutDescription;
};

export const getParkingFeeWarning = state => {
  return state.bookingSummaryParkingFee;
};

export const getUffTileEnabled = state => {
  return state.uffTileEnabled;
};

export const getIsLoyaltyPointsExpirationOnProfileEnabled = state => {
  return state.isLoyaltyPointsExpirationOnProfileEnabled;
};

export const getIsCartActive = state => {
  return state.isCartActive;
};

export const getIsRoomUnbundledRateWPAvailable = state => {
  return state.isRoomUnbundledRateWPAvailable;
};

export const getIsNewPatchEndpoint = state => {
  return state.newPatchEndpoint;
};

export const getMaxOccupancy = state => state.maxOccupancy;

export const getBeginReservationDate = state => state.beginReservationsDate;

export const getLoyaltyPointsToEarn = state => {
  return state.loyaltyPointsToEarn;
};

export const getLoyaltyEarnThresholdNights = state => {
  return state.loyaltyEarnThresholdNights;
};

export const getShowPackagesInAddGuestsModal = state => {
  return state.showPackagesInAddGuestsModal;
};

export const getSuitePriceIncludedTaxesAndFees = state => {
  return state.suitePriceIncludedTaxesAndFees;
};

export const getTaxAndFeesLabels = state => {
  return state.taxAndFeeLabels ? JSON.parse(state.taxAndFeeLabels) : {};
};
