import AvailabilityApiclient from '../../api/clients/AvailabilityApiClient';
import { GwDatesWrapper } from '../../components/_internal_date_/gwDatesWrapper';
import { initializeState } from '../../utilities/storageUtils';
import { getResortLocation } from './CurrentLodge';

const getDealSuccessType = 'GET_DEAL_SUCCESS';
const getDealLoadingType = 'GET_DEAL_LOADING';
const getDealErrorType = 'GET_DEAL_ERROR';
const clearDealType = 'CLEAR_DEAL';

// SET AN INITIAL STATE FOR REDUX
const initialState = {
  dealData: {},
  sendingGet: false,
  getFailed: false,
  getErrorMessage: ''
};

export const actionCreators = {
  // Get deal by promo code.
  getDealFromPromoCode: promoCode => (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);

    dispatch({
      type: getDealLoadingType,
      sendingGet: true
    });

    // Perform the get request.
    const apiClient = new AvailabilityApiclient('v2.2');
    const request = apiClient.getDealFromPromoCode(resortLocation, promoCode);
    return request.then(
      response => {
        const dealEnd = response?.data[0]?.stayEndDate;
        const isDealEndValid = GwDatesWrapper.isSameOrAfter(dealEnd, GwDatesWrapper.today(), 'days');
        let payload = {
          type: isDealEndValid ? getDealSuccessType : clearDealType
        };
        if (isDealEndValid) {
          payload.dealData = response.data[0];
        }

        dispatch(payload);
      },
      err => {
        dispatch({
          type: getDealErrorType,
          getErrorMessage: `Ops, ${err.message}`
        });
      }
    );
  },

  // Get deal by promo code.
  clearDeal: () => dispatch => {
    dispatch({ type: clearDealType });
  }
};

// This reducer combines reducers for providing new values
// to the Redux store based on actions defined above
//
// If the TYPE matches the action, the new state will replace the old
// this occurs on a per action basis and you should see the Redux store
// (state tree) reflect the change
const reducer = (state, action) => {
  state = initializeState(state, initialState);

  // Get Book information reducers
  switch (action.type) {
    // Post data request succeded
    case getDealSuccessType:
      return {
        ...state,
        dealData: action.dealData,
        sendingGet: false,
        getFailed: false,
        getErrorMessage: ''
      };
    // The request is currently running
    case getDealLoadingType:
      return {
        ...state,
        sendingGet: action.sendingGet
      };
    // The Post request failed
    case getDealErrorType:
      return {
        ...state,
        dealData: {},
        sendingGet: false,
        getFailed: true,
        getErrorMessage: action.getErrorMessage
      };

    // handle the offerCode clear event
    case clearDealType:
      return {
        ...state,
        dealData: {}
      };

    default:
      return state;
  }
};

export default reducer;

// selectors
export const getDealDataFromEntities = state => {
  const { dealData } = state;
  return { ...dealData };
};
