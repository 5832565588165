import get from 'lodash-es/get';
import sortBy from 'lodash-es/sortBy';
import { combineReducers } from 'redux';
import EventCalendarApiClient from '../../api/clients/EventCalendarApiClient';
import { getErrorMessage } from '../../utilities/messageUtils';
const getEventCalendarSuccessType = 'GET_EVENT_CALENDAR_SUCCESS';
const getEventCalendarErrorType = 'GET_EVENT_CALENDAR_ERROR';

export const actionCreators = {
  getEventCalendarDetails: values => (dispatch, getState) => {
    const aemEventApiUrl = values.endPointUrl;
    const eventDate = values.eventDate;
    (async () => {
      try {
        const apiClient = new EventCalendarApiClient(aemEventApiUrl);
        const details = await apiClient.getEventDetails(eventDate);
        const { data } = details;

        if (data) {
          dispatch({
            type: getEventCalendarSuccessType,
            response: data
          });
        }
      } catch (error) {
        dispatch({
          type: getEventCalendarErrorType,
          message: getErrorMessage(error.message)
        });
      }
    })();
  }
};

const data = (state = {}, action) => {
  switch (action.type) {
    case getEventCalendarSuccessType:
      return action.response;
    case getEventCalendarErrorType:
      return {};
    default:
      return state;
  }
};

const errorMessage = (state = null, action) => {
  switch (action.type) {
    case getEventCalendarErrorType:
      return action.message;
    case getEventCalendarSuccessType:
      return null;
    default:
      return state;
  }
};

const requestSuccess = (state = false, action) => {
  switch (action.type) {
    case getEventCalendarSuccessType:
      return true;
    case getEventCalendarErrorType:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  requestSuccess,
  data,
  errorMessage
});

// Selectors
/**
 * Gets Event Header message (eventsHeader) for EventCalendar.
 * @param {Object} state EventCalendar entity store.
 * @return {String} - Event Header.
 */
export const getEventHeader = state => get(state, 'data.eventsHeader');

/**
 * Get Event Hours (eventsHoursHeader) for EventCalendar header.
 * @param {Object} state EventCalendar entity store.
 * @return {String} - Events Hours Header.
 */
export const getEventsHoursHeader = state => get(state, 'data.eventsHoursHeader');

/**
 * Get Event Hours message (eventsHoursCopy) for EventCalendar header.
 * @param {Object} state EventCalendar entity store.
 * @return {string} Event hours message.
 */
export const getEventsHoursCopy = state => get(state, 'data.eventsHoursCopy');

/**
 * Get Sorted Events List for EventCalendar.
 * @param {Object} state EventCalendar entity store.
 * @return {[Object]} Sorted EventList by event time.
 */
export const getSortedEventListByEventTime = state => {
  return sortBy(get(state, 'data.eventList'), event => event.startTime);
};
