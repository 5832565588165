import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Counter, Flex } from '../../../../../ui-kit';

const propTypes = {
  handleUpdatePackageQuantity: PropTypes.func,
  handleDeletePackage: PropTypes.func,
  packageItem: PropTypes.object
};

const defaultProps = {
  handleUpdatePackageQuantity: () => {},
  handleDeletePackage: () => {},
  packageItem: null
};

const CartCounterContainer = ({ handleUpdatePackageQuantity, handleDeletePackage, packageItem }) => {
  const [value, setValue] = useState(packageItem?.quantity ?? 0);

  const handleUpdateStates = value => {
    if (value === 0) return handleDeletePackage(packageItem);
    handleUpdatePackageQuantity(value, packageItem);
  };

  useEffect(() => {
    setValue(packageItem?.quantity ?? 0);
  }, [packageItem]);

  return (
    <Flex>
      <Counter
        onChange={e => handleUpdateStates(e)}
        value={value}
        variation="small"
        min={0}
        width={40}
        max={+packageItem?.maxPackageLimit}
      />
    </Flex>
  );
};

CartCounterContainer.propTypes = propTypes;
CartCounterContainer.defaultProps = defaultProps;

export default CartCounterContainer;
