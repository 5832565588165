/*
As detailed on this user story: https://dev.azure.com/gwdigital/GW%20Digital%20Project/_workitems/edit/55167/
during the removal of the 'moment' date library it was chosen to have
this file be the only place where 'dayjs' would be allowed to be imported or mentioned.
*/
import internalDate from 'dayjs';
import { DATE_FORMATS } from '../../utilities/constants';
internalDate.isInternalDate = internalDate.isDayjs;
const plugins = [
  require('dayjs/plugin/localeData'),
  require('dayjs/plugin/localizedFormat'),
  require('dayjs/plugin/pluralGetSet'),
  require('dayjs/plugin/isBetween'),
  require('dayjs/plugin/weekday'),
  require('dayjs/plugin/customParseFormat'),
  require('dayjs/plugin/duration')
];
plugins.forEach(p => internalDate.extend(p));

const _gwDateFromat = DATE_FORMATS.default;
const _today = internalDate();
const _yesterday = _today.subtract(1, 'day');
const _tomorrow = _today.add(1, 'day');
const _localeData = internalDate.localeData();
const _getInternalDate = (date, format, clone = false) =>
  internalDate.isInternalDate(date)
    ? clone
      ? date.clone()
      : date
    : format
    ? internalDate(date, format)
    : internalDate(date);
const _createDate = dateStr => _getInternalDate(dateStr).format(_gwDateFromat);
const _todayStr = _createDate(_today);
const _yesterdayStr = _createDate(_yesterday);
const _tomorrowStr = _createDate(_tomorrow);

/**
 * This wrapper is going to abstract the date library used in the application.
 *  https://medium.com/swlh/best-moment-js-alternatives-5dfa6861a1eb
 * Main principles/approach:
 *  1. We only deal with 'date' portion of the 'Date' object, not the 'time' portion in this application
 *  2. We represent the 'date' as a string using 'DATE_FORMATS.default' date format
 *  3. No part of this application should use 'Date' or 'moment' object to create, store, calculate or format date information
 *  4. Code in this application should use this wrapper class for any date related operation instead of importing 'moment' library and utilizing that, only exception (for now) is this wrapper class
 */

const isStringAndDefaultFormat = (dateStr, inputFormat = _gwDateFromat) =>
  typeof dateStr === 'string' && inputFormat === _gwDateFromat && /^(1|2)\d{3}-(0|1)\d-(0|1|2|3)\d$/.test(dateStr); // Regex for YYYY-MM-DD
// Year 4 digits must start with 1 or 2 / Month 2 digits: 0 or 1 / Day 2 digits: 0, 1, 2, or 3

export const GwDatesWrapper = {
  // GET CURRENT DATE
  today: () => _todayStr,
  yesterday: () => _yesterdayStr,
  // DURATION
  duration: number => internalDate.duration(number),
  // PARSE DATESTR USING FORMAT AND RETURN STANDARD DATE AS STRING
  parseDate: (dateStr, format) => _createDate(_getInternalDate(dateStr, format)),
  // GET CURRENT MONTH
  month: (dateStr, monthName) => _getInternalDate(dateStr).month(monthName),
  // GET CURRENT YEAH
  year: (dateStr, newYearNumber) => _getInternalDate(dateStr).year(newYearNumber),
  // FORMAT DATE
  format: (dateStr, format = _gwDateFromat) =>
    isStringAndDefaultFormat(dateStr, format) ? dateStr : _getInternalDate(dateStr).format(format),
  // END OF UNITY
  endOf: (dateStr, unit = 'days') => _createDate(_getInternalDate(dateStr, null, true).endOf(unit)),
  // START OF UNITY
  startOf: (dateStr, unit = 'days') => _createDate(_getInternalDate(dateStr, null, true).startOf(unit)),
  // DAY
  day: dateStr => _getInternalDate(dateStr).day(),
  // WEEKDAY
  weekday: dateStr => _getInternalDate(dateStr).weekday(),
  // ADD TIME TO DATE
  add: (dateStr, amount, unit = 'days') => _createDate(_getInternalDate(dateStr, null, true).add(amount, unit)),
  // SUBTRACT TIME TO DATE
  subtract: (dateStr, amount, unit = 'days') =>
    _createDate(_getInternalDate(dateStr, null, true).subtract(amount, unit)),
  // DIFFERENCE BETWEEN DATES
  diff: (dateStr1, dateStr2, unit = 'days') => _getInternalDate(dateStr1).diff(_getInternalDate(dateStr2), unit),
  // IS DATE BETWEEN
  isBetween: (dateStr, start, end, unit = 'days', inclusionRules) =>
    _getInternalDate(dateStr).isBetween(_getInternalDate(start), _getInternalDate(end), unit, inclusionRules),
  // IS DATE THE SAME
  isSame: (dateStr1, dateStr2, unit = 'days') =>
    ((unit === 'day' || unit === 'days') && dateStr1 === dateStr2) ||
    _getInternalDate(dateStr1).isSame(_getInternalDate(dateStr2), unit),
  // IS DATE AFTER
  isAfter: (dateStr1, dateStr2, unit = 'days') => _getInternalDate(dateStr1).isAfter(_getInternalDate(dateStr2), unit),
  // IS DATE BEFORE
  isBefore: (dateStr1, dateStr2, unit = 'days') =>
    _getInternalDate(dateStr1).isBefore(_getInternalDate(dateStr2), unit),
  // IS DATE THE SAME OR AFTER
  isSameOrAfter: (dateStr1, dateStr2, unit = 'days') =>
    _getInternalDate(dateStr1).isSame(_getInternalDate(dateStr2), unit) ||
    _getInternalDate(dateStr1).isAfter(_getInternalDate(dateStr2), unit),
  // IS DATE THE SAME OR BEFORE
  isSameOrBefore: (dateStr1, dateStr2, unit = 'days') =>
    _getInternalDate(dateStr1).isSame(_getInternalDate(dateStr2), unit) ||
    _getInternalDate(dateStr1).isBefore(_getInternalDate(dateStr2), unit),
  // IS DATE TODAY
  isToday: dateStr => _getInternalDate(dateStr).isSame(_today, 'day'),
  //SUBTRACT A DATE BY ANOTHER
  subtractDateByAnother: (date1, date2) => {
    return _createDate(_getInternalDate(date1) - _getInternalDate(date2));
  },
  // FORMAT RANGE
  formatRange: (firstDateStr, secondDateStr, format, separator = '-') =>
    `${_getInternalDate(firstDateStr).format(format)} ${separator} ${_getInternalDate(secondDateStr).format(format)}`,
  // SET DATE - Generic setter, accepting unit as first argument, and value as second argument
  set: (dateStr, unit = 'days', value) => _createDate(_getInternalDate(dateStr).set(unit, value)),
  // IS DATE A VALID DATE
  isValid: (date, strictCheck = false) =>
    strictCheck ? isStringAndDefaultFormat(date) && _getInternalDate(date).isValid() : _getInternalDate(date).isValid(),
  // FORMAT OF LOCALE
  getLocaleDateFormat: (formatKey = 'L') => _localeData.longDateFormat(formatKey),
  // LOCALE FIRST DAY OF THE WEEK
  firstDayOfWeek: () => _localeData.firstDayOfWeek(),
  // --------------------- UNUSED LEFT FOR FUTURE REFERENCE:
  tomorrow: () => _tomorrowStr,
  // STANDARD DATE FORMAT FOR THIS WRAPPER
  gwDateFromat: _gwDateFromat,
  // GET LOCALE DATA
  getLocaleData: () => _localeData,
  // INTERNAL DATE PARSEZONE
  parseZone: dateStr => internalDate.parseZone(dateStr),
  // CREATE CC DATE
  createCCDate: (month, year) => this.format(this.parseDate(`${month}/${year}`, 'MM/YYYY'), 'YYYY-MM'),
  // IS DATE TOMORROW
  isTomorrow: dateStr => _getInternalDate(dateStr).isSame(_tomorrow, 'day'),
  // IS DATE YESTERDAY
  isYesterday: dateStr => _getInternalDate(dateStr).isSame(_yesterday, 'day')
};
