import manageReservationTypes from './managereservation.types';

const INITIAL_STATE = {
  data: {},
  isLoading: false,
  errorMessage: null,
  requestSuccess: false
};

const manageReservationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case manageReservationTypes.GET_MANAGERESERVATIONDETAILS_REQUEST:
      return { ...state, isLoading: true };
    case manageReservationTypes.GET_MANAGERESERVATIONDETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.response,
        errorMessage: null
      };
    case manageReservationTypes.GET_MANAGERESERVATIONDETAILS_FAILURE:
      return { ...state, isLoading: false, data: {}, errorMessage: action.message };
    case manageReservationTypes.CLEAR_MANAGERESERVATIONDETAILS:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default manageReservationReducer;
