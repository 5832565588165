//details.js
export const PROMO_CODE_COPY = 'Code: <PROMOCODE> applied';
export const CHANGE_SUITE_SELECTION = 'Change Suite Selection';
export const ROOM_SAVINGS_APPLIED = 'Room Savings Applied:';

//Footer.js
export const YOUR_SAVINGS = 'Your Savings:';

//PackageList.js
export const REMOVE = 'Remove';
export const WATERPARK_PASS = 'Water Park Passes';
export const INCLUDED = 'INCLUDED';
export const ROOM_WITH_NO_WATER_PASS =
  'Add <b><PASSES> water park <PASSES_TEXT></b> for just <b>$<PRICE> per night</b> - Unlimited access during all days of your stay';
export const ADD_PASSES = 'Add <PASSES_TEXT>';

//Subtotal.js
export const SUBTOTAL = 'Subtotal';
export const TAXES_NOT_INCLUDED = 'Taxes and fees not included';

//TotalSavings.js
export const TOTAL_SAVINGS = 'Total Savings';

//Totals.js
export const POINTS_APPLIED = '<POINTS> Voyagers Points applied';
export const TOTAL = 'Total';

//Offers.js
export const EXPIRATION_DATE = 'This Deal will expire on ';
export const BONUS_DEALS_APPLIED = 'Bonus Deals applied';
export const EXCEED_AMOUNT = 'Amount exceeds the room price, please remove & continue';

//AdditionalCharges.js
export const ADDITIONAL_CHARGES = 'Additional Charges';

//Header.js
export const CHECK_IN = 'Check-In';
export const CHECK_OUT = 'Check-Out';
export const STAY_SUMMARY_DESKTOP = 'Stay Summary';
export const STAY_SUMMARY_MOBILE = 'Stay Summary:';

//DueAmount.js
export const DUE_TODAY = 'Due today';
export const DUE_TODAY_CAP = 'Due Today';
export const DUE_CHECKIN = 'Due at Check-In';

//Content.js
export const SEASONS_CODE = 'SEASONS';

//Cart Copy
export const BOOKING_CART_TITLE = 'Booking Cart';
export const BOOKING_CART_EMPTY = 'Your Cart is currently empty';
export const BOOKING_CART_BOOK_NOW = 'Book Now';
export const BOOKING_CART_SUBTOTAL = '(Subtotal)';
export const GUESTS_COPY = '<ADULT_COUNT> <ADULT_TEXT> - <KID_COUNT> <KID_TEXT>';
export const DATES = '<CHECK_IN> - <CHECK_OUT>';
export const PROMOTION_CODE = 'Code: <PROMO>';
export const REMOVE_PACKAGE = 'Are you sure you want to remove <PACKAGE> from your cart?';
export const REMOVE_SUITE =
  '<WARNING> Removing a suite will empty your cart. Are you sure you want to remove <PACKAGE> and all additional items?"';
export const EDIT_SUITE =
  'The other items in your cart are attached to the <PACKAGE> . Changing suites empties your cart and they will need to be re-added.';
export const REMOVE_ITEM_TITLE = 'Remove Item';
export const REMOVE_SUITE_TITLE = 'Remove Suite';
export const EDIT_SUITE_TITLE = 'Edit Suite';
export const EDIT = 'Edit';
export const CANCEL = 'Cancel';
export const CONTINUE_BOOKING = 'Continue Booking';
export const CONTINUE_TO_STEP = 'Continue to <STEP>';
export const EXPIRED_BOOKING_TITLE = 'Your booking session has expired';
export const EXPIRED_BOOKING_TEXT = 'Please return to the Suites page to restart your booking.';
export const RESTART_MY_BOOKING = 'Restart My Booking';
export const WARNING = 'Warning';
