import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Text } from '../../../../../ui-kit';
import * as COPY from '../utilities/copy';

import { useMediaQuery } from 'react-responsive';
import { Divider } from '../../../../../../pages/ReservationDetail/styles';
import AlertSuccessIcon from '../../../../../ui-kit/icons/AlertSuccessIcon';
import theme from '../../../../../ui-kit/themes/theme';
import { editCopyByReplaceString } from '../../../../../utilities/copyFunctions';
import { SummaryContent } from '../../../Content';

const propTypes = {
  /** Title of the suite */
  suiteTitle: PropTypes.string.isRequired,

  /** Total for the suite */
  suiteTotal: PropTypes.number.isRequired,

  /** Promo code used in the reservation */
  promoCode: PropTypes.string,

  /** Number of guests as `1 Adult, 0 Kid` or `2 Adults, 2 Kids` */
  guestCountText: PropTypes.string.isRequired,

  /** Flag for the suite that has new guests added from CMP */
  hasNewGuests: PropTypes.bool,

  /** Number of new guests added from CMP */
  newCMPGuestsCount: PropTypes.object
};

export const SuiteDetails = ({
  suiteTitle,
  suiteTotal,
  guestCountText,
  promoCode,
  hasNewGuests,
  newCMPGuestsCount,
  addGuestReservation
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.md})`
  });

  const newGuestsWithFeeTotal = () => {
    const total =
      newCMPGuestsCount?.feeGuests > 0
        ? `$${(newCMPGuestsCount?.feeGuests * newCMPGuestsCount?.extraPerGuestAmount).toFixed(2)}`
        : `$0.00`;
    return total;
  };
  return (
    <>
      <SummaryContent.Divider borderThickness={2} />
      <SummaryContent.Item fontWeight="bold" mb={8}>
        <SummaryContent.Description>
          <Text fontSize={0} lineHeight={16}>
            {addGuestReservation ? addGuestReservation?.suiteDescription : suiteTitle}
          </Text>
        </SummaryContent.Description>
        <SummaryContent.SubtotalAmount>
          <Text fontSize={0} lineHeight={16}>
            {addGuestReservation
              ? `$${addGuestReservation?.suitePrice.toFixed(2)}`
              : `$${
                  newCMPGuestsCount?.feeGuests > 0
                    ? (suiteTotal - newCMPGuestsCount?.feeGuests * newCMPGuestsCount?.extraPerGuestAmount).toFixed(2)
                    : suiteTotal.toFixed(2)
                }`}
          </Text>
        </SummaryContent.SubtotalAmount>
      </SummaryContent.Item>
      {isMobile ? null : !addGuestReservation ? <Divider /> : null}
      {hasNewGuests && !addGuestReservation && (
        <>
          {newCMPGuestsCount?.unbillableGuests > 0 ? (
            <>
              <SummaryContent.Item fontWeight="bold" mb={8}>
                <SummaryContent.Description>
                  <Text fontSize={0} lineHeight={18}>
                    {editCopyByReplaceString(
                      COPY.NEW_GUESTS_ADDED,
                      String(newCMPGuestsCount?.unbillableGuests),
                      '<GUEST_COUNT>'
                    )}
                  </Text>
                </SummaryContent.Description>
                <SummaryContent.SubtotalAmount>
                  <Text fontSize={0} lineHeight={16}>{`$0.00`}</Text>
                </SummaryContent.SubtotalAmount>
              </SummaryContent.Item>
              <SummaryContent.Item>
                <SummaryContent.Description>
                  <Text color="nightSkyBlack.80" fontSize={0} lineHeight={18}>
                    {COPY.NEW_GUESTS_IN_BASE_OCUPANCY}
                  </Text>
                </SummaryContent.Description>
              </SummaryContent.Item>
            </>
          ) : null}
          {newCMPGuestsCount?.feeGuests > 0 ? (
            <>
              <SummaryContent.Item fontWeight="bold" mb={8}>
                <SummaryContent.Description>
                  <Text fontSize={0} lineHeight={18}>
                    {editCopyByReplaceString(
                      COPY.NEW_GUESTS_ADDED,
                      String(newCMPGuestsCount?.feeGuests),
                      '<GUEST_COUNT>'
                    )}
                  </Text>
                </SummaryContent.Description>
                <SummaryContent.SubtotalAmount>
                  <Text fontSize={0} lineHeight={16}>
                    {newGuestsWithFeeTotal()}
                  </Text>
                </SummaryContent.SubtotalAmount>
              </SummaryContent.Item>
              <SummaryContent.Item>
                <SummaryContent.Description>
                  <Text color="nightSkyBlack.80" fontSize={0} lineHeight={18}>
                    {COPY.NEW_GUESTS_IN_MAX_OCUPANCY}
                  </Text>
                </SummaryContent.Description>
              </SummaryContent.Item>
            </>
          ) : null}
        </>
      )}
      <SummaryContent.Item>
        <SummaryContent.Description>
          <Text fontSize={0} lineHeight={18}>
            <Flex>
              {guestCountText}
              {((addGuestReservation && addGuestReservation.splash) || hasNewGuests) && <SummaryContent.NewGuestsTag />}
            </Flex>
          </Text>
        </SummaryContent.Description>
      </SummaryContent.Item>
      {(promoCode || (addGuestReservation && addGuestReservation?.code)) && (
        <SummaryContent.Item fontWeight="bold" color="successGreen">
          <SummaryContent.Description>
            <Text color="deepForestGreen.100" fontSize={0} lineHeight={16}>
              {addGuestReservation
                ? addGuestReservation?.code
                : `${COPY.SUMMARY_CODE}: ${promoCode} ${COPY.SUMMARY_APPLIED}`}
            </Text>
          </SummaryContent.Description>
          <AlertSuccessIcon size="21px" />
        </SummaryContent.Item>
      )}
    </>
  );
};

SuiteDetails.propTypes = propTypes;

SuiteDetails.defaultProps = {
  iconSize: 16
};
