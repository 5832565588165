import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import routes from '../../../../../../routes';
import { getTheFirstElementOfArray } from '../../../../../../utilities/arraysUtils';
import { PACKAGE_CHARGE_TYPE, ROOM_RATE_TYPE } from '../../../../../../utilities/constants';
import { getNumberOfBillableGuests } from '../../../../../../utilities/guestsUtils';
import { getCurrencyCodeText } from '../../../../../../utilities/planUtils';
import SelectPackageQuantity from '../../../../../Packages/SelectPackageQuantity';
import { Animation, Box, Flex, Text } from '../../../../../ui-kit';
import theme from '../../../../../ui-kit/themes/theme';
import {
    editCopyByReplaceMultipleStrings,
    editCopyByReplaceString,
    pluralize
} from '../../../../../utilities/copyFunctions';
import { SummaryContent } from '../../../Content';
import { CART_MODIFY_MODAL_TYPES, CART_MODIFY_MODAL_VARIATIONS } from '../../utilities/constants';
import * as COPY from '../../utilities/copy';
import { getPkgImage, getPkgImageValidation, isDiningCredit } from '../../utilities/functions';
import {
    AddWaterPassesSummary,
    CartCardImageContainer,
    CartCardTitle,
    CartCardsContainer,
    CartPackageListItemContainer,
    DisableAddButtonText,
    SummaryPackagesCardContainer
} from '../styles';
import CartCounterContainer from './CartCounterContainer';
const propTypes = {
  /** String date object with the checkin date */
  checkinDateSelection: PropTypes.string.isRequired,

  /** String date object with the checkout date */
  checkoutDateSelection: PropTypes.string.isRequired,

  /** Currenty selected packages */
  currentSelectedPackages: PropTypes.arrayOf(
    PropTypes.shape({
      packageCode: PropTypes.string,
      packageName: PropTypes.string,
      currencyCode: PropTypes.string,
      quantity: PropTypes.number,
      amount: PropTypes.number,
      isAdjustment: PropTypes.bool,
      showQuantitySelect: PropTypes.bool
    })
  ),

  /** Number of guests for the reservation */
  numberOfGuests: PropTypes.number.isRequired,

  /** Flag to indicate the property is in Niagara */
  isNiagaraProperty: PropTypes.bool,

  /** Function to update the package quantity */
  upsertPackage: PropTypes.func,

  /** Funtion to delete a package */
  handleDeletePackage: PropTypes.func,

  /** Flag to indicate if the reservation has selected packages */
  hasSelectedPackages: PropTypes.bool
};

const getPackagePriceDisplay = packageItem => {
  if (packageItem.maxPackageLimit === '1') return `${packageItem.amount.toFixed(2)}`;
  return `${(parseInt(packageItem.quantity) * packageItem.amount).toFixed(2)}`;
};

const PackageList = ({
  currentSelectedPackages,
  handleDeletePackage,
  isNiagaraProperty,
  checkinDateSelection,
  checkoutDateSelection,
  numberOfGuests,
  handleUpdatePackageQuantity,
  isCartVariation,
  handleUpdatePackageQuantityCounter,
  openModifyCartItemModal,
  resortLocationUrlParam,
  toggleOpen,
  lcoCode
}) => {
  if (!currentSelectedPackages || !currentSelectedPackages.length) return null;

  const handleRemovePackageModal = packageItem => {
    const removeItemConfirmationFunction = () => handleDeletePackage(packageItem);
    openModifyCartItemModal(
      packageItem,
      CART_MODIFY_MODAL_TYPES.REMOVE,
      CART_MODIFY_MODAL_VARIATIONS.PACKAGE,
      removeItemConfirmationFunction
    );
  };

  const moveToActivitiesAndCloseCart = () => {
    toggleOpen();
    window.location.href = removeLastSlash(resortLocationUrlParam) + routes.plan.activities;
  };

  const moveToDiningAndCloseCart = () => {
    toggleOpen();
    window.location.href = removeLastSlash(resortLocationUrlParam) + routes.plan.dining;
  };

  return (
    <>
      <SummaryPackagesCardContainer isCartVariation={isCartVariation}>
        {/* Packages Card */}
        {currentSelectedPackages.map((item, index) => {
          const priceToDisplay = getPackagePriceDisplay(item);
          return (
            !item.isAdjustment && (
              <CartCardsContainer key={`${item.packageCode + index}`} isCartVariation={isCartVariation}>
                {/* Package Image */}
                {isCartVariation ? (
                  <CartCardImageContainer
                    mr={3}
                    cover={getTheFirstElementOfArray(item.packageType) !== 'Pass'}
                    isDiningCredit={isDiningCredit(item)}>
                    {getPkgImageValidation(item) ? (
                      <Animation
                        src={getPkgImage(item)}
                        name={item.packageName || item.title}
                        autoplay={true}
                        loop={true}
                      />
                    ) : (
                      <img src={getPkgImage(item)} alt="" />
                    )}
                  </CartCardImageContainer>
                ) : null}
                {/* Package Details */}
                <CartPackageListItemContainer isCartVariation={isCartVariation}>
                  <SummaryContent.Item fontSize={0}>
                    <Flex
                      flexDirection={isCartVariation ? 'column' : 'row'}
                      justifyContent={'space-between'}
                      width="100%"
                      gap="14px">
                      <Flex flexGrow="1" flexDirection={isCartVariation ? 'column' : 'row'}>
                        {isCartVariation ? (
                          <CartCardTitle
                            fontSize={[0]}
                            fontWeight="bold"
                            lineHeight={[16]}
                            color="deepLakeBlue.100"
                            mb={3}
                            onClick={
                              item?.packageType?.includes('dining')
                                ? moveToDiningAndCloseCart
                                : moveToActivitiesAndCloseCart
                            }>
                            {item.packageName || item.title}
                          </CartCardTitle>
                        ) : (
                          <SummaryContent.Description lineHeight="18px">{item.packageName}</SummaryContent.Description>
                        )}
                        {(!isNiagaraProperty &&
                          item.quantity &&
                          !item.packageType?.includes('dining') &&
                          !item.packageType?.includes('bundle') &&
                          item.packageCode !== lcoCode) ||
                        (isNiagaraProperty &&
                          item.quantity &&
                          item.chargeType === PACKAGE_CHARGE_TYPE.byPackage &&
                          !item.packageType?.includes('bundle') &&
                          item.packageCode !== lcoCode) ? (
                          isCartVariation ? (
                            <CartCounterContainer
                              handleUpdatePackageQuantity={handleUpdatePackageQuantityCounter}
                              handleDeletePackage={handleDeletePackage}
                              packageItem={item}
                            />
                          ) : (
                            <Box width="80px" style={{ textAlign: 'right' }}>
                              <SelectPackageQuantity
                                isNiagaraProperty={isNiagaraProperty}
                                checkinDateSelection={checkinDateSelection}
                                checkoutDateSelection={checkoutDateSelection}
                                packageItem={item}
                                numberOfGuests={numberOfGuests}
                                handleUpdatePackageQuantity={handleUpdatePackageQuantity}
                              />
                            </Box>
                          )
                        ) : null}
                      </Flex>
                      <Flex gap="14px">
                        {!isCartVariation ? (
                          <SummaryContent.Action lineHeight="18px" onClick={() => handleDeletePackage(item)}>
                            {COPY.REMOVE}
                          </SummaryContent.Action>
                        ) : null}
                        <Flex alignItems="center" flexGrow="1">
                          {isCartVariation ? (
                            <Flex justifyContent="space-between" flexGrow="1">
                              <Flex>
                                <Box mr={7}>
                                  <SummaryContent.Action
                                    lineHeight="16px"
                                    fontSize={theme.fontSizes[0]}
                                    onClick={() => handleRemovePackageModal(item)}>
                                    {COPY.REMOVE}
                                  </SummaryContent.Action>
                                </Box>
                                <Box>
                                  <SummaryContent.Action
                                    lineHeight="16px"
                                    fontSize={theme.fontSizes[0]}
                                    onClick={
                                      item?.packageType?.includes('dining')
                                        ? moveToDiningAndCloseCart
                                        : moveToActivitiesAndCloseCart
                                    }>
                                    {COPY.EDIT}
                                  </SummaryContent.Action>
                                </Box>
                              </Flex>

                              <Box>
                                <Text fontSize={[1]} lineHeight={[18]} fontWeight="bold" color="deepLakeBlue.100">
                                  ${priceToDisplay}
                                </Text>
                              </Box>
                            </Flex>
                          ) : (
                            <SummaryContent.Amount>
                              ${priceToDisplay} {getCurrencyCodeText(item.currencyCode)}
                            </SummaryContent.Amount>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </SummaryContent.Item>
                </CartPackageListItemContainer>
              </CartCardsContainer>
            )
          );
        })}
      </SummaryPackagesCardContainer>
    </>
  );
};

export const PackagesList = ({
  checkinDateSelection,
  checkoutDateSelection,
  currentSelectedPackages,
  numberOfGuests,
  isNiagaraProperty,
  upsertPackage,
  deletePackage,
  hasSelectedPackages,
  isCartVariation = false,
  openModifyCartItemModal,
  resortLocationUrlParam,
  toggleOpen,
  selectedSuite,
  updateSuite,
  adultsCount,
  kidsAges
}) => {
  const handleUpdatePackageQuantitySelect = (e, packageItem) => {
    upsertPackage({ ...packageItem, quantity: e.target.value });
  };
  const handleUpdatePackageQuantityCounter = (value, packageItem) => {
    upsertPackage({ ...packageItem, quantity: value });
  };

  const price =
    selectedSuite?.rateOptions?.length > 1
      ? selectedSuite?.rateOptions?.find(rate => rate.rateType === ROOM_RATE_TYPE.roomWithWaterParkPass)
          ?.averageNightlyBaseRate -
        selectedSuite?.rateOptions?.find(rate => rate.rateType === ROOM_RATE_TYPE.roomOnlyWithoutWaterParkPass)
          ?.averageNightlyBaseRate
      : 0;
  const numberOfBillableGuests = getNumberOfBillableGuests(adultsCount, kidsAges);
  const copyObject = {
    '<PASSES>': numberOfBillableGuests,
    '<PRICE>': Math.round(price),
    '<PASSES_TEXT>': pluralize('pass', numberOfBillableGuests)
  };

  const lcoCode = selectedSuite?.lcoPackageCode ?? '';

  return (
    <>
      {!isCartVariation ? (
        <>
          <SummaryContent.Divider />
          <SummaryContent.Item fontSize={0} lineHeight="18px">
            {selectedSuite?.rateType === ROOM_RATE_TYPE.roomOnlyWithoutWaterParkPass ? (
              <AddWaterPassesSummary alignItems="center">
                <Text fontSize={0} lineHeight={18} fontWeight="medium">
                  {ReactHtmlParser(editCopyByReplaceMultipleStrings(COPY.ROOM_WITH_NO_WATER_PASS, copyObject))}
                </Text>
                <DisableAddButtonText minWidth="fit-content">
                  <SummaryContent.Action lineHeight="18px" onClick={updateSuite}>
                    <Text capitalize>
                      {editCopyByReplaceString(COPY.ADD_PASSES, pluralize('pass', numberOfGuests), '<PASSES_TEXT>')}
                    </Text>
                  </SummaryContent.Action>
                </DisableAddButtonText>
              </AddWaterPassesSummary>
            ) : (
              <>
                <SummaryContent.Description lineHeight="18px">{COPY.WATERPARK_PASS}</SummaryContent.Description>
                {COPY.INCLUDED}
              </>
            )}
          </SummaryContent.Item>
        </>
      ) : null}

      {hasSelectedPackages && (
        <PackageList
          currentSelectedPackages={currentSelectedPackages}
          handleDeletePackage={deletePackage}
          isNiagaraProperty={isNiagaraProperty}
          checkinDateSelection={checkinDateSelection}
          checkoutDateSelection={checkoutDateSelection}
          numberOfGuests={numberOfGuests}
          handleUpdatePackageQuantity={handleUpdatePackageQuantitySelect}
          handleUpdatePackageQuantityCounter={handleUpdatePackageQuantityCounter}
          isCartVariation={isCartVariation}
          openModifyCartItemModal={openModifyCartItemModal}
          resortLocationUrlParam={resortLocationUrlParam}
          toggleOpen={toggleOpen}
          lcoCode={lcoCode}
        />
      )}
    </>
  );
};

PackagesList.propTypes = propTypes;

PackagesList.defaultProps = {
  isNiagaraProperty: false,
  hasSelectedPackages: false
};

// Function to remove last slash from string
function removeLastSlash(str) {
  return str.replace(/\/$/, '');
}
