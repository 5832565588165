import PropTypes from 'prop-types';
import React from 'react';
import { SummaryModal } from '../../Modal';
import * as COPY from './utilities/copy';

import { Content } from './Content';

const propTypes = {
  /** Flag to indicate if the summary is visible or not */
  opened: PropTypes.bool,
  /** Function that executes when click on the Close Button */
  onClose: PropTypes.func
};

export const Summary = ({ opened, onClose, ...restProps }) => {
  return (
    <SummaryModal opened={opened}>
      <SummaryModal.Header>
        <SummaryModal.Title lineHeight={24}>{COPY.SUMMARY_TITLE}</SummaryModal.Title>
        <SummaryModal.CloseButton onClick={onClose} />
      </SummaryModal.Header>
      <SummaryModal.Content>
        <Content {...restProps} />
      </SummaryModal.Content>
    </SummaryModal>
  );
};

Summary.propTypes = propTypes;
Summary.defaultProps = {
  opened: false
};
