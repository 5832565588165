import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { GwDatesWrapper } from '../components/_internal_date_/gwDatesWrapper';
import { INELIGIBILITY_REASONS } from '../components/ReservationDetails/constants';
import theme from '../components/ui-kit/themes/theme';
import { getLocationDisplayName } from '../components/utilities/ResortLocations';
import { EReservationStatus } from '../utilities/availabilityUtils';
import {
    CABANAS_CODES_TYPES,
    CHECK_IN_STATUS,
    DATE_FORMATS,
    DAY_PASSES_TYPE,
    RESERVATION_STATUS,
    SITE_NIAGON
} from './constants';

export const ReservationsHeaderMessages = {
  UPCOMING: 'Upcoming Reservations',
  PAST: 'Past Reservations',
  CANCELLED: 'Canceled Reservations',
  MY_UPCOMING: 'My Upcoming Reservations',
  MY_PAST: 'My Past Reservations',
  MY_CANCELLED: 'My Canceled Reservations'
};

export const ReservationsUserMessages = {
  UPCOMING: "There aren't any upcoming reservations.",
  PAST: "There aren't any past reservations.",
  CANCELLED: "There aren't any canceled reservations.",
  MY_UPCOMING:
    'We cannot find any upcoming reservations. Please note that new reservations may take up to 30 minutes to appear here.',
  MY_PAST: 'We cannot find any past reservations. If you think this is an error, please try again later or',
  MY_CANCELLED: 'We cannot find any canceled reservations. If you think this is an error, please try again later or'
};

export const isValidReservation = (departureDate, reservationStatus) => {
  if (!departureDate || !reservationStatus) return false;
  const isAfter = GwDatesWrapper.isSameOrAfter(departureDate, GwDatesWrapper.today(), 'day');
  const isActive = reservationStatus !== RESERVATION_STATUS.canceled;
  if (isAfter && isActive) return true;
  else return false;
};

export const getReservationCurrency = site => {
  if (site === SITE_NIAGON) {
    return 'CAD';
  }

  return 'USD';
};

export const getOverviewReservationDetails = reservation => {
  const isCabana = Object.values(CABANAS_CODES_TYPES).includes(reservation?.roomType);
  const isDayPass = Object.values(DAY_PASSES_TYPE).includes(reservation?.roomType);
  const isReservation = !isCabana && !isDayPass;

  let roomName = 'Day Pass';

  if (isCabana) {
    roomName = get(reservation, 'roomDescription', 'N/A');
  } else if (isReservation) {
    roomName = 'N/A';
  }

  const getAmount = id => parseInt(get(reservation, id) ?? '0');
  const numAdults = getAmount('adults');
  const numChildren = getAmount('kidsCount');
  const totalGuests = numAdults + numChildren;

  return { roomName, numAdults, numChildren, totalGuests };
};

export const getOverviewReservationDates = reservation => {
  const arrival = GwDatesWrapper.format(reservation?.arrival, DATE_FORMATS.monthLetterDayYear);

  const departure = GwDatesWrapper.format(reservation?.departure, DATE_FORMATS.monthLetterDayYear);

  return `${arrival} - ${departure}`;
};

export const getOverviewReservationHeader = reservation => {
  const reservationId = reservation?.externalId ?? reservation?.id ?? null;
  const reservationDatesLabel = getOverviewReservationDates(reservation);
  const propertyName = getLocationDisplayName(reservation?.property);

  return { reservationId, reservationDatesLabel, propertyName };
};

export const getStackedVariantByStatus = reservationStatus => {
  switch (reservationStatus) {
    case EReservationStatus.PAST:
      return theme.stackedAccordions.secondary;

    case EReservationStatus.CANCELED:
      return theme.stackedAccordions.danger;

    default:
      return theme.stackedAccordions.primary;
  }
};

export const getCheckInStatus = checkIn => {
  if (!Boolean(checkIn)) {
    return CHECK_IN_STATUS.UNAVAILABLE;
  }

  if (checkIn.ineligibilityReason === INELIGIBILITY_REASONS.TOO_EARLY) {
    return CHECK_IN_STATUS.UNAVAILABLE;
  }

  if (checkIn.isEligible && checkIn.url) {
    return CHECK_IN_STATUS.AVAILABLE;
  }

  if (checkIn.ineligibilityReason === INELIGIBILITY_REASONS.PRE_CHECKED_IN) {
    return CHECK_IN_STATUS.SUCCESSFUL;
  }

  return CHECK_IN_STATUS.INVALID;
};

/**
 * Sorts a list of reservations by checkin date and reservation id
 * @param {array} reservations List of reservations
 * @returns array - sorted list of reservations
 */
export const sortReservationsListByCheckin = reservations => {
  if (isEmpty(reservations)) {
    return [];
  }

  let sortedReservations = reservations.sort((firstElement, secondElement) => {
    if (firstElement.arrival === secondElement.arrival) {
      return firstElement.id - secondElement.id;
    }

    return GwDatesWrapper.subtractDateByAnother(firstElement.arrival, secondElement.arrival);
  });

  return sortedReservations;
};
