import styled from 'styled-components';

import { Modal } from '../../../Modal';
import { Absolute, Box, Flex, Text } from '../../../ui-kit';
import theme from '../../../ui-kit/themes/theme';

export const ModalWrapper = styled(Modal)``;

export const CloseButtonContainer = styled(Absolute)`
  right: 26px;
  top: 30px;
  cursor: pointer;
`;

export const Content = styled(Box)`
  padding: 0px ${theme.space[7]}px;
  overflow-x: hidden;
`;

export const Divider = styled(Box)`
  border: 1px solid ${theme.colors.kiddiePoolBlue[10]};
  margin-bottom: ${theme.space[4]}px;
`;

export const Header = styled(Flex)`
  padding: ${theme.space[7]}px;
  color: ${theme.colors.deepLakeBlue[100]};
  align-items: center;
  width: 100%;
  position: relative;
  height: 82px;
  border-bottom: 1px solid ${theme.colors.snowMountainGrey[100]};
`;

export const Title = styled(Text)`
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.preciousStoneBlue[100]};
  font-size: 18px;
  padding: 0px;
  flex: 1;
  text-align: center;
`;
