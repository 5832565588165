import get from 'lodash-es/get';
import { getResortLocation } from '../../../../store/componentStores/CurrentLodge';
import {
    getAvailabilityHighestRatesErrorType,
    getAvailabilityInfoErrorType,
    getAvailabilityInfoSuccessType,
    getInteractionsData
} from '../../../../store/componentStores/Plans';
import {
    getDetailedAvailabilityErrorType,
    getPackageInteractionsData,
    selectSuiteType,
    setPersonalizationPackageRules
} from '../../../../store/componentStores/Suite';
import { availabilityTrackError } from '../../../../utilities/applicationInsights';
import { DATE_FORMATS, ROOM_RATES_ERROR_MSG, SUITE_ERROR_MSG } from '../../../../utilities/constants';
import duettoTracking from '../../../../utilities/duetto';
import { GwDatesWrapper } from '../../../../components/_internal_date_/gwDatesWrapper';
import {
    buildAddToCartEnhancedEcommerceObject,
    buildProductClickEnhancedECommerceObject,
    buildSearchObject
} from '../analyticsObjectBuilders';
import { getCurrentLocation } from './EventSuccessListeners';

export const onPlanListener = ({ type, personalizationRules, personalizationResponses, getState }) => {
  const isAvailabilitySuccess = type === getAvailabilityInfoSuccessType;
  const isAvailabilityError = type === getAvailabilityInfoErrorType;

  if (isAvailabilitySuccess) {
    const { dates, currentLodge } = getState();
    const resortLocation = getResortLocation(currentLodge);
    if (personalizationRules && personalizationRules.length > 0) {
      const interactionsData = getInteractionsData({
        personalizationResponses
      });
      window.dataLayer.push({
        event: EVENT_CATEGORIES.personalizationRules,
        rules: personalizationRules.join(),
        ...(interactionsData && {
          aud: interactionsData.userAudience,
          rec: interactionsData.recommendationEngine,
          recType: interactionsData.recommendationType,
          recValue: interactionsData.suiteCategory ? interactionsData.suiteCategory : ''
        })
      });
    }
    duettoTracking.trackAvailabilitySearch({
      resortLocation,
      arrival: dates.checkinDateSelection,
      departure: dates.checkoutDateSelection
    });
  }

  if (isAvailabilityError) {
    const { dates, currentLodge } = getState();
    const resortLocation = getResortLocation(currentLodge);
    duettoTracking.trackAvailabilityDenial({
      resortLocation,
      arrival: dates.checkinDateSelection,
      departure: dates.checkoutDateSelection
    });
  }
};

export const onHighestRatesError = event => {
  const { plans } = event.getState();
  availabilityTrackError(plans, getAvailabilityHighestRatesErrorType, SUITE_ERROR_MSG);
  return event;
};
onHighestRatesError.eventType = getAvailabilityHighestRatesErrorType;

export const onAvailabilityError = event => {
  const { plans } = event.getState();
  availabilityTrackError(plans, getAvailabilityInfoErrorType, ROOM_RATES_ERROR_MSG);
  return event;
};
onAvailabilityError.eventType = getAvailabilityInfoErrorType;

export const onDetailedAvailabilityError = event => {
  const { suite } = event.getState();
  availabilityTrackError(suite, getDetailedAvailabilityErrorType, ROOM_RATES_ERROR_MSG, true);
  return event;
};
onDetailedAvailabilityError.eventType = getDetailedAvailabilityErrorType;

export const onDetailedAvailabilitySuccess = event => {
  const { suite } = event.getState();
  const personalizationPackageRules = get(suite, 'personalizationPackageRules');
  if (personalizationPackageRules && personalizationPackageRules.length > 0) {
    const interactionsData = getPackageInteractionsData(suite);
    if (interactionsData && interactionsData.length > 0) {
      interactionsData.forEach(recommendation => {
        window.dataLayer.push({
          event: EVENT_CATEGORIES.personalizationRules,
          rules: personalizationPackageRules.join(),
          rec: recommendation.recommendationEngine,
          recType: recommendation.recommendationType,
          recValue: recommendation.packageCode
        });
      });
    } else {
      window.dataLayer.push({
        event: EVENT_CATEGORIES.personalizationRules,
        rules: personalizationPackageRules.join()
      });
    }
  }
};
onDetailedAvailabilitySuccess.eventType = setPersonalizationPackageRules;

export const onSuiteSelect = ({ type, eventProps, getState, selectedSuite }) => {
  if (type !== selectSuiteType || !selectedSuite) return;

  const { dates, currentLodge, guests, router } = getState();
  const currentPathLocation = router.location.pathname || '';
  const purchaseLocation = eventProps?.customPurchaseLocation
    ? eventProps?.customPurchaseLocation
    : getCurrentLocation(currentPathLocation);
  const resortLocation = getResortLocation(currentLodge);
  const position = eventProps?.currentPosition + 1;

  window.dataLayer.push(
    buildProductClickEnhancedECommerceObject(
      guests.kidsAges,
      selectedSuite,
      resortLocation,
      dates.checkoutDateSelection,
      dates.checkinDateSelection,
      guests.adultsCount,
      guests.kidsCount,
      position,
      purchaseLocation
    )
  );

  window.dataLayer.push(
    buildAddToCartEnhancedEcommerceObject(
      guests.kidsAges,
      selectedSuite,
      resortLocation,
      dates.checkoutDateSelection,
      dates.checkinDateSelection,
      guests.adultsCount,
      guests.kidsCount,
      position,
      purchaseLocation
    )
  );
};

export const onIsCaliforniaResident = ({ type, getState }) => {
  if (type !== 'SET_IS_CALIFORNIA_RESIDENT') return;
  const { plans } = getState();
  const clickType = !plans.isCaliforniaResident ? 'cali-checked' : 'cali-unchecked';
  window.dataLayer.push({
    event: EVENT_CATEGORIES.customClick,
    click_category: 'cali resident',
    click_type: clickType
  });
};

export const onSearchClick = ({ type, getState }) => {
  if (type !== 'GET_AVAILABILITY_HIGHEST_RATES_SUCCESS') return;

  const { dates, currentLodge, guests, router, offer, account, lodgeLocations } = getState();
  const currentPathLocation = router.location.pathname || '';
  const searchLocation = getCurrentLocation(currentPathLocation);
  const bonusDealValue = () => {
    let valueReturned = 0 + account?.currentLoggedInUser?.pointsBalance;
    account?.currentLoggedInUser?.offerList?.forEach(offer => {
      valueReturned = valueReturned + offer.dollarValue;
    });
    return (!isNaN(valueReturned) ? valueReturned : 0) + ' points';
  };
  const searchType = 'reservation';
  const resortLocation = getResortLocation(currentLodge);
  const lodgeOperaCode = resortLocation ?? '';
  const resortLocationName = lodgeLocations?.locationList[lodgeOperaCode]?.url ?? '';

  window.dataLayer.push(
    buildSearchObject(
      resortLocationName,
      searchType,
      bonusDealValue(),
      searchLocation,
      GwDatesWrapper.format(dates?.checkinDateSelection, DATE_FORMATS.default),
      GwDatesWrapper.format(dates?.checkoutDateSelection, DATE_FORMATS.default),
      guests?.adultsCount,
      guests?.kidsCount,
      guests?.kidsAges,
      offer?.code
    )
  );
};

const EVENT_CATEGORIES = {
  personalizationRules: 'Personalization Rules',
  customClick: 'custom_click'
};
