import get from 'lodash-es/get';
import { GwDatesWrapper } from '../../../components/_internal_date_/gwDatesWrapper';
import { getLocation } from '../../../components/utilities/ResortLocations';
import { store } from '../../../store/configureStore';
import {
    DATE_FORMATS,
    EVENT_CATEGORIES,
    GTM_EVENT,
    LATE_CHECKOUT_PREFIXES,
    PACKAGES_CATEGORY,
    PACKAGE_CHARGE_TYPE,
    PACKAGE_TYPE,
    PRODUCT_SCOPES,
    RECOMMENDED_FOR_YOU
} from '../../../utilities/constants';
import { pushEvent } from '../../../utilities/dataLayerUtils';
import { getNumberOfBillableGuests } from '../../../utilities/guestsUtils';
import { FLEX_TRIP_PACKAGE_CODE } from '../../../utilities/paymentUtils';
import { extraGuestPurchaseObj } from '../analytics/analyticsObjectBuilders';
import {
    buildPackagesPurchaseEnhancedECommerceObject,
    buildPurchaseEnhancedECommerceObject
} from './analyticsObjectBuilders';

/**
 * Get packages grouped for the reservation
 * @param {*} param0
 */
export const getGroupedAddedPackages = ({ addedPackagesCodes, reservation }) => {
  return addedPackagesCodes.reduce((groupedPackages, packageCode) => {
    const foundPackages = reservation.packages.reduce((foundPackages, reservationPackage) => {
      if (reservationPackage.packageGroup === packageCode || reservationPackage.packageCode === packageCode) {
        foundPackages.push(reservationPackage);
      }

      return foundPackages;
    }, []);

    groupedPackages[packageCode] = foundPackages;
    return groupedPackages;
  }, {});
};

/**
 * Calculates total revenue and taxes for the requested packages
 * @param {Array} addedPackagesCodes
 * @param {Array} groupedAddedPackages
 * @returns [int totalRevenue, int totalTaxes]
 */
export const getTotalPackagesRevenueAndTaxes = ({ addedPackagesCodes, groupedAddedPackages }) => {
  const totalRevenue = addedPackagesCodes.reduce((acc, packageCode) => {
    const packageGroupRevenue = groupedAddedPackages[packageCode].reduce((packageGroupRevenue, singlePackage) => {
      return packageGroupRevenue + singlePackage.packageAmount;
    }, 0);

    return acc + packageGroupRevenue;
  }, 0);

  const totalTaxes = addedPackagesCodes.reduce((acc, packageCode) => {
    const packageGroupTaxes = groupedAddedPackages[packageCode].reduce((taxesTotal, singlePackage) => {
      return taxesTotal + singlePackage.tax;
    }, 0);

    return acc + packageGroupTaxes;
  }, 0);

  return [totalRevenue, totalTaxes];
};

export const getAddedPackagesCodes = packages => packages.map(addedPackage => addedPackage.packageCode);

export const getPaymentDescription = reservation => {
  const { paymentType, comments } = reservation;
  if (paymentType === 'DS' && comments.includes('This is a PayPal reservation')) {
    return 'PayPal';
  }

  if (paymentType === 'AF') return 'Affirm';

  if (paymentType === 'GP') return 'Google Pay';

  if (paymentType === 'AP') return 'Apple Pay';

  return 'Credit Card';
};

export const getAnalyticsPayload = reservation => {
  const products = [];
  const packages = reservation.packages;

  products.push({
    productId: reservation.selection.roomCode,
    quantity: reservation.selection.numberOfNights
  });

  if (packages) {
    packages.forEach(pack => {
      products.push({
        productId: pack.packageCode,
        quantity: pack.quantity
      });
    });
  }

  return {
    paymentMethod: getPaymentDescription(reservation),
    orderId: reservation.id,
    products
  };
};

/**
 * Function to get package type or the package tab where it belongs
 * @param {Object} newAddedPackage Package Object recently added
 * @param {Array} packagesTable Array of objects got from availabilty call, represents packageTableJson
 * @returns String Package type one of: Attractions, Birthday, Family, LCO, Flextrip, Dining
 */
export const getPackageCategory = (newAddedPackage, packagesTable) => {
  let packageType = '';

  // Get if is LCO
  if (newAddedPackage.packageCode.includes(LATE_CHECKOUT_PREFIXES.code)) {
    packageType = LATE_CHECKOUT_PREFIXES.itemCode;
  } else if (newAddedPackage.packageCode.trim() === FLEX_TRIP_PACKAGE_CODE) {
    packageType = PACKAGE_TYPE.flexTrip;
  } else {
    // if is not then we look at packages table for package type
    const planPackage = packagesTable.filter(pkgTable =>
      pkgTable.packageCodes?.map(pkgCode => pkgCode.trim()).includes(newAddedPackage.packageCode)
    );
    const isValidPackage = planPackage && planPackage.length > 0;
    const isDining =
      isValidPackage &&
      planPackage[0].packageTableName.trim().toLowerCase() === PACKAGE_TYPE.dining.trim().toLowerCase();
    // Check when this is a dining package, we need to consume this from another property
    if (isDining) {
      packageType = PACKAGE_TYPE.dining;
    } else {
      packageType = isValidPackage ? planPackage[0].packageTabName : '';
    }
  }

  return packageType;
};

/**
 * Get valid quanity for the package. When is dining and per person/day we should perfom some calcs
 * @param {Object} newAddedPackage Package object
 * @param {Array} packageCategory  one of: attractions, family, birthday, dining, lco, flextrip
 * @param {Object} guests Guest Redux state
 * @param {number} numberOfNights Number of nights for the stay
 * @returns Number quantity of  the package
 */
export const getPackageQuantity = (newAddedPackage, packageCategory, guests, numberOfNights) => {
  // if is dining and is by package per day -- number of nights * billable guests
  const isDiningPerDay =
    packageCategory.trim().toLowerCase() === PACKAGES_CATEGORY.dining.toLowerCase() &&
    newAddedPackage.chargeType.trim().toLowerCase() === PACKAGE_CHARGE_TYPE.personPerDay.toLowerCase();

  if (isDiningPerDay) {
    const billableGuests = getNumberOfBillableGuests(guests.adultsCount, guests.kidsAges);
    return billableGuests * numberOfNights;
  }
  //if is dining and is by package -- number of combo
  return newAddedPackage.quantity;
};

/**
 * Check if current package is recommended
 * @param {Object} newAddedPackage Event package object
 * @param {Array} packagesTable Array of objects incoming from detailed availability
 * @returns bool
 */
export const isRecommendedPackage = (newAddedPackage, packagesTable) => {
  const result = packagesTable.some(pkgTable =>
    pkgTable.packageList?.some(
      pkgItem => pkgItem.packageCode === newAddedPackage.packageCode && pkgItem.flags?.includes(RECOMMENDED_FOR_YOU)
    )
  );

  return result;
};

/**
 * Listener triggered on add extra guest
 * @param {Object} eventType The event type
 * @param {number} amount The amount charged on this transaction
 * @param {Object} additionalGuests The object that contains adults and children numbers
 */
export const sendAddExtraGuestInfo = (eventType, amount, additionalGuests, suiteContent) => {
  const { entities, suite } = store.getState();
  const { maxOccupancy, suiteCategory } = suiteContent;

  const reservation = entities?.reservationDetails.data;

  const addExtraGuestObject = {
    event: eventType,
    resortLocation: reservation?.property,
    purchaseLocation: 'modify reservation',
    ecommerce: {
      currencyCode: 'USD',
      purchase: {
        actionField: {
          id: reservation?.id,
          list: 'suites',
          position: 1,
          SIP: suite.dayType
        },
        products: [extraGuestPurchaseObj(reservation, maxOccupancy, suiteCategory, amount, additionalGuests)]
      }
    }
  };

  pushEvent(addExtraGuestObject);
};

/**
 * Send post purchase package to analytics, post purchase happens when user adds or buys something in CMP
 * @param {array} packages Array of objects containing the new packages to be added
 * @param {object} reservation Reservation object with booking information
 */
export const sendPostPurchasePkg = (
  packages,
  reservation,
  packagesContent,
  purchaseLocation,
  dayType,
  maxOccupancy
) => {
  const addedPackagesCodes = getAddedPackagesCodes(packages);
  const groupedAddedPackages = getGroupedAddedPackages({
    addedPackagesCodes,
    reservation
  });

  const [addedPackagesRevenue, addedPackagesTaxes] = getTotalPackagesRevenueAndTaxes({
    addedPackagesCodes,
    groupedAddedPackages
  });

  // TODO - Eventually we are going to remove this in favor of the enhanced E-Commerce experience.
  const products = addedPackagesCodes.reduce((acc, packageCode) => {
    const packagesByCode = packages.reduce((keysAcc, currentPackage) => {
      keysAcc[currentPackage.packageCode] = currentPackage;
      return keysAcc;
    }, {});
    const pkgType = getPkgType(packagesContent, packageCode);
    acc.push({
      name: packagesByCode[packageCode].title,
      id: packageCode,
      variant: pkgType,
      brand: 'great wolf',
      price: groupedAddedPackages[packageCode].reduce((revenueAcc, packageItem) => {
        return revenueAcc + packageItem.packageAmount;
      }, 0),
      quantity: packagesByCode[packageCode].quantity,
      category: packagesByCode[packageCode].packageType,
      coupon: reservation.offerCode
    });
    const uniquePackages = [...new Map(acc.map(m => [m.id, m])).values()];
    return uniquePackages;
  }, []);

  const postPurchasePackageObj = {
    event: EVENT_CATEGORIES.GTM.postPurchasePackage,
    ecommerce: {
      purchase: {
        actionField: {
          id: reservation.id,
          coupon: reservation.rateCode,
          revenue: addedPackagesRevenue.toFixed(2),
          SIP: dayType
        },
        products: products
      }
    }
  };

  pushEvent(postPurchasePackageObj);

  const transactionPackages = packages.map(pkg => {
    return {
      ...pkg,
      quantity: pkg.quantityPerson || pkg.quantity
    };
  });

  const uniquePackages = [...new Map(transactionPackages.map(m => [m.id, m])).values()];

  const packageProducts = buildPackagesPurchaseEnhancedECommerceObject(
    {
      addedPackagesCodes,
      packages: uniquePackages,
      groupedAddedPackages,
      reservation,
      packagesContent,
      maxOccupancy
    },
    purchaseLocation || PRODUCT_SCOPES.postPurchase
  );

  const postPurchaseEnhancedECommerce = buildPurchaseEnhancedECommerceObject(
    {
      reservation,
      products: packageProducts,
      revenue: (addedPackagesRevenue + addedPackagesTaxes).toFixed(2),
      tax: addedPackagesTaxes.toFixed(2),
      shipping: 0
    },
    EVENT_CATEGORIES.GTM.transaction,
    purchaseLocation || PRODUCT_SCOPES.postPurchase
  );

  pushEvent(postPurchaseEnhancedECommerce);
};

/**
 * Send cabanas post purchase to analytics, post purchase happens when user adds or buys something in CMP
 * @param {object} cabanas Object containing cabanas information to be added from the user
 * @param {object} reservationData Object containing reservation information to be added from the user
 */
export const sendCabanasAnalytics = (cabanas, reservationData, dayType) => {
  const { numAdults, numberOfNights, numChildren, site: propertyCode, offerCode } = reservationData.selection;

  cabanas.reservations.forEach(cabana => {
    const { id, total, roomDescription, arrival, rateDescription, roomType, rateInfo } = cabana;

    // Now send enhanced e-commers for transaction
    const cabanaDate = GwDatesWrapper.format(arrival, DATE_FORMATS.default);
    const locationName = getLocation(propertyCode).url; //product scope dimension for resort;
    const taxes = get(rateInfo, 'taxes', 0);
    const totalRevenue = parseFloat(total) + parseFloat(taxes);
    const { suite, guests } = store.getState();

    const cabanaPurchaseObj = {
      event: EVENT_CATEGORIES.GTM.transaction,
      resortLocation: locationName,
      purchaseLocation: EVENT_CATEGORIES.GTM.postPurchase,
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          actionField: {
            id: reservationData.id,
            affiliation: GTM_EVENT.LABEL.greatWolfLowerCase,
            revenue: totalRevenue.toFixed(2),
            shipping: 0,
            tax: taxes.toFixed(2),
            coupon: offerCode,
            SIP: dayType
          },
          products: [
            {
              name: roomDescription,
              id: `${roomType} - ${id}`,
              price: total,
              brand: GTM_EVENT.LABEL.greatWolfLowerCase,
              category: EVENT_CATEGORIES.GTM.packages,
              variant: rateDescription,
              quantity: 1,
              coupon: offerCode,
              dimension29: locationName,
              dimension30: EVENT_CATEGORIES.GTM.packages,
              dimension31: numberOfNights,
              dimension32: numAdults,
              dimension33: numChildren,
              dimension34: EVENT_CATEGORIES.GTM.postPurchase,
              dimension35: cabanaDate,
              dimension36: cabanaDate,
              dimension61: Number(suite.selectedSuite.maxOccupancy), // product scope dimension for Maximum Suite Occupancy
              dimension62: guests.kidsAges || [] // product scope dimension for Kids Ages
            }
          ]
        }
      }
    };
    pushEvent(cabanaPurchaseObj);
  });
};

/**
 *
 * @param {Array} pkgs app pkgs
 * @param {String} packageCode pkg code to filter
 * @returns pkgType based on pkgCode
 */
export const getPkgType = (pkgs, packageCode) => {
  let pkgType = null;
  const trimPkgCode = packageCode.trim();
  // Look for LCO and Flextrip packages
  if (trimPkgCode.includes(LATE_CHECKOUT_PREFIXES.code)) {
    return LATE_CHECKOUT_PREFIXES.itemCode;
  } else if (trimPkgCode === FLEX_TRIP_PACKAGE_CODE) {
    return PACKAGE_TYPE.flexTrip;
  }

  const filteredPackage = pkgs.find(pkg => pkg.packageCode === trimPkgCode);

  if (!filteredPackage) return pkgType;

  pkgType = filteredPackage.packageType;

  // The PkgType used on the CMP is an array
  const isPkgTypeAnArray = Array.isArray(pkgType);
  const packageType = isPkgTypeAnArray ? pkgType[0] : pkgType;

  return packageType;
};
