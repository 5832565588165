import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '../../../../../ui-kit';
import * as COPY from '../utilities/copy';

import { isValidPrice } from '../../../../../../utilities/cmpPackagesUtils';
import { SummaryContent } from '../../../Content';

const propTypes = {
  /** Summary total for the reservation */
  total: PropTypes.number.isRequired
};

export const Total = ({ total, addGuestReservation }) => {
  if (!isValidPrice(total)) return null;
  return (
    <>
      <SummaryContent.Divider borderThickness={2} />
      <SummaryContent.Item>
        <SummaryContent.Description>
          <Text fontSize={7} fontWeight={700} lineHeight={22}>
            {COPY.SUMMARY_TOTAL}
          </Text>
        </SummaryContent.Description>
        <SummaryContent.SubtotalAmount>
          <Text fontSize={7} fontWeight={700} lineHeight={22}>{`$${
            addGuestReservation ? addGuestReservation.total.toFixed(2) : total.toFixed(2)
          }`}</Text>
        </SummaryContent.SubtotalAmount>
      </SummaryContent.Item>
    </>
  );
};

Total.propTypes = propTypes;
