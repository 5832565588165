import { SUMMARY_CART_VARIATIONS } from '../components/Summary/components/Suite/utilities/constants';
import { pluralize } from '../components/utilities/copyFunctions';

// Regular expressions to validate canadian postal code and email
export const canadianPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
export const canadianEmailAddressRegex = /^(.*)(.ca|.CA)(.*)$/;

/**
 * Type of guest.
 * @enum {string} - Guest Type
 */
export const GUEST_TYPE = {
  KID: 'Kid',
  KIDS: 'Kids',
  ADULT: 'Adult',
  ADULTS: 'Adults',
  INFANT: 'Infant',
  INFANTS: 'Infants'
};

/**
 * Gives message for adults count.
 * @param {*} adultsCount - Adults count.
 */
export const getAdultsCountMessage = adultsCount => {
  return adultsCount
    ? adultsCount > 1
      ? `${adultsCount} ${GUEST_TYPE.ADULTS}`
      : `${adultsCount} ${GUEST_TYPE.ADULT}`
    : `0 ${GUEST_TYPE.ADULTS}`;
};

/**
 * Gives message for kids count.
 * @param {*} kidsCount - Kids count.
 */
export const getKidsCountMessage = kidsCount => {
  return kidsCount > 1 ? `${kidsCount} ${GUEST_TYPE.KIDS}` : `${kidsCount} ${GUEST_TYPE.KID}`;
};

/**
 * Gives message for infants count.
 * @param {*} infantsCount - Infants count
 */
export const getInfantsCountMessage = infantsCount => {
  return infantsCount > 1 ? `${infantsCount} ${GUEST_TYPE.INFANTS}` : `${infantsCount} ${GUEST_TYPE.INFANT}`;
};

/**
 * Based on an array of numbers get the ones below the limit.
 * @param {string[]} kids List of kids ages.
 * @param {number} limit Age limit, kid will be added if is below this age.
 * @return {number} Numbers of kids below the limit age.
 */
export const getKidsUnderAge = (kids, limit) => {
  return kids.reduce((acc, currentValue) => {
    if (parseInt(currentValue) < limit) {
      return (acc += 1);
    }
    return acc;
  }, 0);
};

/**
 * Based on an array of numbers get the ones below the limit.
 * @param {string[]} kids List of kids ages.
 * @param {number} limit Age limit, kid will be added if is above this age.
 * @return {number} Numbers of kids above the limit age.
 */
export const getKidsAboveAge = (kids, lowerLimit) => {
  if (!kids) return 0;
  return kids.reduce((acc, currentValue) => {
    if (parseInt(currentValue) > lowerLimit) {
      return (acc += 1);
    }
    return acc;
  }, 0);
};

/**
 * Get the number of guest that will be charged (including 4+ years old kids)
 * @param {number} adultsCount
 * @param {string} kidsAges
 */
export const getNumberOfBillableGuests = (adultsCount = 0, kidsAges = [], isDiningPackage = false) => {
  const normalizedKidsAges = typeof kidsAges === 'string' ? kidsAges.split(',') : kidsAges;

  const age = isDiningPackage ? 3 : 2;
  const numOfBillableChildren = getKidsAboveAge(normalizedKidsAges, age);

  return adultsCount + numOfBillableChildren;
};

/**
 * Get the number of unbillable guests.
 * @param {string} kidsAges The ages of the kids.
 */
export const getNumberOfUnbillableGuests = kidsAges => {
  const kidsCount = kidsAges.length;
  return kidsCount > 0 ? kidsAges.filter(age => age <= 3).length : 0;
};

/**
 *
 * @param {number} adultsCount number of adults
 * @param {number} kidsCount  number of kids
 * @returns Label describing the amount of adults and kids as (adultsCount) Adults, (Kids) Kids
 */
export const getGuestCountLabel = (adultsCount, kidsCount, variation = null, capitalize = false) => {
  const adultText = pluralize(capitalize ? 'Adult' : 'adult', adultsCount);
  const kidText = pluralize(capitalize ? 'Kid' : 'kid', kidsCount);
  let guestCountText = `${adultsCount} ${adultText}, ${kidsCount} ${kidText}`;
  if (variation === SUMMARY_CART_VARIATIONS.CART) {
    guestCountText = `${adultsCount} ${adultText} - ${kidsCount} ${kidText}`;
  }
  return guestCountText;
};

/**
 * Returns the Children ages as array
 * @param {Array} kidsAges Array of kids/children ages
 * @returns Array
 */
export const getKidsCount = kidsAges => {
  let kidsCountArray = [];

  if (kidsAges) {
    if (typeof kidsAges === 'string') {
      kidsCountArray = kidsAges.split(',');
    } else if (Array.isArray(kidsAges)) {
      kidsCountArray = kidsAges;
    }
  }

  return kidsCountArray;
};

/**
 * Gives message for guests information.
 * @param {Array} kidsAges List/Array of children ages
 * @param {number} numberOfAdults Count of adults in the stay
 * @param {string} offerCode Offer code used for reservation
 * @returns String containing information regarding guests
 */
export const recentSearchesMessage = (kidsAges, numberOfAdults, offerCode) => {
  const kidsAgesList = getKidsCount(kidsAges);
  const adultMessage = getAdultsCountMessage(numberOfAdults);
  const childrenCount = getKidsAboveAge(kidsAgesList, 2); // Children above 2
  const infantCount = getKidsUnderAge(kidsAgesList, 3); // Children age 2 and under
  const kidsMessage = childrenCount > 0 ? getKidsCountMessage(childrenCount) : '';
  const infantsMessage = infantCount > 0 ? getInfantsCountMessage(infantCount) : '';
  const offerMessage = offerCode ? offerCode : '';

  return [adultMessage, kidsMessage, infantsMessage, offerMessage].filter(Boolean).join(', ');
};
