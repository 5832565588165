import SipRatesApiClient from '../../api/clients/SipRatesApiClient';
import { GwDatesWrapper } from '../../components/_internal_date_/gwDatesWrapper';
import { initializeState } from '../../utilities/storageUtils';
import { getResortLocation } from './CurrentLodge';

const updateDatesType = 'UPDATE_DATES';
const clearDatesType = 'CLEAR_DATES';
const updateSipDates = 'UPDATE_SIP_DATES';
const fetchSipDates = 'FETCHING_SIP_DATES';
const updateSipError = 'UPDATE_SIP_ERROR';

const initialState = {
  checkinDateSelection: undefined,
  checkoutDateSelection: undefined,
  isFetchingSipDates: false,
  sipDates: {},
  sipRatesError: null
};

export const actionCreators = {
  updateDates: value => {
    return {
      type: updateDatesType,
      checkinDateSelection: value.startDate,
      checkoutDateSelection: value.endDate
    };
  },
  clearDates: () => ({ type: clearDatesType }),
  getMonthRate: values => async (dispatch, getState) => {
    const { month, year, isMobile = false } = values;
    const resortLocation = getResortLocation(getState().currentLodge);

    // 1. set fetch to true
    dispatch({ type: fetchSipDates, fetchSipDates: true });
    // 2. get data
    const payload = {
      month,
      year,
      property: resortLocation
    };

    const apiClient = new SipRatesApiClient();
    let sipData;
    let result = { data: {} };

    try {
      result = await apiClient.getSipRatesByMonthAndYear(payload);
      if (isMobile) {
        const payloadPrev = {
          month: parseInt(month) === 1 ? 12 : parseInt(month) - 1,
          year: parseInt(month) === 1 ? year - 1 : year,
          property: resortLocation
        };

        const payloadNext = {
          month: parseInt(month) === 12 ? 1 : parseInt(month) + 1,
          year: parseInt(month) === 12 ? year + 1 : year,
          property: resortLocation
        };

        let resultPrev = { data: {} };
        let resultNext = { data: {} };
        resultPrev = await apiClient.getSipRatesByMonthAndYear(payloadPrev);
        resultNext = await apiClient.getSipRatesByMonthAndYear(payloadNext);
        sipData = { ...result.data.sipCalendar, ...resultPrev.data.sipCalendar, ...resultNext.data.sipCalendar };
      } else {
        sipData = { ...result.data.sipCalendar };
      }

      dispatch({
        type: updateSipError,
        sipStatus: null
      });
    } catch (error) {
      dispatch({
        type: updateSipError,
        sipStatus: 'API error'
      });
    }

    dispatch({ type: updateSipDates, sipDates: sipData });
    // 3. set fetch to false
    dispatch({ type: fetchSipDates, fetchSipDates: false });
  }
};

const reducer = (state, action) => {
  state = initializeState(state, initialState);
  switch (action.type) {
    case updateDatesType:
      return {
        ...state,
        checkinDateSelection: action.checkinDateSelection,
        checkoutDateSelection: action.checkoutDateSelection
      };
    case clearDatesType:
      return initialState;
    case fetchSipDates:
      return {
        ...state,
        isFetchingSipDates: action.fetchSipDates
      };
    case updateSipDates:
      return {
        ...state,
        sipDates: action.sipDates
      };
    case updateSipError:
      return {
        ...state,
        sipRatesError: action.sipStatus
      };
    default:
      return state;
  }
};

export default reducer;

/**
 * Get number of nights based on check in and check out date selected.
 * @param {Object} reservation dates
 * @return {number} total number of nights
 */
export const getTotalNumberOfNights = state => {
  const { checkinDateSelection, checkoutDateSelection } = state;
  if (!checkinDateSelection || !checkoutDateSelection) return 0;
  return GwDatesWrapper.diff(checkoutDateSelection, checkinDateSelection);
};

/**
 * Get selected check in date.
 * @param {Object} state
 * @returns {string} String date.
 */
export const getCheckIn = state => state.checkinDateSelection;

/**
 * Get selected check out date.
 * @param {Object} state
 * @returns {string} String date.
 */
export const getCheckOut = state => state.checkoutDateSelection;
