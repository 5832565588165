import React from 'react';
import { Flex, Text } from '../../../../../ui-kit';
import theme from '../../../../../ui-kit/themes/theme';
import * as COPY from '../../utilities/copy';
import { StyledWave } from '../styles';

const CartHeader = ({ subTotal = 0 }) => {
  return (
    <>
      {/* Content Title and Subtotal */}
      <Flex justifyContent="space-between" background={theme.colors.kiddiePoolBlue[20]} pl={7} pt={7} pr={7} pb={3}>
        <Text color="deepLakeBlue.100" fontSize={[1, 1, 1, 1, 7]} fontWeight="bold" lineHeight={[18, 18, 18, 18, 24]}>
          {COPY.BOOKING_CART_TITLE}
        </Text>
        <Flex alignItems="baseline">
          <Text
            color="deepLakeBlue.100"
            fontSize={[1, 1, 1, 1, 7]}
            fontWeight="bold"
            lineHeight={[18, 18, 18, 18, 24]}
            mr={1}>
            {subtotalCopy(subTotal)}
          </Text>
          <Text color="deepLakeBlue.100" fontSize={[0]} fontWeight="medium" lineHeight={[18]}>
            {COPY.BOOKING_CART_SUBTOTAL}
          </Text>
        </Flex>
      </Flex>
      {/* Wave */}
      <StyledWave />
    </>
  );
};

export default CartHeader;

function subtotalCopy(subTotal) {
  // Check if subtotal has decimals and decimals are not 0
  const subTotalHasDecimals = subTotal % 1 !== 0 && subTotal % 1 !== 0.0;

  // if decimals is true return the subtotal with 2 decimal places
  if (subTotalHasDecimals) {
    return `$${subTotal}`;
  }
  // if it has no decimals or decimals are 0 return the subtotal with no decimal places
  return `$${Math.floor(subTotal)}`;
}
