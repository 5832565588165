import ManageBookingApiClient from '../../../api/clients/ManageBookingApiClient';
import { getErrorMessage } from '../../../utilities/messageUtils';
import manageReservationTypes from './managereservation.types';

export const getManageReservationDetails = reservationId => async dispatch => {
  dispatch({ type: manageReservationTypes.GET_MANAGERESERVATIONDETAILS_REQUEST });

  try {
    const manageBookingApiClient = new ManageBookingApiClient();
    const manageBookingResponse = await manageBookingApiClient.manageBooking(reservationId);

    dispatch({
      type: manageReservationTypes.GET_MANAGERESERVATIONDETAILS_SUCCESS,
      response: manageBookingResponse?.data
    });
  } catch (err) {
    dispatch({
      type: manageReservationTypes.GET_MANAGERESERVATIONDETAILS_FAILURE,
      message: getErrorMessage(err.message)
    });
  }
};

export const clearManageReservationDetails = () => ({
  type: manageReservationTypes.CLEAR_MANAGERESERVATIONDETAILS
});
