import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '../../../../../ui-kit';
import { SUMMARY_IMAGES } from '../assets/images';
import { CartLogoContainer, CartLogoCounter, CartLogoImg } from '../styles';

const propTypes = {
  packagesCount: PropTypes.number,
  toggleOpen: PropTypes.func
};

const defaultProps = {
  packagesCount: 0,
  toggleOpen: () => {}
};

const CartLogo = ({ packagesCount = 0, toggleOpen, overlayVisible }) => {
  return (
    <CartLogoContainer textAlign="center" onClick={toggleOpen} overlayVisible={overlayVisible}>
      <CartLogoImg src={SUMMARY_IMAGES.CART_LOGO} alt="Cart" />
      {packagesCount > 0 ? (
        <CartLogoCounter>
          <Text fontSize={9} fontWeight="bold" lineHeight={14} color="pureWhite.0">
            {packagesCount}
          </Text>
        </CartLogoCounter>
      ) : null}
    </CartLogoContainer>
  );
};

CartLogo.propTypes = propTypes;
CartLogo.defaultProps = defaultProps;

export default CartLogo;
