import { store } from '../../../store/configureStore';
import { getResortLocationUrl } from '../../../utilities/resortLocation';
import { LocalStorageKeys, SessionStorageKeys } from '../../../utilities/storageUtils';

export const LODGE_LOCATION_DATA_TTL = 15; // minutes
export const LODGE_CONFIG_DATA_TTL = 30; // minutes
export const GEO_DATA_TTL = 60; // minutes

// get data from sessionStorage
let endpointTTLData = JSON.parse(sessionStorage.getItem(SessionStorageKeys.ENDPOINT_TTL_KEY)) || {};

const urlList = {
  planConfigByResort: ['plan', 'daypass', 'booking'],
  creditPackageConfigByResort: ['check-in', 'packages-dining', /my-reservations\/[\w\d]+/],
  cmpConfigByResort: ['confirmation', /my-reservations\/[\w\d]+/],
  paymentConfigByResort: ['payment', 'packages-']
};

// function to validate if the endpoint can be called
export const canCallEndpoint = (endpoint, minTTL = 3, validateUrl = false) => {
  const now = Date.now();
  const msTTL = minTTL * 60 * 1000;

  const { lodgeLocations } = store?.getState() || '';

  if (
    endpointTTLData[endpoint] &&
    now - endpointTTLData[endpoint] < msTTL &&
    Object.keys(lodgeLocations.locationList).length > 0
  ) {
    return false; // the endpoint has been called during TTL value in minutes
  }
  return validateUrl ? checkURLAgainstList(endpoint) : true; // the endpoint can be called
};

// function to mark the endpoint as called
export const markEndpointAsCalled = endpoint => {
  endpointTTLData[endpoint] = Date.now();
  sessionStorage.setItem(SessionStorageKeys.ENDPOINT_TTL_KEY, JSON.stringify(endpointTTLData));
};

// check if the current url contains any of the keywords for the endpoint
export const checkURLAgainstList = endpoint => {
  const currentURL = window.location.href;

  const keywordCheck =
    urlList[endpoint] &&
    urlList[endpoint].some(keyword => {
      if (keyword instanceof RegExp) {
        return keyword.test(currentURL);
      }
      return currentURL.includes(keyword);
    });

  if (keywordCheck) {
    return true;
  }

  return false;
};

export const clearEndpointTTLData = endpointList => {
  // remove TTL for endpoints - keep the ones where 'keepTTL' flag is set
  endpointList.forEach(enddpoint => {
    delete endpointTTLData[enddpoint.name];
  });

  sessionStorage.setItem(SessionStorageKeys.ENDPOINT_TTL_KEY, JSON.stringify(endpointTTLData));
};

export const getLocationFromUrl = () => {
  const locationData = localStorage.getItem(LocalStorageKeys.URL_LOCATION);
  return locationData ? JSON.parse(locationData) : null;
};

export const setLocationFromUrl = () => {
  const locationData = getResortLocationUrl();
  if (locationData) {
    localStorage.setItem(LocalStorageKeys.URL_LOCATION, JSON.stringify(locationData.replace(/\//g, '')));
  }
};
