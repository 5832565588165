import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { getExpirationPoints } from '../../../store/componentStores/Account';
import { getCurrentResortPhone, getResortLocationUrlParam } from '../../../store/componentStores/CurrentLodge';
import { getIsLoyaltyPointsExpirationOnProfileEnabled } from '../../../store/componentStores/LodgeConfig';
import { showLoyalty } from '../../../store/reducers';
import { getTermsAndConditionUrl } from '../../../utilities/navigationUtils';
import { GwDatesWrapper } from '../../_internal_date_/gwDatesWrapper';
import { Box, Button, Flex, Text, TextLink } from '../../ui-kit';
import theme from '../../ui-kit/themes/theme';
import { editCopyByReplaceMultipleStrings, editCopyByReplaceString } from '../../utilities/copyFunctions';
import {
    BookNowWhite,
    Icon,
    LoyaltyProfileContainer,
    PointConditionsAndTerms,
    PointsContainer,
    PointsExpirationContainer,
    SpanDot
} from '../assets/styles';
import { LOYALTY_ICON_LINK_WHITE } from '../utilities/constants';
import * as COPY from '../utilities/copy';

const LoyaltyProfilePoints = ({
  amount,
  iconLink,
  showLoyalty,
  currentResortPhone,
  resortLocationUrlParam,
  expirationPointsObject,
  isLoyaltyPointsExpirationOnProfileEnabled
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.xl})`
  });

  const { nextExpirationDate, expiringMetricValue } = expirationPointsObject;

  const expirationObject = {
    '<POINTS>': expiringMetricValue ?? 0,
    '<DATE>': GwDatesWrapper.format(nextExpirationDate, 'MMM DD, YYYY')
  };

  return (
    <>
      {showLoyalty ? (
        <LoyaltyProfileContainer>
          {expirationPointsObject && expiringMetricValue !== 0 && isLoyaltyPointsExpirationOnProfileEnabled ? (
            <PointsExpirationContainer>
              <SpanDot />
              <Text
                fontSize={[0, 0, 0, 0, 1]}
                fontWeight="bold"
                color={'voyagersColors.100'}
                lineHeight={[16, 16, 16, 16, 20]}>
                {editCopyByReplaceMultipleStrings(COPY.EXPIRATION_POINTS, expirationObject)}
              </Text>
            </PointsExpirationContainer>
          ) : null}

          <PointsContainer>
            <Box mb={[4, 4, 4, 4, 0]}>
              <Flex flexDirection="column" width="100%">
                <Box textAlign="center" mb={[3]}>
                  <Text
                    color={theme.colors.pureWhite[0]}
                    fontSize={[7]}
                    fontWeight="bold"
                    lineHeight={[22, 22, 22, 22, 24]}>
                    {COPY.AVAILABLE_VOYAGERS_POINTS}
                  </Text>
                </Box>

                <Flex justifyContent={isMobile ? 'center' : 'start'}>
                  <Icon size="32px" src={iconLink} alt="Loyalty" />
                  <Text
                    ml="12px"
                    color={theme.colors.pureWhite[0]}
                    fontSize={[6, 6, 6, 6, 13]}
                    fontWeight="bold"
                    lineHeight={[48]}>
                    {amount}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box>
              <BookNowWhite>
                <Button as="a" href={`${resortLocationUrlParam}plan`}>
                  {COPY.BOOK_NOW}
                </Button>
              </BookNowWhite>
            </Box>
          </PointsContainer>
          <PointConditionsAndTerms>
            <Box>
              <Flex alignItems="center" flexDirection="row" mb={['12px', '12px', '12px', '12px', '7px']}>
                <SpanDot width={5} height={5} mr={8} />
                <Text fontSize={[0]} fontWeight="medium" lineHeight={[18]} color={'voyagersColors.100'}>
                  {COPY.USE_POINTS_NEXT_BOOKING}
                </Text>
              </Flex>
              <Flex alignItems="center" flexDirection="row" mb={['12px', '12px', '12px', '12px', '7px']}>
                <SpanDot width={5} height={5} mr={8} />
                <Text fontSize={[0]} fontWeight="medium" lineHeight={[18]} color={'voyagersColors.100'}>
                  {COPY.POINTS_ADDED_UPON_COMPLETION}
                </Text>
              </Flex>
              <Flex alignItems="center" flexDirection="row" mb={['24px', '24px', '24px', '24px', '12px']}>
                <SpanDot width={5} height={5} mr={8} />
                <Text fontSize={[0]} fontWeight="medium" lineHeight={[18]} color={'voyagersColors.100'}>
                  {COPY.TEMP_EXPIRATION_NOTICE}
                </Text>
              </Flex>
              <Box textAlign={isMobile ? 'center' : 'left'} mb={['24px', '24px', '24px', '24px', '0px']}>
                <TextLink
                  href={getTermsAndConditionUrl(resortLocationUrlParam)}
                  target="_blank"
                  underline
                  color={'voyagersColors.100'}
                  fontSize={[1]}
                  fontWeight="bold"
                  lineHeight={[18]}>
                  {COPY.SEE_TERMS_AND_CONDITIONS}
                </TextLink>
              </Box>
            </Box>
            <Box textAlign={isMobile ? 'left' : 'right'}>
              <Text fontSize={[9]} fontWeight="medium" lineHeight={[16]} color={'voyagersColors.100'}>
                {editCopyByReplaceString(COPY.CLOSE_ACCOUNT, currentResortPhone, '<<phone>>')}
              </Text>
            </Box>
          </PointConditionsAndTerms>
        </LoyaltyProfileContainer>
      ) : null}
    </>
  );
};

LoyaltyProfilePoints.propTypes = {
  amount: PropTypes.number,
  iconLink: PropTypes.string,
  showLoyalty: PropTypes.bool,
  locationPhone: PropTypes.string,
  expirationPointsObject: PropTypes.object
};
LoyaltyProfilePoints.defaultProps = {
  amount: '0',
  iconLink: LOYALTY_ICON_LINK_WHITE,
  showLoyalty: false,
  locationPhone: '',
  expirationPointsObject: {
    nextExpirationDate: '',
    expiringMetricValue: 0
  }
};

const mapStateToProps = state => ({
  showLoyalty: showLoyalty(state),
  currentResortPhone: getCurrentResortPhone(state.currentLodge),
  resortLocationUrlParam: getResortLocationUrlParam(state.currentLodge),
  expirationPointsObject: getExpirationPoints(state.account),
  isLoyaltyPointsExpirationOnProfileEnabled: getIsLoyaltyPointsExpirationOnProfileEnabled(state.lodgeConfig)
});

export default connect(mapStateToProps, null)(LoyaltyProfilePoints);
