export const EVENTS_DICTIONARY = {
  AEM_LOGIN: 'aem.emitLogin',
  AEM_LOGOUT: 'aem.emitLogOut',
  AEM_DEALS_T2_CARDS_UPDATE: 'aem.emitDealsT2CardsUpdate',
  FLOW_NEXT_STEP: 'flow.NextStep',
  FLOW_LAST_STEP: 'flow.LastStep',
  FLOW_NEXT_STEP_TEXT: 'flow.NextStepText'
};

/**
 * This functions emits an event on the window that will be listened
 * @param {String} eventName name that will be expected on the other side
 *
 * More custom options can be added later
 */
export const emitEventsByName = eventName => {
  const event = new Event(eventName);
  window.dispatchEvent(event);
};

/**
 * This functions emits an event on the window that will be listened
 * @param {String} eventName name that will be expected on the other side
 * @param {Object} detail object with the data that will be sent to the other side
 *
 * More custom options can be added later
 */
export const emitCustomEvent = (eventName, detail) => {
  const event = new CustomEvent(eventName, {
    detail: { ...detail }
  });
  window.dispatchEvent(event);
};
