import { Fragment } from 'react';
import { OFFERS_IMAGES } from '../../../../../../components/LoyaltySummary/assets/images';
import { GwDatesWrapper } from '../../../../../_internal_date_/gwDatesWrapper';
import { Box, Flex, Text } from '../../../../../ui-kit';
import theme from '../../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../../Content';
import * as COPY from '../../utilities/copy';
import { CustomSummaryItemOffer, CustomSummaryItemTitle, OfferImageContainer, OffersContainer } from '../styles';

const Offers = ({ userOffers, activeOffers, removeOffer, excedent, isCartVariation }) => {
  if (!activeOffers || activeOffers?.length === 0) {
    return null;
  }

  const offerSkeleton = (offerCode, index) => {
    const offerInfo = userOffers.find(offer => offer.id === offerCode);
    if (!offerInfo) return;

    return (
      <Fragment>
        <SummaryContent.Item>
          <Box width="100%">
            <Flex justifyContent="space-between" width="100%">
              <Box>
                <Text
                  mb="1"
                  mt="0"
                  fontSize={0}
                  fontWeight={theme.fontWeights.medium}
                  color={theme.colors.deepLakeBlue[100]}>
                  {offerInfo.title}
                </Text>
              </Box>
              <Box>
                <Flex flexDirection="row" alignItems="center">
                  <SummaryContent.Action
                    mr={['4px', '4px', '4px', '0px']}
                    height={'max-content'}
                    fontSize={isCartVariation ? theme.fontSizes[9] : theme.fontSizes[0]}
                    lineHeight="18px"
                    onClick={() => removeOffer(offerInfo)}>
                    {COPY.REMOVE}
                  </SummaryContent.Action>
                  <Text fontWeight="500" fontSize={0} color="deepLakeBlue.100" width="63px" textAlign="right">
                    {`-$${offerInfo.dollarValue.toFixed(2)}`}
                  </Text>
                </Flex>
              </Box>
            </Flex>
            <Text lineHeight="16px" my="0" fontSize={'10px'} fontWeight="medium" color="deepLakeBlue.100">
              {COPY.EXPIRATION_DATE} {GwDatesWrapper.format(offerInfo.bookByDate, 'MMM DD, YYYY')}
            </Text>
          </Box>
        </SummaryContent.Item>
      </Fragment>
    );
  };

  return (
    <OffersContainer isCartVariation={isCartVariation}>
      {!isCartVariation ? <SummaryContent.Divider /> : null}
      <CustomSummaryItemTitle fontWeight="bold">
        <SummaryContent.Description>
          <Flex flexDirection="column">
            <Flex alignItems="center">
              <OfferImageContainer mr="8px">
                <img src={OFFERS_IMAGES.OFFERS_V_BLUE_ICON} alt="" />
              </OfferImageContainer>
              <Text color={theme.colors.deepLakeBlue[100]} my="0" fontSize={0} fontWeight="bold" lineHeight="16px">
                {COPY.BONUS_DEALS_APPLIED}
              </Text>
            </Flex>

            {excedent && (
              <Text my="2" fontSize={['10px', null, null, '10px']} color="red">
                {COPY.EXCEED_AMOUNT}
              </Text>
            )}
          </Flex>
        </SummaryContent.Description>
      </CustomSummaryItemTitle>
      <CustomSummaryItemOffer>
        {activeOffers.map((activeOffer, index) => (
          <Box key={index}>{offerSkeleton(activeOffer, index)}</Box>
        ))}
      </CustomSummaryItemOffer>
    </OffersContainer>
  );
};

export default Offers;
