import { push } from 'connected-react-router';
import get from 'lodash-es/get';
import ReactGA from 'react-ga';
import AuthClient from '../../api/auth/AuthClient';
import ResetPasswordApiClient from '../../api/clients/ResetPasswordApiClient';
import UnlockApiClient from '../../api/clients/UnlockApiClient';
import UserAccountApiClientTioga from '../../api/clients/UserAccountApiClient';
import { getPasswordChangeEventObject } from '../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import { GTM_EVENT } from '../../utilities/constants';
import { emitEventsByName, EVENTS_DICTIONARY } from '../../utilities/customEventsHelpers';
import { pushEvent } from '../../utilities/dataLayerUtils';
import { getErrorMessage } from '../../utilities/messageUtils';
import { routesRedirectOnSignOut } from '../../utilities/navigationUtils';
import { initializeState } from '../../utilities/storageUtils';
import { getCurrentResortDataUrl } from './CurrentLodge';

const resetNewAccountInitialStateType = 'RESET_NEW_ACCOUNT_TO_INITIAL_STATE';

const postForgotPwdInfo = 'POST_FORGOT_PWD_INFO';
const postForgotPwdInfoSuccess = 'POST_FORGOT_PWD_INFO_SUCCESS';
const postForgotPwdInfoFailure = 'POST_FORGOT_PWD_INFO_FAILURE';

const postChangePwdInfoSuccessType = 'POST_CHANGE_PWD_INFO_SUCCESS';
const postChangePwdInfoLoadingType = 'POST_CHANGE_PWD_INFO_LOADING';
const postChangePwdInfoErrorType = 'POST_CHANGE_PWD_INFO_ERROR';

const postUnlockAccountSuccessType = 'POST_UNLOCK_ACCOUNT_SUCCESS';
const postUnlockAccountLoadingType = 'POST_UNLOCK_ACCOUNT_LOADING';
const postUnlockAccountErrorType = 'POST_UNLOCK_ACCOUNT_ERROR';

const currentUserIsLoggedInType = 'CURRENT_USER_IS_LOGGED_IN';
const currentLoggedInUserType = 'CURRENT_LOGGED_IN_USER';

const postNewAccountSuccessType = 'POST_NEW_ACCOUNT_SUCCESS';
const postNewAccountLoadingType = 'POST_NEW_ACCOUNT_LOADING';
const postNewAccountErrorType = 'POST_NEW_ACCOUNT_ERROR';

const loginErrorType = 'LOGIN_ERROR_TYPE';
const resetAccountMethodsType = 'RESET_ACCOUNT_METHODS';
const resetPwFeedback = 'RESET_PW_FEEDBACK';

const loyaltyOptIn = 'LOYALTY_OPT_IN';
const updateMemberIdAndReferralCode = 'UPDATE_MEMBER_ID_AND_REFERRAL_CODE';

const removeAllActiveOffersType = 'REMOVE_ALL_ACTIVE_OFFERS_TYPE';
const updateUserPointsAndOffers = 'UPDATE_USER_POINTS_AND_OFFERS';
const getUserPointsHistory = 'GET_USER_POINTS_HISTORY';

const USER_NOT_FOUND = 'USER_NOT_FOUND';
const USER_NOT_FOUND_ERROR = 'User not found';

const authClient = new AuthClient();

// SET AN INITIAL STATE FOR REDUX
const initialState = {
  forgotPwdReqIsLoading: false,
  forgotPwdReqHasFinished: false,
  changePwdReqIsLoading: false,
  changePwdReqHasFailed: false,
  changePwdReqHasSucceded: false,
  changePwdReqErrorMessage: '',
  userIsLoggedIn: false,
  creatingNewAccount: false,
  newAccountHasSucceded: false,
  newAccountValidationErrors: null,
  newAccountErrorMessage: '',
  accountModalIsOpen: false,
  loginErrorMessage: '',
  loginHasFailed: false,
  currentLoggedInUser: null,
  unlockAccountReqIsLoading: false,
  unlockAccountReqHasFailed: false,
  unlockAccountReqHasSucceded: false,
  unlockAccountReqErrorMessage: ''
};

export const actionCreators = {
  forgotPassword: (email, callbackUrl, setError) => (dispatch, getState) => {
    let requestIsLoading = true;

    // Loading
    dispatch({
      type: postForgotPwdInfo
    });

    let apiClient = new ResetPasswordApiClient();
    const request = apiClient.forgotPassword(email, callbackUrl);

    return request.then(
      response => {
        dispatch({
          type: postForgotPwdInfoSuccess
        });
        const eventData = getPasswordChangeEventObject(
          GTM_EVENT.ACTION.passwordChangeRequest,
          GTM_EVENT.LABEL.greatWolf
        );
        pushEvent(eventData);
      },
      error => {
        dispatch({
          type: postForgotPwdInfoFailure,
          error: !requestIsLoading
        });

        if (error.validationErrors) {
          const [errorCode] = error.validationErrors.errorCode;

          if (errorCode.toUpperCase().includes(USER_NOT_FOUND_ERROR.toUpperCase())) {
            setError({
              forgotPassword: {
                email: USER_NOT_FOUND
              }
            });
          }
        }
      }
    );
  },

  pushToPage: url => dispatch => {
    dispatch(push(url));
  },

  changePassword: (email, newPassword, resetToken, oldPassword) => (dispatch, getState) => {
    dispatch({
      type: postChangePwdInfoLoadingType,
      changePwdReqIsLoading: true
    });

    let apiClient = new ResetPasswordApiClient();
    let request;

    if (resetToken) {
      request = apiClient.changePassword(email, newPassword, resetToken);
    } else {
      request = apiClient.changePasswordByOldPassword(email, oldPassword, newPassword);
    }
    const eventData = getPasswordChangeEventObject(GTM_EVENT.ACTION.passwordChangeAttempt, GTM_EVENT.LABEL.greatWolf);
    pushEvent(eventData);
    return request.then(
      response => {
        dispatch({
          type: postChangePwdInfoSuccessType,
          changePwdReqIsLoading: false
        });
        const eventData = getPasswordChangeEventObject(
          GTM_EVENT.ACTION.passwordChangeSuccess,
          GTM_EVENT.LABEL.greatWolf
        );
        pushEvent(eventData);
      },
      err => {
        dispatch({
          type: postChangePwdInfoErrorType,
          changePwdReqErrorMessage: err.validationErrors || getErrorMessage(err)
        });
        dispatch({
          type: postChangePwdInfoLoadingType,
          postForgotPwdLoading: false
        });
        const eventData = getPasswordChangeEventObject(
          GTM_EVENT.ACTION.passwordChangeFailure,
          GTM_EVENT.LABEL.greatWolf
        );
        pushEvent(eventData);
      }
    );
  },

  unlockAccount: (email, resetToken) => dispatch => {
    dispatch({
      type: postUnlockAccountLoadingType,
      unlockAccountReqIsLoading: true
    });

    const apiClient = new UnlockApiClient();
    const request = apiClient.unlockAccount(email, resetToken);

    return request.then(
      response => {
        dispatch({
          type: postUnlockAccountSuccessType,
          unlockAccountReqIsLoading: false
        });
      },
      err => {
        dispatch({
          type: postUnlockAccountErrorType,
          unlockAccountReqErrorMessage: err.validationErrors || getErrorMessage(err)
        });
      }
    );
  },

  checkIfUserIsLoggedIn: () => (dispatch, getState) => {
    let usrIsLoggedIn = authClient.isLoggedIn();
    let user = null;

    if (usrIsLoggedIn) {
      user = authClient.getUser();
    }
    dispatch({
      type: currentLoggedInUserType,
      currentLoggedInUser: user
    });

    dispatch({
      type: currentUserIsLoggedInType,
      userIsLoggedIn: usrIsLoggedIn
    });
  },

  getCurrentLoggedInUser: () => (dispatch, getState) => {
    let usrIsLoggedIn = authClient.isLoggedIn();
    let user = null;

    if (usrIsLoggedIn) {
      user = authClient.getUser();
    }
    dispatch({
      type: currentLoggedInUserType,
      currentLoggedInUser: user
    });
  },

  logoutCurrentUser: (checkToRedirect = true) => async (dispatch, getState) => {
    const usrIsLoggedIn = false;

    await authClient.logout();

    dispatch({
      type: currentUserIsLoggedInType,
      userIsLoggedIn: usrIsLoggedIn
    });

    dispatch({ type: removeAllActiveOffersType });
    emitEventsByName(EVENTS_DICTIONARY.AEM_LOGOUT);
    if (checkToRedirect) {
      /**
       * If current path includes some of the routers which are account/login based,
       * redirect it to home page if user logs out, otherwise reload the current path
       */

      const state = getState();

      const currentPath = state.router.location.pathname;

      const isIncludedPath = routesRedirectOnSignOut.some(route => currentPath.includes(route));

      const resortLocationDataUrl = getCurrentResortDataUrl(state.currentLodge);

      if (isIncludedPath) {
        window.location.href = `${window.location.origin}/${resortLocationDataUrl}`;
      }
    }
  },

  createNewAccount: (
    requestObject,
    isAPI_2_2_Active = false,
    onSuccessCallBack = () => {},
    isLeadGenSignUp = false
  ) => async (dispatch, getState) => {
    dispatch({
      type: postNewAccountLoadingType,
      creatingNewAccount: true
    });
    try {
      const request = await authClient.createAccount(requestObject, isAPI_2_2_Active, isLeadGenSignUp);
      let usrIsLoggedIn = authClient.isLoggedIn();
      dispatch({
        type: postNewAccountSuccessType
      });
      dispatch({
        type: currentUserIsLoggedInType,
        userIsLoggedIn: usrIsLoggedIn
      });
      dispatch({ type: resetNewAccountInitialStateType });
      onSuccessCallBack(request);
      return request;
    } catch (err) {
      dispatch({
        type: postNewAccountErrorType,
        newAccountErrorMessage: getErrorMessage(err.message),
        newAccountValidationErrors: err.validationErrors
      });
      return err;
    }
  },

  //This functions is used to create a new user from a leadgen user
  createNewLeadGenAccount: (requestObject, isAPI_2_2_Active = false, onSuccessCallBack = () => {}) => (
    dispatch,
    getState
  ) => {
    dispatch({
      type: postNewAccountLoadingType,
      creatingNewAccount: true
    });

    const request = authClient.createLeadGenAccount(requestObject, isAPI_2_2_Active);

    return request.then(
      response => {
        let usrIsLoggedIn = authClient.isLoggedIn();
        dispatch({
          type: postNewAccountSuccessType
        });
        dispatch({
          type: currentUserIsLoggedInType,
          userIsLoggedIn: usrIsLoggedIn
        });
        dispatch({ type: resetNewAccountInitialStateType });
        onSuccessCallBack(response);
      },
      err => {
        dispatch({
          type: postNewAccountErrorType,
          newAccountErrorMessage: getErrorMessage(err.message),
          newAccountValidationErrors: err.validationErrors
        });
      }
    );
  },

  updateLoyaltyOptIn: requestObject => dispatch => {
    let user = JSON.parse(localStorage.getItem('user'));
    user.loyaltyOptIn = true;
    localStorage.setItem('user', JSON.stringify(user));
    ReactGA.set({ dimension57: 'YES' });
    return dispatch({
      type: loyaltyOptIn,
      loyaltyOptIn: true
    });
  },

  setLoginError: errorMsg => (dispatch, getState) => {
    dispatch({
      type: loginErrorType,
      loginErrorMessage: errorMsg
    });
  },

  resetAccountMethods: () => dispatch => {
    dispatch({
      type: resetAccountMethodsType
    });
  },

  ensureWebUser: requestObject => dispatch => {
    dispatch({
      type: postNewAccountLoadingType,
      creatingNewAccount: true
    });

    const request = authClient.ensureWebUser(requestObject);

    return request.then(
      response => {
        let usrIsLoggedIn = authClient.isLoggedIn();
        dispatch({
          type: postNewAccountSuccessType
        });
        dispatch({
          type: currentUserIsLoggedInType,
          userIsLoggedIn: usrIsLoggedIn
        });
        dispatch({ type: resetNewAccountInitialStateType });
      },
      err => {
        dispatch({
          type: postNewAccountErrorType,
          newAccountErrorMessage: getErrorMessage(err.message),
          newAccountValidationErrors: err.validationErrors
        });
      }
    );
  },

  clearPwChangeFeedback: () => dispatch => {
    dispatch({
      type: resetPwFeedback
    });
  },

  updateUserPointsAndOffers: userId => async (dispatch, getState) => {
    const userAccountApiClient = new UserAccountApiClientTioga();
    const payload = {
      profileId: userId,
      queryParams: {}
    };

    try {
      const getUpdatedUserPoints = await userAccountApiClient.getUserUpdatedPointsAndOffers(payload);
      const currentUserLocalData = JSON.parse(localStorage.getItem('user')) || {};
      if (currentUserLocalData && Object.keys(currentUserLocalData).length > 0) {
        currentUserLocalData.pointsBalance = getUpdatedUserPoints.data.pointsBalance;
        currentUserLocalData.offerList = getUpdatedUserPoints.data.offerList;
        localStorage.setItem('user', JSON.stringify(currentUserLocalData));
      }

      const currentUserReduxData = getState().account.currentLoggedInUser;
      if (currentUserReduxData && Object.keys(currentUserReduxData).length > 0) {
        currentUserReduxData.pointsBalance = getUpdatedUserPoints.data.pointsBalance;
        currentUserReduxData.offerList = getUpdatedUserPoints.data.offerList;
      }

      dispatch({
        type: updateUserPointsAndOffers,
        loyaltyOptIn: currentUserReduxData
      });
    } catch (error) {
      console.log(error);
    }
  },
  resetCreateAccountFlow: () => dispatch => {
    dispatch({ type: resetNewAccountInitialStateType });
  },
  getUserPointsHistory: () => async (dispatch, getState) => {
    const userApiClient = new UserAccountApiClientTioga('v1');

    try {
      const getPointsHistory = await userApiClient.getUserPointsHistory();
      const currentUserLocalData = JSON.parse(localStorage.getItem('user')) || {};
      if (currentUserLocalData && Object.keys(currentUserLocalData).length > 0) {
        currentUserLocalData.nextExpirationDate = getPointsHistory.data.nextExpirationDate;
        currentUserLocalData.expiringMetricValue = getPointsHistory.data.expiringMetricValue;
        localStorage.setItem('user', JSON.stringify(currentUserLocalData));
      }

      const currentUserReduxData = getState().account.currentLoggedInUser;
      if (currentUserReduxData && Object.keys(currentUserReduxData).length > 0) {
        currentUserReduxData.nextExpirationDate = getPointsHistory.data.nextExpirationDate;
        currentUserReduxData.expiringMetricValue = getPointsHistory.data.expiringMetricValue;
      }

      dispatch({
        type: getUserPointsHistory,
        loyaltyOptIn: currentUserReduxData
      });
    } catch (error) {
      console.log(error);
    }
  },
  updateCurrentLoggedInUser: data => dispatch => {
    let user = JSON.parse(localStorage.getItem('user'));
    user.loyaltyMemberId = data.memberId;
    user.loyaltyReferralCode = data.referralCode;
    localStorage.setItem('user', JSON.stringify(user));
    return dispatch({
      type: updateMemberIdAndReferralCode,
      loyaltyMemberId: user.loyaltyMemberId,
      loyaltyReferralCode: user.loyaltyReferralCode
    });
  }
};

// This reducer combines reducers for providing new values
// to the Redux store based on actions defined above
//
// If the TYPE matches the action, the new state will replace the old
// this occurs on a per action basis and you should see the Redux store
// (state tree) reflect the change

export const reducer = (state, action) => {
  state = initializeState(state, initialState);

  // Account information reducers
  switch (action.type) {
    case postForgotPwdInfo:
      return {
        ...state,
        forgotPwdReqIsLoading: true,
        forgotPwdReqHasFinished: false,
        forgotPwdError: false
      };
    // The request is currently running
    case postForgotPwdInfoSuccess:
      return {
        ...state,
        forgotPwdReqIsLoading: false,
        forgotPwdReqHasFinished: true,
        forgotPwdError: false
      };
    case postForgotPwdInfoFailure:
      return {
        ...state,
        forgotPwdReqIsLoading: false,
        forgotPwdReqHasFinished: false,
        forgotPwdError: action.error
      };
    // CHANGE PASSWORD
    // Post to change password request succeded
    case postChangePwdInfoSuccessType:
      return {
        ...state,
        changePwdReqIsLoading: false,
        changePwdReqHasFailed: false,
        changePwdReqHasSucceded: true,
        forgotPwdErrorMessage: ''
      };
    // The request is currently running
    case postChangePwdInfoLoadingType:
      return {
        ...state,
        changePwdReqIsLoading: action.changePwdReqIsLoading
      };
    // Post to change password request failed
    case postChangePwdInfoErrorType:
      return {
        ...state,
        changePwdReqIsLoading: false,
        changePwdReqHasFailed: true,
        changePwdReqHasSucceded: false,
        changePwdReqErrorMessage: action.changePwdReqErrorMessage
      };
    // Set if user is already logged in
    case currentUserIsLoggedInType:
      return {
        ...state,
        userIsLoggedIn: action.userIsLoggedIn
      };

    case currentLoggedInUserType:
      return {
        ...state,
        currentLoggedInUser: action.currentLoggedInUser
      };

    // A new account is being created
    case postNewAccountLoadingType:
      return {
        ...state,
        creatingNewAccount: action.creatingNewAccount
      };
    // The new account request has successfully finished
    case postNewAccountSuccessType:
      return {
        ...state,
        newAccountHasSucceded: true,
        creatingNewAccount: false,
        newAccountValidationErrors: null,
        newAccountErrorMessage: ''
      };
    // The new account request has successfully finished
    case postNewAccountErrorType:
      return {
        ...state,
        newAccountHasSucceded: false,
        creatingNewAccount: false,
        newAccountValidationErrors: action.newAccountValidationErrors,
        newAccountErrorMessage: action.newAccountErrorMessage
      };
    // Reset all values to their initial state
    case resetNewAccountInitialStateType:
      return {
        ...state,
        creatingNewAccount: false,
        newAccountHasSucceded: false,
        newAccountValidationErrors: null,
        newAccountErrorMessage: ''
      };
    // Set an login error
    case loginErrorType:
      return {
        ...state,
        loginErrorMessage: action.loginErrorMessage,
        loginHasFailed: true
      };

    // post to unlock account request is succeded.
    case postUnlockAccountSuccessType:
      return {
        ...state,
        unlockAccountReqIsLoading: false,
        unlockAccountReqHasFailed: false,
        unlockAccountReqHasSucceded: true,
        unLockAccountErrorMessage: ''
      };

    // The request is currently running
    case postUnlockAccountLoadingType:
      return {
        ...state,
        unlockAccountReqIsLoading: action.unlockAccountReqIsLoading
      };

    // Post to unlock account request failed
    case postUnlockAccountErrorType:
      return {
        ...state,
        unlockAccountReqIsLoading: false,
        unlockAccountReqHasFailed: true,
        unlockAccountReqHasSucceded: false,
        unlockAccountReqErrorMessage: action.unlockAccountReqErrorMessage
      };
    //Reset pw feedback
    case resetPwFeedback:
      return {
        ...state,
        changePwdReqHasFailed: false,
        changePwdReqHasSucceded: false
      };
    //Update Loyalty Flagh
    case loyaltyOptIn:
      return {
        ...state,
        currentLoggedInUser: { ...state.currentLoggedInUser, loyaltyOptIn: action.loyaltyOptIn }
      };
    //Update currentLoggedInUser with the memberId and referralCode
    case updateMemberIdAndReferralCode:
      return {
        ...state,
        currentLoggedInUser: {
          ...state.currentLoggedInUser,
          loyaltyMemberId: action.loyaltyMemberId,
          loyaltyReferralCode: action.loyaltyReferralCode
        }
      };

    // Reset all account methods
    case resetAccountMethodsType:
      return {
        forgotPwdReqIsLoading: false,
        forgotPwdReqHasFinished: false,
        changePwdReqIsLoading: false,
        changePwdReqHasFailed: false,
        changePwdReqHasSucceded: false,
        changePwdReqErrorMessage: '',
        creatingNewAccount: false,
        newAccountHasSucceded: false,
        newAccountValidationErrors: null,
        newAccountErrorMessage: '',
        loginErrorMessage: '',
        loginHasFailed: false,
        unlockAccountReqIsLoading: false,
        unlockAccountReqHasFailed: false,
        unlockAccountReqHasSucceded: false,
        unlockAccountReqErrorMessage: ''
      };
    default:
      return state;
  }
};

// Selectors
export const getUserIsLoggedIn = state => {
  return state.userIsLoggedIn;
};

export const getUserFirstName = state => {
  return get(state, 'currentLoggedInUser.firstName');
};

export const getUserLastName = state => {
  return get(state, 'currentLoggedInUser.lastName');
};

export const getUserId = state => {
  return get(state, 'currentLoggedInUser.id');
};

export const getChangePwdIsLoading = state => {
  return state.changePwdReqIsLoading;
};

export const getPwChangeSuccess = state => {
  return state.changePwdReqHasSucceded;
};

export const getPwChangeFailed = state => {
  return state.changePwdReqHasFailed;
};

export const getPwErrorMessage = state => {
  return state.changePwdReqErrorMessage;
};

export const getUserLoyaltyOptIn = state => {
  return get(state, 'currentLoggedInUser.loyaltyOptIn');
};

export const getUserLoyaltyPointsBalance = state => {
  return get(state, 'currentLoggedInUser.pointsBalance');
};

export const getUserOffers = state => {
  return get(state, 'currentLoggedInUser.offerList');
};

export const getUserReferralCode = state => {
  return get(state, 'currentLoggedInUser.loyaltyReferralCode');
};

export const getLoyaltyMemberId = state => {
  return get(state, 'currentLoggedInUser.loyaltyMemberId');
};

export const getReferralNotEligible = state => {
  return get(state, 'currentLoggedInUser.referralNotEligible');
};

export const shouldUseV22CreateUserAPI = state => {
  const isLoyaltyEnabled = state.lodgeConfig.isLoyaltyEnabled;
  const isOnLLP = state.router.location.pathname === '/voyagers-club';
  const isPrimerState = state.lodgeConfig.isInPrimerState;

  if (isLoyaltyEnabled === false) return false; // use V2.1 Api if loyalty is not active

  if (isLoyaltyEnabled === true && isOnLLP === false && isPrimerState === true) return false; // use V2.1 Api if user is on Booking Engine and lodge is on Primer State

  return true; // use V2.2 Api
};

export const getExpirationPoints = state => {
  const nextExpirationDate = get(state, 'currentLoggedInUser.nextExpirationDate');
  const expiringMetricValue = get(state, 'currentLoggedInUser.expiringMetricValue');
  const expirationObject = { nextExpirationDate, expiringMetricValue };
  return expirationObject;
};
