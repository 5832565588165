import { GwDatesWrapper } from '../components/_internal_date_/gwDatesWrapper';
import { DATE_FORMATS } from './constants';

/**
 *
 * @param {Date} date to be converted
 * @returns Date in the format MM/YY
 */
export const getDateMonthDayFormat = date => {
  return date && GwDatesWrapper.format(date, DATE_FORMATS.monthDayFormat);
};

/**
 * @param {Date | string} date to be converted
 * @returns Date in the format Month. DD, YYYY
 */
export const getMonthLetterDayYearFormat = date => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    return GwDatesWrapper.format(date, DATE_FORMATS.monthLetterDayYear);
  }

  return GwDatesWrapper.format(date, DATE_FORMATS.monthLetterDayYear);
};
