import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import Relative from './Relative';
import Text from './Text';
import theme from './themes/theme';

const ALIGNMENT = {
  right: 'right',
  left: 'left',
  center: 'center'
};

/**
 * Tooltip component used to display complementary information
 * when the user hovers the trigger component
 * @param {Object} props Props object.
 * @param {string} props.ariaKey Unique string ID in order to attend ADA Compliance
 * @param {number} props.tooltipWidth Tooltip width
 * @param {string} props.borderColor Tooltip border and detail color
 * @param {string} props.title Tooltip text content
 * @param {string} props.textAlign Tooltip text content alignment
 * @param {string} props.textColor Tooltip text content color
 * @param {object} props.triggerComponent Component that will be rendered to open the tooltip
 * @param {function} props.tooltipContent Tooltip component that will override the default one
 * @param {string} props.alignment Tooltip alignment, either: center, left or
 * right.
 */
const Tooltip = ({
  mobileAlignment = false,
  alignment,
  verticalAlignment,
  tooltipWidth,
  borderRadius,
  tooltipContent,
  triggerComponent,
  ariaKey,
  borderColor,
  textAlign,
  textColor,
  title,
  fontSize
}) => {
  const triggerRef = useRef(null);
  const tooltipRef = useRef(null);
  const tooltipContainerRef = useRef(null);

  const [tooltipVisibility, setTooltipVisibility] = useState(false);
  const hasCurrentTriggerRef = triggerRef && triggerRef.current;
  const hasCurrentTooltipRef = tooltipRef && tooltipRef.current;
  const containerOffset = tooltipContainerRef?.current?.getBoundingClientRect();

  const getHorizontalOffset = () => {
    if (mobileAlignment && tooltipContainerRef.current) {
      return -containerOffset.left + triggerRef.current.offsetWidth * 2;
    }
    if (alignment === ALIGNMENT.left || !hasCurrentTriggerRef || !hasCurrentTooltipRef) {
      return -(tooltipWidth - 30);
    }

    if (alignment === ALIGNMENT.right) {
      return 0;
    }

    return -((tooltipWidth - triggerRef.current.offsetWidth) / 2);
  };

  const topOffset = hasCurrentTriggerRef ? triggerRef.current.offsetHeight + theme.space[2] : 0;

  const leftOffset = getHorizontalOffset();

  const TooltipContent = tooltipContent;
  const TriggerComponent = triggerComponent;

  return (
    <Relative
      ref={tooltipContainerRef}
      role="complementary"
      aria-label={`${ariaKey}`}
      onMouseOver={() => {
        setTooltipVisibility(true);
      }}
      onMouseLeave={() => {
        setTooltipVisibility(false);
      }}>
      <TriggerComponent triggerRef={triggerRef} />

      <TooltipContainer
        ref={tooltipRef}
        tooltipVisibility={tooltipVisibility}
        tooltipWidth={tooltipWidth}
        borderRadius={borderRadius}
        top={topOffset}
        verticalAlignment={verticalAlignment}
        left={leftOffset}
        borderColor={borderColor}
        textAlign={textAlign}
        alignment={alignment}
        mobileAlignment={mobileAlignment}
        containerLeft={containerOffset?.left}
        triggerWidth={triggerRef?.current?.offsetWidth}>
        <TooltipContent color={textColor}>{fontSize ? <Text fontSize={fontSize}>{title}</Text> : title}</TooltipContent>
      </TooltipContainer>
    </Relative>
  );
};

const DefaultTriggerComponent = styled.div`
  padding-left: ${`${theme.space[1]}px`};
  padding-right: ${`${theme.space[1]}px`};
  background-color: ${theme.colors.snowMountainGrey[100]};
  border: 1px solid ${theme.colors.deepForestGreenBorder};
  color: ${theme.colors.preciousStoneBlue[100]};
  font-weight: bold;
  font-size: ${`${theme.fontSizes[0]}px`};

  cursor: pointer;
`;

const TooltipContainer = styled.div`
  width: ${props => `${props.tooltipWidth}px`};
  display: ${props => (props.tooltipVisibility ? 'unset' : 'none')};
  position: absolute;
  left: ${props => `${props.left}px`};
  ${props => (props.verticalAlignment === 'bottom' ? `bottom: ${props.top}` : `top: ${props.top}`)}px;
  z-index: ${theme.zIndex.floatingButton};
  border: ${props => `1px solid ${props.borderColor}`};
  padding: ${`${theme.space[3]}px`};
  background-color: ${theme.colors.pureWhite[0]};
  text-align: ${props => props.textAlign};
  border-radius: ${props => props.borderRadius};

  &::after {
    content: ' ';
    position: absolute;
    bottom: calc(100% + 1px);

    border-width: 5px;
    border-style: solid;
    border-color: ${props =>
      props.verticalAlignment === 'bottom'
        ? 'transparent'
        : `transparent transparent ${props.borderColor} transparent`};
    ${props => {
      if (props.mobileAlignment) {
        return css`
          left: ${props.containerLeft - props.triggerWidth * 1.6}px;
        `;
      }

      if (props.alignment === ALIGNMENT.left) {
        return css`
          right: 17px;
        `;
      }

      if (props.alignment === ALIGNMENT.right) {
        return css`
          left: 5px;
        `;
      }

      if (props.alignment === ALIGNMENT.center) {
        return css`
          left: 50%;
          margin-left: -5px;
        `;
      }
    }}
  }
`;

Tooltip.defaultProps = {
  ariaKey: 'Tooltip',
  tooltipWidth: 290,
  borderColor: theme.colors.deepForestGreenBorder,
  title: 'Tooltip Content',
  textAlign: 'left',
  textColor: theme.colors.nightSkyBlack[60],
  triggerComponent: props => <DefaultTriggerComponent ref={props.triggerRef}>?</DefaultTriggerComponent>,
  tooltipContent: props => (
    <Text color={props.textColor} fontSize={1} fontWeight={theme.fontWeights.normal}>
      {props.children}
    </Text>
  ),
  alignment: ALIGNMENT.center
};

Tooltip.propTypes = {
  ariaKey: PropTypes.string,
  tooltipWidth: PropTypes.number,
  borderColor: PropTypes.string,
  title: PropTypes.string,
  textAlign: PropTypes.string,
  textColor: PropTypes.string,
  triggerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  tooltipContent: PropTypes.func,
  alignment: PropTypes.oneOf(Object.values(ALIGNMENT))
};
export default Tooltip;
