import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import AuthClient from '../../../../api/auth/AuthClient';
import { getLocationDisplayName } from '../../../../components/utilities/ResortLocations';
import { getResortLocation } from '../../../../store/componentStores/CurrentLodge';
import { addActiveOfferType, removeActiveOfferType } from '../../../../store/componentStores/Offer';
import { deletePackageType, pkgImpressionsData, upsertPackageType } from '../../../../store/componentStores/Packages';
import { logNewRevervationOrDayPassType } from '../../../../store/componentStores/Reservations';
import { logImpressionsData } from '../../../../store/componentStores/Suite';
import { logPostSalePackageAdded } from '../../../../store/componentStores/reservationDetailsEntity';
import { store } from '../../../../store/configureStore';
import {
    optimizelyDaypassPackagesGold,
    optimizelyDaypassPackagesPlatinum,
    optimizelyDaypassPackagesSilver
} from '../../../../utilities/ABTesting';
import { DAY_PASSES_TYPE, EVENT_CATEGORIES, GTM_EVENT, PRODUCT_SCOPES } from '../../../../utilities/constants';
import { pushEvent } from '../../../../utilities/dataLayerUtils';
import { findElementsNotInList } from '../../../../utilities/objectKeysManagementUtils';
import { getReservationCurrency } from '../../../../utilities/reservationsUtils';
import {
    buildImpressionsDataObject,
    buildImpressionsDataObjectPkgs,
    buildImpressionsEventObject,
    buildPackageCartEventObject,
    buildPackagesPurchaseEnhancedECommerceObject,
    buildPurchaseEnhancedECommerceObject,
    buildSuitePurchaseEnhancedECommerceObject,
    getBaseSuiteObject,
    getDayPassBaseObject,
    getEventBaseObject
} from '../analyticsObjectBuilders';
import {
    getAddedPackagesCodes,
    getAnalyticsPayload,
    getGroupedAddedPackages,
    getPaymentDescription,
    sendCabanasAnalytics,
    sendPostPurchasePkg
} from '../analyticsUtils';

export const onPushImpressionsData = ({ eventProps }) => {
  const {
    currentSuite,
    resortLocation,
    checkoutDateSelection,
    checkinDateSelection,
    adultsCount,
    kidsCount,
    position,
    customPurchaseLocation
  } = eventProps;
  const { guests } = store.getState();
  const kidsAges = guests.kidsAges;
  const impressionsDataObj = buildImpressionsDataObject(
    kidsAges,
    currentSuite,
    resortLocation,
    checkoutDateSelection,
    checkinDateSelection,
    adultsCount,
    kidsCount,
    position,
    customPurchaseLocation
  );

  const eventData = buildImpressionsEventObject(resortLocation, impressionsDataObj, customPurchaseLocation);

  pushEvent(eventData);
};

onPushImpressionsData.eventType = logImpressionsData;

export const onPkgsImpressionsData = ({ eventProps }) => {
  const {
    pkgs,
    resortLocation,
    checkoutDateSelection,
    checkinDateSelection,
    adultsCount,
    kidsCount,
    customPurchaseLocation
  } = eventProps;

  if (!pkgs || pkgs.length === 0) {
    return;
  }

  const { router } = store.getState();
  const currentPathLocation = get(router, 'location.pathname', '');
  const purchaseLocation = getCurrentLocation(currentPathLocation);

  const impressionProducts = pkgs.map((pkg, index) => {
    return buildImpressionsDataObjectPkgs(
      pkg,
      resortLocation,
      checkoutDateSelection,
      checkinDateSelection,
      adultsCount,
      kidsCount,
      index,
      customPurchaseLocation ?? purchaseLocation
    );
  });

  const eventData = buildImpressionsEventObject(
    resortLocation,
    impressionProducts,
    customPurchaseLocation ?? purchaseLocation
  );

  pushEvent(eventData);
};

onPkgsImpressionsData.eventType = pkgImpressionsData;

const handlePackagePurchaseTrackingAction = pckCode => {
  return `${pckCode} Pass Purchased`;
};

/**
 * Listener on purchase event
 * @param {Object} event
 * @param {Array} eventsHistory
 */
export const onReservationSuccess = (event, eventsHistory) => {
  const UUID = uuidv4();
  const { suite } = store.getState();
  const { reservation, packages, suiteContent } = event.response;
  const { suiteCategory } = suiteContent;
  if (!reservation) return;
  const voyagerPointsDiscount = reservation.rateInfo.totalPoints;
  const offersPointsDiscount = reservation.rateInfo.totalOffers;
  const packagesResponse = reservation.packages || [];
  pushSuiteCheckoutSuccessData(
    reservation,
    packagesResponse,
    packages,
    voyagerPointsDiscount,
    offersPointsDiscount,
    suite.dayType,
    suiteContent,
    UUID
  );
  pushReservationData(reservation, packagesResponse, packages, suite.dayType, suiteCategory, UUID);
  pushInteractionsData(reservation, packagesResponse);
  // In order to save this event in the history (so we can log it) we should return it!
  // otherwise it will be ignored!

  const passPackagesCodes = packages.filter(pck => pck.packageType === 'Pass').map(pck => pck.packageCode);
  const fullPassPackages = packagesResponse.filter(pck => passPackagesCodes.includes(pck.packageCode));

  fullPassPackages.forEach(pck => {
    pushEvent({
      event: GTM_EVENT.NAME.passPurchase,
      category: EVENT_CATEGORIES.GTM.passPackagePurchasesVariationB,
      action: handlePackagePurchaseTrackingAction(pck.packageCode),
      label: `Revenue: ${pck.packageAmount}`
    });
  });

  return event;
};

onReservationSuccess.eventType = logNewRevervationOrDayPassType; // Redux Action Type. Get it from the store

/**
 * Listener triggered on post package added, currently on CMP
 * @param {Object} event Event object triggered by redux
 * @returns Object -- event
 */
export const onPackageAdded = event => {
  try {
    const { response, getState } = event;

    if (!response) return;

    const reservation = get(response, 'reservation', {});
    const packages = get(response, 'packages', []);
    const cabanas = get(response, 'cabanas', []);
    const maxOccupancy = get(response, 'maxOccupancy', 0);
    const customPurchaseLocation = get(response, 'customPurchaseLocation', null);

    const { router, suite } = store.getState();
    const currentPathLocation = get(router, 'location.pathname', '');
    const purchaseLocation = customPurchaseLocation ? customPurchaseLocation : getCurrentLocation(currentPathLocation);

    if (!isEmpty(packages) && !isEmpty(reservation)) {
      const pkgAvailability = get(getState(), 'packages.packageAvailabilityList', []);
      sendPostPurchasePkg(packages, reservation, pkgAvailability, purchaseLocation, suite.dayType, maxOccupancy);
    }

    if (!isEmpty(cabanas) && !isEmpty(cabanas.reservations)) {
      const { entities } = getState();
      const reservationsData = get(entities, 'reservationDetails.data', []);

      if (!isEmpty(reservationsData)) {
        sendCabanasAnalytics(cabanas, reservationsData, suite.dayType);
      }
    }
  } catch {
    // Ingore error an continue excecution as normal
  }

  return event;
};

onPackageAdded.eventType = logPostSalePackageAdded;

const pushInteractionsData = (reservation, packages) => {
  const interactionsData = {
    event: EVENT_CATEGORIES.INTERACTIONS.postReservation,
    reservationNumber: reservation.id ? reservation.id : reservation.externalId,
    reservationTotal: reservation.rateInfo.total,
    roomCode: reservation.selection.roomCode,
    roomQuantity: reservation.selection.numAdults + reservation.selection.numChildren,
    roomPrice: reservation.rateInfo.totalRoomRate,
    packages:
      packages && packages.length > 0
        ? packages.map(pkg => ({
            packageCode: pkg.packageCode,
            packageQuantity: pkg.quantity,
            packagePrice: pkg.packageAmount
          }))
        : null
  };
  pushEvent(interactionsData);
};

const pushReservationData = (reservation, reservationPackages, packagesContent, dayType, suiteCategory, UUID) => {
  const authClient = new AuthClient();
  const isUserLoggedIn = authClient.isLoggedIn();

  const { packages, packageTotalRevenue } = reservationPackages.reduce(
    (packagesAcc, reservationPackage) => {
      if (reservationPackage) {
        const foundPackage = packagesContent.find(pkg => pkg.packageCode === reservationPackage.packageCode);
        packagesAcc.packages.push({
          name: reservationPackage.description,
          id: reservationPackage.packageCode,
          variant: foundPackage ? foundPackage.packageType : '',
          brand: 'great wolf',
          price: reservationPackage.unitAmount,
          quantity: reservationPackage.quantity,
          category: 'packages',
          coupon: reservation.offerCode
        });

        packagesAcc.packageTotalRevenue += reservationPackage.quantity * reservationPackage.unitAmount;
      }
      return packagesAcc;
    },
    { packages: [], packageTotalRevenue: 0 }
  );

  // 1. Push data - track reservation id
  pushEitherSuiteOrDayPass(reservation, suiteCategory);

  // 2. Track checkout step 1 for checkout and 2 for confirmation
  pushEvent({
    event: EVENT_CATEGORIES.GTM.checkoutOption,
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 2,
          option: reservation.rateCode
        }
      }
    }
  });

  // 3. PuchaseSuite
  const purchaseSuiteObj = {
    event: EVENT_CATEGORIES.GTM.purchaseSuite,
    loggedinStatus: isUserLoggedIn,
    ecommerce: {
      purchase: {
        actionField: {
          id: UUID,
          confirmationNumber: reservation.id ? reservation.id : reservation.externalId,
          coupon: reservation.rateCode,
          revenue: reservation.rateInfo.totalRoomRate,
          SIP: dayType
        },
        products: [
          {
            name: reservation.roomDescription.split('-')[0].trim() || reservation.roomType,
            id: reservation.roomType,
            price: (reservation.rateInfo.totalRoomRate / reservation.selection.numberOfNights).toFixed(2),
            category: suiteCategory,
            variant: 'standard',
            brand: 'great wolf',
            quantity: reservation.selection.numberOfNights,
            dimension1: getPaymentDescription(reservation),
            coupon: reservation.offerCode
          }
        ]
      }
    }
  };

  pushEvent(purchaseSuiteObj);

  const uniquePackages = [...new Map(packages.map(m => [m.id, m])).values()];

  // 4. PurchasePackage
  const purchasePackageObj = {
    event: EVENT_CATEGORIES.GTM.purchasePackage,
    ecommerce: {
      purchase: {
        actionField: {
          id: UUID,
          confirmationNumber: reservation.id ? reservation.id : reservation.externalId,
          coupon: reservation.rateCode,
          revenue: packageTotalRevenue.toFixed(2),
          SIP: dayType
        },
        products: uniquePackages
      }
    }
  };

  pushEvent(purchasePackageObj);

  // 5. Package revenue
  pushEvent({
    total_package_revenue: packageTotalRevenue.toFixed(2)
  });

  // 6. Affirm
  try {
    const payload = getAnalyticsPayload(reservation);
    window.affirm.analytics.trackOrderConfirmed(payload);
  } catch (error) {}
};

const pushSuiteCheckoutSuccessData = (
  reservation,
  packages,
  packagesContent,
  voyagerPointsDiscount,
  offersPointsDiscount,
  dayType,
  suiteContent,
  UUID
) => {
  const { maxOccupancy, suiteCategory } = suiteContent;
  const products = [];
  const { router, suite, offer, account } = store.getState();
  const currentPathLocation = get(router, 'location.pathname', '');
  const purchaseLocation = getCurrentLocation(currentPathLocation);
  const selectedOffers = get(offer, 'activeOffers', []);
  const userOfferList = get(account, 'currentLoggedInUser.offerList', []);

  let allActiveOffers = [];
  let formatedOffers = [];
  // If we have offer we add the offers to the newAddedPackages
  if (selectedOffers.length > 0 && userOfferList.length > 0) {
    allActiveOffers = selectedOffers.map(offer => {
      const offerFound = userOfferList.find(userOffer => userOffer.id === offer);
      return {
        ...offerFound
      };
    });

    if (allActiveOffers.length > 0) {
      formatedOffers = allActiveOffers.map((offer, index) => {
        return {
          packageName: 'Bonus deal',
          packageCode: `bonusdeal${index}`,
          amount: offer.dollarValue,
          category: 'bonusDeal',
          packageCategory: offer.title,
          quantity: 1
        };
      });
    }
  }

  const suiteProduct = buildSuitePurchaseEnhancedECommerceObject(
    suite,
    reservation,
    purchaseLocation || PRODUCT_SCOPES.suiteCheckout,
    maxOccupancy,
    suiteCategory
  );

  products.push(suiteProduct);

  const uniquePackages = [...new Map(packages.map(m => [m.packageCode, m])).values()];

  if (uniquePackages) {
    const addedPackagesCodes = getAddedPackagesCodes(uniquePackages);
    const groupedAddedPackages = getGroupedAddedPackages({
      addedPackagesCodes,
      reservation
    });

    const packagesProducts = buildPackagesPurchaseEnhancedECommerceObject(
      {
        suite,
        addedPackagesCodes,
        packages: uniquePackages,
        groupedAddedPackages,
        reservation,
        packagesContent,
        formatedOffers,
        maxOccupancy
      },
      purchaseLocation || PRODUCT_SCOPES.suiteCheckout
    );
    products.push(...packagesProducts);
  }
  const purchaseEnhancedECommerceData = buildPurchaseEnhancedECommerceObject(
    {
      reservation,
      products,
      revenue: reservation.rateInfo.total.toFixed(2),
      tax: reservation.rateInfo.taxes.toFixed(2),
      shipping: reservation.rateInfo.resortFee.toFixed(2),
      voyagerPointsDiscount,
      offersPointsDiscount,
      dayType,
      UUID
    },
    EVENT_CATEGORIES.GTM.transaction,
    purchaseLocation || PRODUCT_SCOPES.suiteCheckout
  );

  pushEvent(purchaseEnhancedECommerceData);
};

const pushEitherSuiteOrDayPass = (reservation, suiteCategory) => {
  // get currency code
  const currencyCode = getReservationCurrency(reservation.selection.site);

  const [locationDisplayName, locationState] = getLocationDisplayName(reservation.property).split(', ');

  const isDayPass = Object.values(DAY_PASSES_TYPE).includes(reservation.roomType);

  const baseObject = getEventBaseObject(EVENT_CATEGORIES.GTM.fbPurchase, reservation, currencyCode);

  const suiteObject = getBaseSuiteObject(reservation, locationDisplayName, locationState, suiteCategory);

  const dayPassObject = getDayPassBaseObject(reservation);

  pushEvent({
    ...baseObject,
    ...(isDayPass ? dayPassObject : suiteObject)
  });

  //Daypass Dining packages event
  const daypassGoogleAnalyticsAction = {
    optimizelyDaypassPackagesSilver: GTM_EVENT.ACTION.daypassPurchaseVersionB,
    optimizelyDaypassPackagesGold: GTM_EVENT.ACTION.daypassPurchaseVersionC,
    optimizelyDaypassPackagesPlatinum: GTM_EVENT.ACTION.daypassPurchaseVersionD
  };

  const handleABCDTesting = () => {
    if (optimizelyDaypassPackagesGold) return 'optimizelyDaypassPackagesGold';
    if (optimizelyDaypassPackagesSilver) return 'optimizelyDaypassPackagesSilver';
    if (optimizelyDaypassPackagesPlatinum) return 'optimizelyDaypassPackagesPlatinum';
  };

  reservation.packages.length !== 0 &&
    (optimizelyDaypassPackagesGold || optimizelyDaypassPackagesPlatinum || optimizelyDaypassPackagesSilver) &&
    pushEvent({
      event: GTM_EVENT.NAME.daypassDiningPackagePurchase,
      category: EVENT_CATEGORIES.GTM.daypassPackagePurchase,
      action: daypassGoogleAnalyticsAction[handleABCDTesting()],
      label: `Dining Revenue: ${reservation.packages[0].totalAmount} - Total Daypass Revenue: ${reservation.rateInfo.totalAmount} `
    });
};

/**
 * Listener on purchase event
 * @param {Object} event
 * @param {Array} eventsHistory
 */
export const onPackagesUpsert = (event, eventsHistory) => {
  // load state contains the historic of the state
  // this event is triggered before it reaches to the reducer so we
  // load the prev saved state
  const { packages: packagesOld, guests, dates, currentLodge, router, suite } = store.getState();
  const selectedPackages = get(event, 'selectedPackages', []);
  const selectedPackagesOld = get(packagesOld, 'selectedPackages', []);
  const packagesTable = get(packagesOld, 'planPackages', []);
  const currentPathLocation = get(router, 'location.pathname', '');
  const purchaseLocation = getCurrentLocation(currentPathLocation);
  const resortLocation = getResortLocation(currentLodge);

  if (!selectedPackages.length > 0 || !packagesTable.length > 0) return;

  //Find values that are in selectedPackages but not in selectedPackagesOld
  let newAddedPackages = findElementsNotInList(selectedPackages, selectedPackagesOld, 'packageCode');

  // NOTE: Package completely removed -- this case is handled on this method: onPackageDelete
  // A. PACKAGE ADDITION
  // 1. New added package, new Added Packages comes with an object
  if (newAddedPackages && newAddedPackages.length > 0) {
    // Perform added package
    const eventObject = buildPackageCartEventObject(
      EVENT_CATEGORIES.GTM.addToCart,
      newAddedPackages,
      packagesTable,
      guests,
      dates,
      resortLocation,
      null,
      purchaseLocation,
      currentPathLocation,
      suite
    );
    pushEvent(eventObject);
  }
  // historic and new state. But the event was triggered, need to check the quantity diff
  else {
    selectedPackagesOld.forEach(pkgOld => {
      const pkg = selectedPackages.find(pkg => pkg.packageCode === pkgOld.packageCode);

      const qtyDiff = pkg ? pkg.quantity - pkgOld.quantity : 0;
      // if there is a difference between the historic and new information then we should track it
      // else ignore it
      if (qtyDiff !== 0) {
        // 2. Package already added but quantity added. Quanity is positive due the incresed difference
        let eventTag = EVENT_CATEGORIES.GTM.addToCart;

        // B. PACKAGE REMOVAL
        // 3. Package already added but quantity decreased. Quantity is negative due the decrese difference
        if (qtyDiff < 0) {
          // Perform removed package
          eventTag = EVENT_CATEGORIES.GTM.removeFromCart;
        }

        const eventObject = buildPackageCartEventObject(
          eventTag,
          [pkg],
          packagesTable,
          guests,
          dates,
          resortLocation,
          Math.abs(qtyDiff),
          purchaseLocation,
          currentPathLocation,
          suite
        );
        pushEvent(eventObject);
      }
    });
  }

  // In order to save this event in the history (so we can log it) we should return it!
  // otherwise it will be ignored!
  return event;
};
onPackagesUpsert.eventType = upsertPackageType; // Redux Action Type. Get it from the store

export const onPackageDelete = (event, eventsHistory) => {
  // load state contains the historic of the state
  // this event is triggered before it reaches to the reducer so we
  // load the prev saved state
  const { packages: packagesOld, guests, dates, currentLodge, router, suite } = store.getState();
  const selectedPackages = get(event, 'selectedPackages', []);
  const selectedPackagesOld = get(packagesOld, 'selectedPackages', []);
  const packagesTable = get(packagesOld, 'planPackages', []);
  const currentPathLocation = get(router, 'location.pathname', '');
  const purchaseLocation = getCurrentLocation(currentPathLocation);
  const resortLocation = getResortLocation(currentLodge);

  if (!selectedPackagesOld.length > 0 || !packagesTable.length > 0) return;

  let removedPackages = findElementsNotInList(selectedPackagesOld, selectedPackages, 'packageCode');

  if (removedPackages && removedPackages.length > 0) {
    // Perform added package
    const eventObject = buildPackageCartEventObject(
      EVENT_CATEGORIES.GTM.removeFromCart,
      removedPackages,
      packagesTable,
      guests,
      dates,
      resortLocation,
      null,
      purchaseLocation,
      currentPathLocation,
      suite
    );

    pushEvent(eventObject);
  }

  // In order to save this event in the history (so we can log it) we should return it!
  // otherwise it will be ignored!
  return event;
};
onPackageDelete.eventType = deletePackageType; // Redux Action Type. Get it from the store

export const onOfferAdded = (event, eventsHistory) => {
  // load state contains the historic of the state
  // this event is triggered before it reaches to the reducer so we
  // load the prev saved state
  const { guests, dates, currentLodge, router, account, suite } = store.getState();
  const currentPathLocation = get(router, 'location.pathname', '');
  const purchaseLocation = getCurrentLocation(currentPathLocation);
  const resortLocation = getResortLocation(currentLodge);
  const selectedOffer = event.offerId;
  const userOfferList = get(account, 'currentLoggedInUser.offerList', []);
  let addedOffers = [];

  // If we have offer we add the offers to the newAddedPackages
  if (selectedOffer) {
    const activeOffer = userOfferList.filter(offer => offer.id === selectedOffer);

    if (activeOffer) {
      const formatedOffers = activeOffer.map((offer, index) => {
        return {
          packageName: 'Bonus deal',
          packageCode: `bonusdeal${index}`,
          amount: offer.dollarValue,
          category: 'bonusDeal',
          packageCategory: offer.title,
          quantity: 1
        };
      });
      addedOffers = [...formatedOffers];
    }
  }

  if (addedOffers && addedOffers.length > 0) {
    // Perform added package
    const eventObject = buildPackageCartEventObject(
      EVENT_CATEGORIES.GTM.addToCart,
      addedOffers,
      null,
      guests,
      dates,
      resortLocation,
      null,
      purchaseLocation,
      currentPathLocation,
      suite
    );

    pushEvent(eventObject);
  }
};

onOfferAdded.eventType = addActiveOfferType; // Redux Action Type. Get it from the store

export const onOfferRemoved = (event, eventsHistory) => {
  // load state contains the historic of the state
  // this event is triggered before it reaches to the reducer so we
  // load the prev saved state
  const { guests, dates, currentLodge, router, account, suite } = store.getState();
  const currentPathLocation = get(router, 'location.pathname', '');
  const purchaseLocation = getCurrentLocation(currentPathLocation);
  const resortLocation = getResortLocation(currentLodge);
  const selectedOffer = event.offerId;
  const userOfferList = get(account, 'currentLoggedInUser.offerList', []);
  let addedOffers = [];

  // If we have offer we add the offers to the newAddedPackages
  if (selectedOffer) {
    const activeOffer = userOfferList.filter(offer => offer.id === selectedOffer);

    if (activeOffer) {
      const formatedOffers = activeOffer.map((offer, index) => {
        return {
          packageName: 'Bonus deal',
          packageCode: `bonusdeal${index}`,
          amount: offer.dollarValue,
          category: 'bonusDeal',
          packageCategory: offer.title,
          quantity: 1
        };
      });
      addedOffers = [...formatedOffers];
    }
  }

  if (addedOffers && addedOffers.length > 0) {
    // Perform added package
    const eventObject = buildPackageCartEventObject(
      EVENT_CATEGORIES.GTM.removeFromCart,
      addedOffers,
      null,
      guests,
      dates,
      resortLocation,
      null,
      purchaseLocation,
      currentPathLocation,
      suite
    );

    pushEvent(eventObject);
  }
};

onOfferRemoved.eventType = removeActiveOfferType; // Redux Action Type. Get it from the store

export function getCurrentLocation(path) {
  // Regular Expresion to check if the path contains /plan but only last slash is optional and can not be followed by confirmation
  const planPathRegex = /\/?plan(?!\/confirmation)/;
  // Test path value against planPathRegex
  const isPlanPath = planPathRegex.test(path);
  // If isPlanPath is true return standard_purchase
  if (isPlanPath) return GTM_EVENT.PURCHASE_LOCATION.standard_purchase;

  // Regular Expresion to check if the path contains ANYNUMBER/check-in while the slashes are optional
  const preCheckinPathRegex = /\/?\d+\/check-in/;
  // Test path value against preCheckinPathRegex
  const isPreCheckinPath = preCheckinPathRegex.test(path);
  // If isPreCheckinPath is true return pre_checkin
  if (isPreCheckinPath) return GTM_EVENT.PURCHASE_LOCATION.pre_checkin;

  // Regular Expresion to check if the path contains /my-reservations/ANYNUMBER or plan/confirmation
  const myReservationsPathRegex = /\/?my-reservations\/\d+|\/?plan\/confirmation/;
  // Test path value against myReservationsPathRegex
  const isMyReservationsPath = myReservationsPathRegex.test(path);
  // If isMyReservationsPath is true return my_reservations
  if (isMyReservationsPath) return GTM_EVENT.PURCHASE_LOCATION.post_package_purchase;

  return null;
}
