import PropTypes from 'prop-types';
import React from 'react';
import { pluralize } from '../../../../../../components/utilities/copyFunctions';
import * as COPY from '../utilities/copy';

import { CheckInOutDate } from '../../../../../CheckInOutDates';
import { Flex, Text } from '../../../../../ui-kit';
import ArrowRightIcon from '../../../../../ui-kit/icons/ArrowRight';
import theme from '../../../../../ui-kit/themes/theme';
import { SummaryContent } from '../../../Content';

const propTypes = {
  /** Checkin Date in the MM/DD format */
  checkIn: PropTypes.string.isRequired,

  /** Checkout Date in the MM/DD format */
  checkOut: PropTypes.string.isRequired,

  /** Number of nights for the reservation */
  numberOfNights: PropTypes.number.isRequired
};

export const Header = ({ checkIn, checkOut, numberOfNights, hasNewGuests, addGuestReservation }) => {
  const numberOfNightsLabel = nights => `${nights} ${pluralize('night', nights)}`;

  const newGuestsBold = <b>{COPY.NEW_GUESTS_BOLD}</b>;
  return (
    <>
      {(hasNewGuests || addGuestReservation) && (
        <SummaryContent.NewGuestsMessage
          copy={addGuestReservation ? addGuestReservation.alert : COPY.NEW_GUESTS_MESSAGE}
          newCopy={newGuestsBold}
        />
      )}
      <SummaryContent.Item>
        <CheckInOutDate>
          <CheckInOutDate.Label fontSize={9} mt="10px" lineHeight="16px" height="15px">
            {COPY.SUMMARY_CHECKIN}
          </CheckInOutDate.Label>
          <CheckInOutDate.Date fontSize="20px" height="15px" lineHeight="24px">
            {addGuestReservation ? addGuestReservation.checkin : checkIn}
          </CheckInOutDate.Date>
        </CheckInOutDate>
        <Flex mt="32px" flex="1" justifyContent="center">
          <ArrowRightIcon color={theme.colors.deepLakeBlue[100]} size={21} />
        </Flex>
        <CheckInOutDate>
          <CheckInOutDate.Label fontSize={9} mt="10px" lineHeight="16px" height="15px">
            {COPY.SUMMARY_CHECKOUT}
          </CheckInOutDate.Label>
          <CheckInOutDate.Date fontSize="20px" height="15px" lineHeight="24px">
            {addGuestReservation ? addGuestReservation.checkout : checkOut}
          </CheckInOutDate.Date>
        </CheckInOutDate>
      </SummaryContent.Item>
      <SummaryContent.Item>
        <SummaryContent.Description>
          <Text fontSize={9} fontWeight={700} lineHeight="16px">
            {addGuestReservation
              ? numberOfNightsLabel(addGuestReservation.numberOfNights)
              : numberOfNightsLabel(numberOfNights)}
          </Text>
        </SummaryContent.Description>
      </SummaryContent.Item>
    </>
  );
};

Header.propTypes = propTypes;
