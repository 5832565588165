import get from 'lodash-es/get';
import { isBBAR } from '../../api/Utils/BestAvailableRate';
import AvailabilityApiClient from '../../api/clients/AvailabilityApiClient';
import ItemApiClient from '../../api/clients/ItemApiClient';
import RoomBookingApiClient from '../../api/clients/RoomBookingApiClient';
import { GwDatesWrapper } from '../../components/_internal_date_/gwDatesWrapper';
import { rateCalendarGA } from '../../infrastructure/middleware/analytics/analyticsObjectBuilders';
import RecentSearchesRepository from '../../repositories/RecentSearchesRepository';
import { TESTS_DICTIONARY, getTestValueAB } from '../../utilities/ABTesting';
import {
    EVENT_CATEGORIES,
    trackVariationHEventError,
    trackVariationJEventError
} from '../../utilities/applicationInsights';
import { getTheFirstElementOfArray } from '../../utilities/arraysUtils';
import { getNormalizedCode } from '../../utilities/availabilityUtils';
import {
    DATE_FORMATS,
    GTM_EVENT,
    PERSONALIZATION_RULES,
    SORTING_CRITERIA,
    SUITE_TYPES
} from '../../utilities/constants';
import { pushEvent } from '../../utilities/dataLayerUtils';
import { getNumberOfBillableGuests } from '../../utilities/guestsUtils';
import { initializeState } from '../../utilities/storageUtils';
import { getResortLocation } from './CurrentLodge';
import { gaTrackingPlanSearchErrorCode } from './utilities/util';
export const setAvailabilityDetailsError = 'SET_AVAILABILITY_DETAILS_ERROR';

export const getAvailabilityInfoSuccessType = 'GET_AVAILABILITY_INFO_SUCCESS';
export const getAvailabilityInfoErrorType = 'GET_AVAILABILITY_INFO_ERROR';
const getAvailabilityInfoLoadingType = 'GET_AVAILABILITY_INFO_LOADING';

const setIsErrorModalAlreadyClosedType = 'SET_IS_ERROR_MODAL_ALREADY_CLOSED';
const setTopFiveSuites = 'SET_TOP_FIVE_SUITES';
const getRecentSearchesInfoSuccessType = 'GET_RECENT_SEARCHES_INFO_SUCCESS';
const getRecentSearchesInfoLoadingType = 'GET_RECENT_SEARCHES_INFO_LOADING';
const getRecentSearchesInfoErrorType = 'GET_RECENT_SEARCHES_INFO_ERROR';
const setReqRecentSearchesInfoLoadingType = 'SET_REQUEST_RECENT_SEARCHES_INFO_SUCCESS';
const delReqRecentSearchesInfoLoadingType = 'DEL_REQUEST_RECENT_SEARCHES_INFO_SUCCESS';
const selectSuiteForRangeType = 'SELECT_SUITE_FOR_RANGE_TYPE';
const unSelectSuiteForRangeType = 'UNSELECT_SUITE_FOR_RANGE_TYPE';
const openRatesCalendarType = 'OPEN_RATES_CALENDAR';
const closeRatesCalendarType = 'CLOSE_RATES_CALENDAR';
const openRatesCalendarRestrictionType = 'OPEN_RATES_CALENDAR_RESTRICTION';
const openRatesCalendarRestrictionIncrementType = 'OPEN_RATES_CALENDAR_RESTRICTION_INCREMENT';
const closeRatesCalendarRestrictionType = 'CLOSE_RATES_CALENDAR_RESTRICTION';
const openPaymentLeaveModalType = 'OPEN_PAYMENT_LEAVE_MODAL';
const closePaymentLeaveModalType = 'CLOSE_PAYMENT_LEAVE_MODAL';

const getLowestRatesCalendar = 'GET_LOWEST_RATES-CALENDAR';
const getLowestRatesCalendarMobile = 'GET_LOWEST_RATES_CALENDAR_MOBILE';
const getLowestRatesCalendarError = 'GET_LOWEST_RATES_CALENDAR_ERROR';
const cleanCalendarRestrictionsAndLowestRates = 'CLEAN_CALENDAR_RESTRICTIONS_AND_LOWEST_RATES';

const getAvailabilityHighestRatesType = 'GET_AVAILABILITY_HIGHEST_RATES';
const getAvailabilityHighestRatesSuccessType = 'GET_AVAILABILITY_HIGHEST_RATES_SUCCESS';
export const getAvailabilityHighestRatesErrorType = 'GET_AVAILABILITY_HIGHEST_RATES_ERROR';

const setAppliedFiltersType = 'SET_APPLIED_FILTERS';
const setSelectedSuiteForCalendarType = 'SET_SELECTED_SUITE_NAME_FOR_CALENDAR';

const getAvailabilityRangeType = 'GET_AVAILABILITY_RANGE';
const getAvailabilityRangeSuccessType = 'GET_AVAILABILITY_RANGE_SUCCESS';
const getAvailabilityRangeErrorType = 'GET_AVAILABILITY_RANGE_ERROR';

const getAvRangeBySuiteIsLoadingType = 'GET_AVAILABILITY_RANGE_BY_SUITE_LOADING';
const getAvRangeBySuiteFailedType = 'GET_AVAILABILITY_RANGE_BY_SUITE_ERROR';
const getAvRangeBySuiteResetType = 'GET_AVAILABILITY_RANGE_BY_SUITE_RESET';

const setLatestVersionJEventError = 'SET_LATEST_J_VERSION_EVENT_ERROR';
const setLatestVersionHEventError = 'SET_LATEST_H_VERSION_EVENT_ERROR';

const getItemQuantityType = 'GET_ITEM_QUANTITY_TYPE';
const getItemQuantitySuccessType = 'GET_ITEM_QUANTITY_SUCCESS';
const getItemQuantityErrorType = 'GET_ITEM_QUANTITY_ERROR';

const toggleEmailCampaignsType = 'TOGGLE_EMAIL_CAMPAIGNS';

const clearAllAvailabilityDataType = 'CLEAR_ALL_AVAILABILITY_DATA';

const setIsCaliforniaResident = 'SET_IS_CALIFORNIA_RESIDENT';
const cleanIsCaliforniaResident = 'CLEAN_IS_CALIFORNIA_RESIDENT';

const setQualifyingID = 'SET_QUALIFYING_ID';
const qualifyingIDHasError = 'QUALIFYING_ID_HAS_ERROR';

const setTabIndexByEmailCmpgnType = 'SET_TAB_INDEX_BY_EMAIL_CAMPAIGN';
const setIsBookingWidgetFocusedType = 'SET_IS_BOOKING_WIDGET_FOCUSED';
const setSortingOrder = 'SET_SORTING_ORDER';
const setSortSuite = 'SET_SUITE_CODE_FILTER';

// SET AN INITIAL STATE FOR REDUX
const initialState = {
  availabilityList: [],
  qualifyingID: undefined,
  requiresQualifyingID: false,
  qualifyingIDError: false,
  offerCodeError: undefined,
  promoDescription: '',
  availabilityReqIsLoading: false,
  availabilityRequestFailed: false,
  availabilityErrorMessage: '',
  status: '',
  selectedSuiteForRange: '',
  selectedSuiteRange: {},
  selectedSuiteRangeRestrictions: {},
  isOpenRatesCalendar: false,
  recentSearchesList: [],
  searchesGetReqIsLoading: false,
  searchesSetReqIsLoading: false,
  searchesDelReqIsLoading: false,
  searchesReqFailed: false,
  searchesErrorMessage: '',
  selectedSuiteForCalendar: '',
  availabilityHighestRatesList: [],
  availabilityHighestRatesReqIsLoading: false,
  availabilityHighestRatesRequestFailed: false,
  filtersApplied: {
    amenities: [],
    accessibility: false,
    themed: false,
    standard: false,
    premium: false,
    condo: false,
    cottage: false
  },
  sorting: SORTING_CRITERIA.recommendedForYou,
  sortSuite: '',
  oldFiltersApplied: {},
  suiteAvailabilityList: [],
  getAvailabilityRangeIsLoading: false,
  getAvailabilityRangeFailed: false,
  getAvailabilityRangeMessage: '',
  getAvRangeBySuiteIsLoading: false,
  getAvRangeBySuiteFailed: false,
  getAvRangeBySuiteErrorMsg: '',
  isAPScall: false,
  itemQuantity: [],
  itemQuantityReqIsLoading: false,
  itemQuantityReqFailed: false,
  emailCampaign: {
    isEmailCampaign: false,
    planCalendarShouldBeBlocked: false,
    startCalendarAt: '',
    endCalendarAt: '',
    campaignObject: {}
  },
  emailCampaignSuiteType: SUITE_TYPES.unknown,
  isBookingWidgetFocused: false,
  isErrorModalAlreadyClosed: false,
  offerType: '',
  lcoPackages: {},
  personalizationResponses: [],
  dealsSummary: {},
  leavePayment: false,
  lowestRateCalendarList: {},
  getLowestRateCalendarErrorMsg: '',
  upsellPackagesContent: [],
  isCaliforniaResident: false,
  isTwoRateTypesSuites: false,
  latestVersionJEventErrorObj: null,
  latestVersionHEventErrorObj: null,
  topFiveSuites: {}
};

const getLowestRatesByStay = async (lowestRatesData, requestObject, suiteCode) => {
  const apiClient = new RoomBookingApiClient('v1.0');
  const shouldFetchRatesByStay =
    requestObject.arrival && requestObject.departure && lowestRatesData[requestObject.arrival];
  if (!shouldFetchRatesByStay) return {};

  try {
    const lowestRatesByStayResponse = await apiClient.getLowestRatesForSuiteByStay(
      requestObject,
      suiteCode,
      requestObject.lodgeCode
    );
    return lowestRatesByStayResponse?.data;
  } catch (err) {
    return {};
  }
};

const getCalendarRates = async (requestObject, suiteCode) => {
  const apiClient = new RoomBookingApiClient('v1.0');

  const { year, month, numberOfMonths, ...lowestRatesByStayRequestObject } = requestObject;

  const lowestRatesResponse = await apiClient.getLowestRatesForSuite(requestObject, suiteCode, requestObject.lodgeCode);
  const lowestRatesByStayResponse = await getLowestRatesByStay(
    lowestRatesResponse?.data,
    lowestRatesByStayRequestObject,
    suiteCode
  );
  return { ...(lowestRatesResponse?.data ?? {}), ...lowestRatesByStayResponse };
};

export const actionCreators = {
  setIsErrorModalAlreadyClosed: value => dispatch => {
    dispatch({
      type: setIsErrorModalAlreadyClosedType,
      value: value
    });
  },
  setTopFiveSuites: suiteList => dispatch => {
    dispatch({
      type: setTopFiveSuites,
      topFiveSuites: suiteList
    });
  },
  // Perform the request search by Phone and Cc to API endpoint
  getAvailability: (requestObject, shouldIgnoreOfferCodeError = false) => (dispatch, getState) => {
    dispatch({
      type: getAvailabilityInfoLoadingType,
      shouldIgnoreOfferCodeError: shouldIgnoreOfferCodeError,
      availabilityReqIsLoading: true,
      offerCodeError: undefined,
      availabilityList: []
    });

    // This way is the one we are going to use for get the Authorization from the AuthClient
    // Call General Availability endpoint to retrieve info on available suites
    let apiClient = new AvailabilityApiClient('v2.3');

    const request = apiClient.getAvailability(requestObject);

    return request.then(
      response => {
        const existsValidSuite = response && response.data && response.data.suites && response.data.suites.length > 0;

        const errorCode = get(response, 'data.errorCode', undefined);
        const rateCode = existsValidSuite && response.data.suites[0].rateCode;
        const isQualifyIdRequired = get(response, 'data.isQualifyIdRequired', false);
        const promoDescription = get(response, 'data.planDescription', '');
        const hasErrorCode = errorCode && requestObject.offerCode && isBBAR(rateCode);
        const personalizationRules = response.data.pr;
        const personalizationResponses = get(response, 'data.rr', []);
        const offerType = get(response, 'data.offerType', '');
        const lcoPackages = get(response, 'data.lco', []);
        const upsellPackagesContent = get(response, 'data.upsellPackagesContent', []);
        const availabilityList = existsValidSuite ? response.data.suites : [{}];
        const isTwoRateTypesSuites =
          existsValidSuite && response?.data?.suites?.some(suite => suite.rateOptions?.length > 1);

        //This code below until the end tag is for create a tracking for version J and H issues
        //======================================================================================
        const optimizelyDisplayResortFeeWithTotalInSuiteCardJ = getTestValueAB(
          TESTS_DICTIONARY.optimizelyDisplayResortFeeWithTotalInSuiteCardJ,
          false
        );
        const optimizelyDisplayResortFeeWithTotalInSuiteCardH = getTestValueAB(
          TESTS_DICTIONARY.optimizelyDisplayResortFeeWithTotalInSuiteCardH,
          false
        );
        const { isCaliforniaResident, latestVersionJEventErrorObj, latestVersionHEventErrorObj } = getState().plans;
        const { resortLocation } = getState().currentLodge;
        const { pricePerWaterPass } = getState().lodgeConfig;
        const { locationList } = getState().lodgeLocations;
        const { checkinDateSelection, checkoutDateSelection } = getState().dates;
        const { adultsCount, kidsCount, kidsAges } = getState().guests;
        const { code } = getState().offer;
        const lodgeLocationName = locationList[resortLocation]?.url;
        //Start version H tracking code
        //=============================
        if (optimizelyDisplayResortFeeWithTotalInSuiteCardH) {
          const numberOfBillableGuests = getNumberOfBillableGuests(adultsCount, kidsAges);
          const numberOfNights = GwDatesWrapper.diff(checkoutDateSelection, checkinDateSelection, 'days');
          //Savings percentage calculation START
          //=========================================
          let savingsLessThanFiveUpperToFithty = [];
          availabilityList?.forEach(suite => {
            const validGuests = numberOfBillableGuests <= 4 ? 4 : numberOfBillableGuests;
            const suiteOnlyRate = suite?.rateOptions?.find(rate => rate.rateType === 'ROOM_ONLY')
              ?.averageNightlyBaseRate;
            const suiteWithWaterParkRate = suite?.rateOptions?.find(rate => rate.rateType === 'WITH_WP')
              ?.averageNightlyBaseRate;
            const DPP_total = pricePerWaterPass * validGuests * (numberOfNights + 1);
            const DPP_new = (suiteWithWaterParkRate - suiteOnlyRate) * numberOfNights;
            const savings = DPP_total - DPP_new;
            const percentage = ((savings / DPP_total) * 100).toFixed();
            if (percentage < 5 || percentage > 50) {
              savingsLessThanFiveUpperToFithty.push({
                suiteName: suite.title,
                percentage: percentage + '%'
              });
            }
          });
          //Savings percentage calculation END
          //===================================
          if (savingsLessThanFiveUpperToFithty.length > 0) {
            const variationHObj = {
              lodge: lodgeLocationName,
              dates:
                'Arrival:' +
                GwDatesWrapper.format(checkinDateSelection, DATE_FORMATS.default) +
                ' Departure:' +
                GwDatesWrapper.format(checkoutDateSelection, DATE_FORMATS.default),
              promoCode: code,
              partySize: adultsCount + kidsCount,
              suiteStyle: 'version H',
              savings: savingsLessThanFiveUpperToFithty
            };
            if (JSON.stringify(latestVersionHEventErrorObj) !== JSON.stringify(variationHObj)) {
              trackVariationHEventError(EVENT_CATEGORIES.OnVersionHEventError, variationHObj);
              dispatch({
                type: setLatestVersionHEventError,
                variationHObj
              });
            }
          }
        }
        //End version H tracking code
        //=============================
        //Start version J tracking code
        //===============================
        if (optimizelyDisplayResortFeeWithTotalInSuiteCardJ) {
          const isJVersionAndIsTwoRatesTypesPresent =
            optimizelyDisplayResortFeeWithTotalInSuiteCardJ && isTwoRateTypesSuites;
          const isJVersionAndIsCaliforniaResident =
            optimizelyDisplayResortFeeWithTotalInSuiteCardJ && isCaliforniaResident;
          const isJVersionAndIsTwoRatesTypesPresentAndNonTwoRatesPresent =
            isJVersionAndIsTwoRatesTypesPresent &&
            availabilityList?.some(
              suite => suite.availabilityKey && (suite.rateOptions === null || suite.rateOptions?.length <= 1)
            );
          if (
            availabilityList?.length > 0 &&
            (!isJVersionAndIsTwoRatesTypesPresent ||
              isJVersionAndIsCaliforniaResident ||
              isJVersionAndIsTwoRatesTypesPresentAndNonTwoRatesPresent)
          ) {
            const variationJObj = {
              lodge: lodgeLocationName,
              dates:
                'Arrival:' +
                GwDatesWrapper.format(checkinDateSelection, DATE_FORMATS.default) +
                ' Departure:' +
                GwDatesWrapper.format(checkoutDateSelection, DATE_FORMATS.default),
              promoCode: code,
              partySize: adultsCount + kidsCount,
              californiaResidentFlag: isCaliforniaResident,
              rateCodes: availabilityList
                ?.filter(
                  suite =>
                    suite.availabilityKey !== null &&
                    (isCaliforniaResident
                      ? suite.rateOptions?.length <= 1 || suite.rateOptions === null || suite.rateOptions.length > 1
                      : suite.rateOptions?.length <= 1 || suite.rateOptions === null)
                )
                .map(suite => {
                  return {
                    name: suite.title,
                    rateCode: suite.rateCode
                  };
                }),
              suiteStyle: isCaliforniaResident
                ? 'version G'
                : isJVersionAndIsTwoRatesTypesPresentAndNonTwoRatesPresent
                ? 'version J and default'
                : 'default'
            };
            if (JSON.stringify(latestVersionJEventErrorObj) !== JSON.stringify(variationJObj)) {
              trackVariationJEventError(EVENT_CATEGORIES.OnVersionJEventError, variationJObj);
              dispatch({
                type: setLatestVersionJEventError,
                variationJObj
              });
            }
          }
        }
        //End version J tracking code
        //=============================

        dispatch({
          type: getAvailabilityInfoSuccessType,
          availabilityList: availabilityList,
          isAPScall: existsValidSuite ? response.data.suites.some(st => st.availabilityKey) : false,
          isTwoRateTypesSuites: isTwoRateTypesSuites,
          availabilityReqIsLoading: false,
          requiresQualifyingID: isQualifyIdRequired,
          isErrorModalAlreadyClosed: false,
          promoDescription: promoDescription,
          personalizationRules: personalizationRules,
          personalizationResponses: personalizationResponses,
          offerCodeError: hasErrorCode
            ? {
                errorCode: errorCode,
                offerCode: getNormalizedCode(requestObject.offerCode)
              }
            : undefined,
          offerType: offerType,
          lcoPackages: lcoPackages,
          upsellPackagesContent: upsellPackagesContent
        });
        if (hasErrorCode)
          gaTrackingPlanSearchErrorCode(
            JSON.stringify({
              errorCode: errorCode,
              offerCode: getNormalizedCode(requestObject.offerCode)
            })
          );
      },
      err => {
        dispatch({
          type: getAvailabilityInfoErrorType,
          shouldIgnoreOfferCodeError: shouldIgnoreOfferCodeError,
          availabilityList: [],
          promoDescription: '',
          requiresQualifyingID: false,
          availabilityReqIsLoading: false,
          availabilityRequestFailed: true,
          availabilityErrorMessage: `Oops, ${typeof err === 'string' ? err : err.message}`,
          offerCodeError: undefined
        });
        gaTrackingPlanSearchErrorCode(JSON.stringify(err));
      }
    );
  },

  getAvailabilityHighestRates: requestObject => (dispatch, getState) => {
    dispatch({
      type: getAvailabilityHighestRatesType,
      availabilityHighestRatesReqIsLoading: true
    });

    const apiClient = new RoomBookingApiClient();

    apiClient
      .getAvailabilityHighestRates(requestObject, getResortLocation(getState().currentLodge))
      .then(response => {
        dispatch({
          type: getAvailabilityHighestRatesSuccessType,
          availabilityHighestRatesList: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: getAvailabilityHighestRatesErrorType,
          availabilityHighestRatesList: []
        });
      });
  },

  setValidationError: validationError => dispatch => {
    dispatch({
      type: getAvailabilityInfoErrorType,
      availabilityList: [],
      availabilityRequestFailed: true,
      availabilityErrorMessage: `Oops, we have an error with: ${validationError.path}. Error: ${validationError.type}`
    });
  },

  getRecentSearches: () => dispatch => {
    dispatch({
      type: getRecentSearchesInfoLoadingType,
      searchesGetReqIsLoading: true
    });

    const recentSearchesRepository = new RecentSearchesRepository();
    const request = recentSearchesRepository.getAllRecentSearches();

    return request.then(
      response => {
        dispatch({
          type: getRecentSearchesInfoSuccessType,
          recentSearchesList: response
        });
        dispatch({
          type: getRecentSearchesInfoLoadingType,
          searchesGetReqIsLoading: false
        });
      },
      err => {
        dispatch({
          type: getRecentSearchesInfoErrorType,
          recentSearchesList: [],
          searchesReqFailed: true,
          searchesErrorMessage: `Oops, ${err.message}`
        });
        dispatch({
          type: getRecentSearchesInfoLoadingType,
          searchesGetReqIsLoading: false
        });
      }
    );
  },

  addRecentSearch: (recentSearchesList, availabilityData) => dispatch => {
    dispatch({
      type: setReqRecentSearchesInfoLoadingType,
      searchesSetReqIsLoading: true
    });

    let recentSearchData = {
      checkin: availabilityData.arrival,
      checkout: availabilityData.departure,
      num_adults: availabilityData.numberOfAdults,
      num_children: availabilityData.numOfBillableChildren,
      num_children2: availabilityData.numOfUnbillableChildren,
      property: availabilityData.location,
      suite: availabilityData.suite ? availabilityData.suite : '',
      offer_code: getNormalizedCode(availabilityData.offerCode),
      kids_ages: availabilityData.kidsAges
    };

    const recentSearchesRepository = new RecentSearchesRepository();
    const request = recentSearchesRepository.addNewRecentSearch(recentSearchesList, recentSearchData);

    return request.then(
      response => {
        dispatch({
          type: getRecentSearchesInfoSuccessType,
          recentSearchesList: response
        });
        dispatch({
          type: setReqRecentSearchesInfoLoadingType,
          searchesSetReqIsLoading: false
        });
      },
      err => {
        dispatch({
          type: getRecentSearchesInfoErrorType,
          searchesReqFailed: true,
          searchesErrorMessage: `Oops, ${err.message}`
        });
        dispatch({
          type: setReqRecentSearchesInfoLoadingType,
          searchesSetReqIsLoading: false
        });
      }
    );
  },

  deleteRecentSearch: (recentSearchesList, recentSearchId) => dispatch => {
    dispatch({
      type: delReqRecentSearchesInfoLoadingType,
      searchesDelReqIsLoading: true
    });

    const recentSearchesRepository = new RecentSearchesRepository();
    const request = recentSearchesRepository.deleteRecentSearchById(recentSearchesList, recentSearchId);

    return request.then(
      response => {
        dispatch({
          type: getRecentSearchesInfoSuccessType,
          recentSearchesList: response
        });
        dispatch({
          type: delReqRecentSearchesInfoLoadingType,
          searchesDelReqIsLoading: false
        });
      },
      err => {
        dispatch({
          type: getRecentSearchesInfoErrorType,
          recentSearchesList: [],
          searchesReqFailed: true,
          searchesErrorMessage: `Oops, ${err.message}`
        });
        dispatch({
          type: delReqRecentSearchesInfoLoadingType,
          searchesDelReqIsLoading: false
        });
      }
    );
  },

  deleteAllRecentSearches: () => dispatch => {
    dispatch({
      type: delReqRecentSearchesInfoLoadingType,
      searchesDelReqIsLoading: true
    });

    const recentSearchesRepository = new RecentSearchesRepository();
    const request = recentSearchesRepository.deleteAllRecentSearches();

    return request.then(
      response => {
        dispatch({
          type: getRecentSearchesInfoSuccessType,
          recentSearchesList: response
        });
        dispatch({
          type: delReqRecentSearchesInfoLoadingType,
          searchesDelReqIsLoading: false
        });
      },
      err => {
        dispatch({
          type: getRecentSearchesInfoErrorType,
          recentSearchesList: [],
          searchesReqFailed: true,
          searchesErrorMessage: `Oops, ${err.message}`
        });
        dispatch({
          type: delReqRecentSearchesInfoLoadingType,
          searchesDelReqIsLoading: false
        });
      }
    );
  },

  openRatesCalendar: (suiteCode, requestObject, getFetchRestrictionsEnabled) => async dispatch => {
    const eventData = rateCalendarGA(GTM_EVENT.ACTION.openRateCalendar, GTM_EVENT.LABEL.greatWolf);

    pushEvent(eventData);
    // Reset the range by suite variables
    dispatch({
      type: getAvRangeBySuiteResetType
    });
    dispatch({
      type: selectSuiteForRangeType,
      selectedSuiteForRange: suiteCode
    });
    dispatch({
      type: getAvRangeBySuiteIsLoadingType,
      getAvRangeBySuiteIsLoading: true
    });

    try {
      const calendarRates = await getCalendarRates(requestObject, suiteCode);
      dispatch(actionCreators.getCalendarRangeRestrictions(requestObject, calendarRates, getFetchRestrictionsEnabled));
    } catch (err) {
      dispatch({
        type: getAvRangeBySuiteFailedType,
        getAvRangeBySuiteFailed: true,
        getAvRangeBySuiteErrorMsg: `Oops, ${err.message}`
      });
      dispatch({
        type: getAvRangeBySuiteIsLoadingType,
        getAvRangeBySuiteIsLoading: false
      });
    }
  },

  getDataForSelectionInRateCalendar: (suiteCode, requestObject) => async (dispatch, getState) => {
    const state = getState()?.plans?.selectedSuiteRange;
    const selectedCalendarRates = await getLowestRatesByStay(state, requestObject, suiteCode);
    dispatch({
      type: openRatesCalendarType,
      selectedSuiteRange: { ...state, ...selectedCalendarRates }
    });
  },

  closeRatesCalendar: () => {
    const eventData = rateCalendarGA(GTM_EVENT.ACTION.closeRateCalendar, GTM_EVENT.LABEL.greatWolf);
    pushEvent(eventData);
    return {
      type: closeRatesCalendarType
    };
  },

  getCalendarRangeRestrictions: (requestObject, calendarRates, restrictionsEnabled) => (dispatch, getState) => {
    const resortLocation = getResortLocation(getState().currentLodge);
    if (restrictionsEnabled && !restrictionsEnabled) {
      if (calendarRates) {
        dispatch({
          type: openRatesCalendarType,
          selectedSuiteRange: calendarRates
        });
      }
      dispatch({
        type: getAvRangeBySuiteIsLoadingType,
        getAvRangeBySuiteIsLoading: false
      });
      return;
    }

    if (!requestObject.propertyCode) {
      requestObject = {
        ...requestObject,
        propertyCode: resortLocation
      };
    }

    let newRequest = {
      lodgeCode: requestObject?.lodgeCode || requestObject.location,
      year: requestObject.arrival?.split('-')[0] || requestObject.year,
      month: requestObject.arrival?.split('-')[1] || requestObject.month,
      adults: requestObject.adults || requestObject.numberOfAdults,
      kidsAges: requestObject?.kidsAges,
      offerCode: requestObject.rateCode || requestObject.offerCode || '',
      numberOfMonths: requestObject.numberOfMonths ? requestObject.numberOfMonths : calendarRates ? 2 : 1
    };

    if (requestObject.type) newRequest.type = requestObject.type;

    let apiClient = new RoomBookingApiClient();
    const request = apiClient.getLowestRates(newRequest, requestObject.propertyCode);

    return request.then(
      response => {
        const dealsSummary = response.data.dealsSummary && getTheFirstElementOfArray(response.data.dealsSummary);
        const restrictions = transformRestrictions(response.data)?.restrictions;
        if (calendarRates) {
          dispatch({
            type: openRatesCalendarType,
            selectedSuiteRange: calendarRates,
            dealsSummary: dealsSummary
          });
        }

        if (newRequest.offerCode !== '') {
          dispatch({
            type: requestObject.type === 1 ? getLowestRatesCalendarMobile : getLowestRatesCalendar,
            lowestRateCalendarList: response.data
          });
        }

        dispatch({
          type:
            requestObject?.type === 1 ? openRatesCalendarRestrictionIncrementType : openRatesCalendarRestrictionType,
          selectedSuiteRangeRestrictions: restrictions,
          dealsSummary: dealsSummary
        });

        dispatch({
          type: getAvRangeBySuiteIsLoadingType,
          getAvRangeBySuiteIsLoading: false
        });
      },
      err => {
        dispatch({
          type: getAvRangeBySuiteFailedType,
          getAvRangeBySuiteFailed: true,
          getAvRangeBySuiteErrorMsg: `Oops, ${err.message}`
        });
        dispatch({
          type: getAvRangeBySuiteIsLoadingType,
          getAvRangeBySuiteIsLoading: false
        });
      }
    );
  },

  cleanCalendarRestrictionsAndLowestRates: () => {
    return {
      type: cleanCalendarRestrictionsAndLowestRates
    };
  },

  cleanRatesCalendarRestrictions: () => {
    return {
      type: closeRatesCalendarRestrictionType
    };
  },

  openPaymentLeaveModal: () => {
    return {
      type: openPaymentLeaveModalType
    };
  },

  closePaymentLeaveModal: () => {
    return {
      type: closePaymentLeaveModalType
    };
  },

  setAppliedFilters: params => dispatch => {
    dispatch({
      type: setAppliedFiltersType,
      filtersApplied: params
    });
  },

  setSelectedSuiteForCalendar: params => dispatch => {
    dispatch({
      type: setSelectedSuiteForCalendarType,
      selectedSuiteForCalendar: params
    });
  },

  getItemQuantity: requestObject => dispatch => {
    dispatch({
      type: getItemQuantityType,
      itemQuantityReqIsLoading: true
    });

    const apiClient = new ItemApiClient();
    const request = apiClient.getItemQuantity(requestObject);

    return request.then(
      response => {
        dispatch({
          type: getItemQuantitySuccessType,
          itemQuantity: response.data
        });
      },
      err => {
        dispatch({
          type: getItemQuantityErrorType,
          itemQuantity: []
        });
      }
    );
  },

  /**
   * @function
   * Sets and toggle when a campaign is being performed on the application
   * @param {bool} isEmailCampaign Whenever if the current url refers to a
   * campaign
   * @param {Object} campaignObject The campaign information
   * @param {date} startDate Start date of the campaign
   * @param {date} endDate End date of the campaign
   */
  toggleEmailCampaigns: (isEmailCampaign, campaignObject = {}, startDate = '', endDate = '') => dispatch => {
    dispatch({
      type: toggleEmailCampaignsType,
      emailCampaign: {
        isEmailCampaign: isEmailCampaign,
        planCalendarShouldBeBlocked: isEmailCampaign,
        startDate: startDate,
        endDate: endDate,
        campaignObject: campaignObject
      }
    });
  },

  /**
   * @function
   * Clear/reset all the availability data to the initial state   *
   */
  clearAllAvaliabilityData: () => ({ type: clearAllAvailabilityDataType }),

  setQualifyingID: (code, x) => {
    return {
      type: setQualifyingID,
      code: code
    };
  },

  getQualifyingID: (shouldTriggerValidation = false) => (dispatch, getState) => {
    const state = getState();
    const { requiresQualifyingID, qualifyingID } = state.plans;
    if (!requiresQualifyingID) return;

    if (!qualifyingID && shouldTriggerValidation) {
      dispatch({
        type: qualifyingIDHasError,
        hasError: true
      });
    }
    return qualifyingID && qualifyingID.toUpperCase();
  },

  /**
   * @function
   * Sets the suite type when an email campaign comes
   * @param {Object} campaignObject The campaign information
   */
  setEmailCampaingSuiteType: (suiteType = null) => (dispatch, getState) => {
    let normalizedSuiteType;

    if (suiteType && suiteType !== SUITE_TYPES.unknown) {
      switch (suiteType.toLowerCase()) {
        case SUITE_TYPES.standard:
          normalizedSuiteType = SUITE_TYPES.standard;
          break;
        case SUITE_TYPES.themed:
          normalizedSuiteType = SUITE_TYPES.themed;
          break;
        case SUITE_TYPES.premium:
          normalizedSuiteType = SUITE_TYPES.premium;
          break;
        default:
          normalizedSuiteType = SUITE_TYPES.unknown;
      }
    }

    dispatch({
      type: setTabIndexByEmailCmpgnType,
      emailCampaignSuiteType: normalizedSuiteType
    });
  },

  setIsBookingWidgetFocused: (value = false) => dispatch => {
    dispatch({
      type: setIsBookingWidgetFocusedType,
      value: value
    });
  },

  setSortingOrder: sortingType => ({
    type: setSortingOrder,
    sortingType
  }),

  setSortSuite: suiteCode => ({
    type: setSortSuite,
    suiteCode
  }),

  setIsCaliforniaResident: () => dispatch => {
    dispatch({
      type: setIsCaliforniaResident
    });
  },

  cleanIsCaliforniaResident: () => dispatch => {
    dispatch({
      type: cleanIsCaliforniaResident
    });
  }
};

// This reducer combines reducers for providing new values
// to the Redux store based on actions defined above
//
// If the TYPE matches the action, the new state will replace the old
// this occurs on a per action basis and you should see the Redux store
// (state tree) reflect the change

const reducer = (state, action) => {
  state = initializeState(state, initialState);

  // Get Book information reducers
  switch (action.type) {
    case setAvailabilityDetailsError:
      return {
        ...state,
        offerCodeError: action.error,
        availabilityRequestFailed: false,
        availabilityReqIsLoading: false,
        availabilityErrorMessage: ''
      };
    case setTopFiveSuites:
      return {
        ...state,
        topFiveSuites: action.topFiveSuites
      };
    // Availability item request succeded
    case getAvailabilityInfoSuccessType:
      return {
        ...state,
        availabilityList: action.availabilityList,
        isAPScall: action.isAPScall,
        availabilityRequestFailed: false,
        availabilityReqIsLoading: false,
        availabilityErrorMessage: '',
        requiresQualifyingID: action.requiresQualifyingID,
        offerCodeError: action.offerCodeError,
        isErrorModalAlreadyClosed: action.isErrorModalAlreadyClosed,
        promoDescription: action.promoDescription,
        offerType: action.offerType,
        personalizationRues: action.personalizationRules,
        personalizationResponses: action.personalizationResponses,
        lcoPackages: action.lcoPackages,
        upsellPackagesContent: action.upsellPackagesContent,
        isTwoRateTypesSuites: action.isTwoRateTypesSuites
      };
    // The request is currently running
    case getAvailabilityInfoLoadingType:
      return {
        ...state,
        availabilityReqIsLoading: action.availabilityReqIsLoading,
        ...(action.availabilityReqIsLoading && {
          availabilityList: []
        }),
        ...(!action.shouldIgnoreOfferCodeError && {
          offerCodeError: action.offerCodeError
        })
      };
    // Availability item request failed
    case getAvailabilityInfoErrorType:
      return {
        ...state,
        availabilityList: action.availabilityList,
        availabilityRequestFailed: action.availabilityRequestFailed,
        availabilityErrorMessage: action.availabilityErrorMessage,
        availabilityReqIsLoading: false,
        requiresQualifyingID: action.requiresQualifyingID,
        promoDescription: action.promoDescription
      };
    case setIsErrorModalAlreadyClosedType:
      return {
        ...state,
        isErrorModalAlreadyClosed: action.value
      };
    // Recent Searches items request succeded
    case getRecentSearchesInfoSuccessType:
      return {
        ...state,
        recentSearchesList: action.recentSearchesList,
        searchesReqFailed: false,
        searchesErrorMessage: ''
      };
    // The request is currently running
    case getRecentSearchesInfoLoadingType:
      return {
        ...state,
        searchesGetReqIsLoading: action.searchesGetReqIsLoading
      };
    // The set request is currently running
    case setReqRecentSearchesInfoLoadingType:
      return {
        ...state,
        searchesSetReqIsLoading: action.searchesSetReqIsLoading
      };
    // The delete request is currently running
    case delReqRecentSearchesInfoLoadingType:
      return {
        ...state,
        searchesDelReqIsLoading: action.searchesDelReqIsLoading
      };
    // Recent Searches item request failed
    case getRecentSearchesInfoErrorType:
      return {
        ...state,
        searchesReqFailed: action.searchesReqFailed,
        searchesErrorMessage: action.searchesErrorMessage
      };

    case selectSuiteForRangeType:
      return {
        ...state,
        selectedSuiteForRange: action.selectedSuiteForRange
      };

    case unSelectSuiteForRangeType:
      return {
        ...state,
        selectedSuiteForRange: initialState.selectedSuiteForRange
      };

    case openRatesCalendarType:
      return {
        ...state,
        selectedSuiteRange: action.selectedSuiteRange,
        dealsSummary: action.dealsSummary
      };

    case closeRatesCalendarType:
      return {
        ...state,
        isOpenRatesCalendar: false,
        selectedSuiteRange: initialState.selectedSuiteRange
      };

    case openRatesCalendarRestrictionType:
      return {
        ...state,
        selectedSuiteRangeRestrictions: action.selectedSuiteRangeRestrictions,
        dealsSummary: action.dealsSummary
      };

    case openRatesCalendarRestrictionIncrementType:
      return {
        ...state,
        selectedSuiteRangeRestrictions:
          Object.keys(action.selectedSuiteRangeRestrictions).length > 0
            ? { ...state.selectedSuiteRangeRestrictions, ...action.selectedSuiteRangeRestrictions }
            : { ...state.selectedSuiteRangeRestrictions },
        dealsSummary: action.dealsSummary
      };

    case getLowestRatesCalendar:
      return {
        ...state,
        lowestRateCalendarList: action.lowestRateCalendarList
      };

    case getLowestRatesCalendarMobile:
      return {
        ...state,
        lowestRateCalendarList:
          Object.keys(action.lowestRateCalendarList).length > 0
            ? { ...state.lowestRateCalendarList, ...action.lowestRateCalendarList }
            : { ...state.lowestRateCalendarList }
      };

    case getLowestRatesCalendarError:
      return {
        ...state,
        getLowestRateCalendarErrorMsg: action.getLowestRateCalendarErrorMsg
      };

    case cleanCalendarRestrictionsAndLowestRates:
      return {
        ...state,
        lowestRateCalendarList: initialState.lowestRateCalendarList,
        selectedSuiteRangeRestrictions: initialState.selectedSuiteRangeRestrictions
      };

    case closeRatesCalendarRestrictionType:
      return {
        ...state,
        isOpenRatesCalendar: false,
        selectedSuiteRangeRestrictions: initialState.selectedSuiteRangeRestrictions
      };

    case openPaymentLeaveModalType:
      return {
        ...state,
        leavePayment: true
      };

    case closePaymentLeaveModalType:
      return {
        ...state,
        leavePayment: false
      };

    case getAvRangeBySuiteIsLoadingType:
      return {
        ...state,
        getAvRangeBySuiteIsLoading: action.getAvRangeBySuiteIsLoading
      };
    case getAvRangeBySuiteFailedType:
      return {
        ...state,
        getAvRangeBySuiteFailed: action.getAvRangeBySuiteFailed,
        getAvRangeBySuiteErrorMsg: action.getAvRangeBySuiteErrorMsg
      };
    case getAvRangeBySuiteResetType:
      return {
        ...state,
        selectedSuiteRange: {},
        getAvRangeBySuiteIsLoading: false,
        getAvRangeBySuiteFailed: false,
        getAvRangeBySuiteErrorMsg: ''
      };
    case getAvailabilityHighestRatesSuccessType:
      return {
        ...state,
        availabilityHighestRatesList: action.availabilityHighestRatesList,
        availabilityHighestRatesIsLoading: false,
        availabilityHighestRatesRequestFailed: false
      };
    case getAvailabilityHighestRatesErrorType:
      return {
        ...state,
        availabilityHighestRatesList: [],
        availabilityHighestRatesIsLoading: false,
        availabilityHighestRatesRequestFailed: true
      };
    case setAppliedFiltersType:
      return {
        ...state,
        filtersApplied: action.filtersApplied,
        oldFiltersApplied: state.filtersApplied
      };

    case setSelectedSuiteForCalendarType:
      return {
        ...state,
        selectedSuiteForCalendar: action.selectedSuiteForCalendar
      };

    case getAvailabilityRangeType:
      return {
        ...state,
        getAvailabilityRangeIsLoading: action.getAvailabilityRangeIsLoading
      };
    case getAvailabilityRangeSuccessType:
      return {
        ...state,
        suiteAvailabilityList: action.suiteAvailabilityList,
        getAvailabilityRangeIsLoading: false,
        getAvailabilityRangeFailed: false,
        getAvailabilityRangeMessage: ''
      };
    case getAvailabilityRangeErrorType:
      return {
        ...state,
        suiteAvailabilityList: [],
        getAvailabilityRangeIsLoading: false,
        getAvailabilityRangeFailed: true,
        getAvailabilityRangeMessage: action.getAvailabilityRangeMessage
      };
    case getItemQuantityType:
      return {
        ...state,
        itemQuantityReqIsLoading: action.itemQuantityReqIsLoading
      };
    case getItemQuantitySuccessType:
      return {
        ...state,
        itemQuantity: action.itemQuantity,
        itemQuantityReqIsLoading: false,
        itemQuantityReqFailed: false
      };
    case getItemQuantityErrorType:
      return {
        ...state,
        itemQuantity: action.itemQuantity,
        itemQuantityReqIsLoading: false,
        itemQuantityReqFailed: true
      };
    case toggleEmailCampaignsType:
      return {
        ...state,
        emailCampaign: action.emailCampaign
      };
    case setQualifyingID:
      return {
        ...state,
        qualifyingID: action.code
      };
    case qualifyingIDHasError:
      return {
        ...state,
        qualifyingIDError: action.hasError
      };
    case setTabIndexByEmailCmpgnType:
      return {
        ...state,
        emailCampaignSuiteType: action.emailCampaignSuiteType
      };
    case setIsBookingWidgetFocusedType:
      return {
        ...state,
        isBookingWidgetFocused: action.value
      };
    case setSortingOrder:
      return {
        ...state,
        sorting: action.sortingType
      };
    case setSortSuite:
      return {
        ...state,
        sortSuite: action.suiteCode
      };
    case setIsCaliforniaResident:
      return {
        ...state,
        isCaliforniaResident: !state.isCaliforniaResident
      };
    case cleanIsCaliforniaResident:
      return {
        ...state,
        isCaliforniaResident: initialState.isCaliforniaResident
      };
    case setLatestVersionJEventError:
      return {
        ...state,
        latestVersionJEventErrorObj: action.variationJObj
      };
    case setLatestVersionHEventError:
      return {
        ...state,
        latestVersionHEventErrorObj: action.variationHObj
      };
    case clearAllAvailabilityDataType:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

// Selectors
export const getCalendarRangeRestrictionList = state => {
  return state.selectedSuiteRangeRestrictions;
};

export const getOfferCodeError = state => {
  return state.offerCodeError;
};

export const getIsBookingWidgetFocused = state => {
  return state.isBookingWidgetFocused;
};

/**
 * Returns the filters applied labels.
 * @param {Object} state Plan store.
 * @returns {Object} Filters applied.
 */
export const getFiltersAppliedLabels = state => {
  return state.filtersApplied;
};

/**
 * Returns the old filters applied labels.
 * @param {Object} state Plan store.
 * @returns {Object} Old filters applied.
 */
export const getOldFiltersAppliedLabels = state => {
  return state.oldFiltersApplied;
};

/**
 * Returns the sorting criteria.
 * @param {Object} state Plan store.
 * @returns {string} Actual sorting criteria.
 */
export const getSortOrder = state => {
  return state.sorting;
};

export const getAvailabilityList = state => {
  return state.availabilityList;
};

/**
 * Returns the Interactions personalization data from the availability reponse
 * @param {Object} state
 */
export const getInteractionsData = state => {
  const interactions =
    state.personalizationResponses &&
    state.personalizationResponses.filter(pr => pr.rule === PERSONALIZATION_RULES.interactions);

  return interactions && interactions.length > 0 && interactions[0].data;
};

export const getRecentSearchesList = state => {
  return get(state, 'recentSearchesList', []);
};

export const getRecentSearchesLoading = state => {
  const getRecentSearchesLoading = get(state, 'searchesGetReqIsLoading', false);
  const delRecentSearchesLoading = get(state, 'searchesDelReqIsLoading', false);
  return getRecentSearchesLoading || delRecentSearchesLoading;
};

export const getDealsSummary = state => {
  return state.dealsSummary;
};

export const getIsAPScall = state => {
  return state.isAPScall;
};

export const getIsPaymentLeaveModal = state => {
  return state.leavePayment;
};

export const getLowestRateCalendarList = state => {
  return state.lowestRateCalendarList;
};

export const getIsCaliforniaResident = state => {
  return state?.isCaliforniaResident;
};

export const getIsTwoRateTypesSuites = state => {
  return state?.isTwoRateTypesSuites;
};

export const getTopFiveSuites = state => {
  return state?.topFiveSuites;
};

const transformRestrictions = data => {
  const dates = Object.keys(data);
  return { restrictions: dates.reduce((items, current) => ({ ...items, [current]: data[current].restrictions }), {}) };
};

export const getAvRangeBySuiteIsLoading = state => state?.getAvRangeBySuiteIsLoading;

export const getItemQuantityState = state => state.itemQuantity;
